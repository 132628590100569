import React, { useState, useRef, useMemo, useEffect, useContext } from 'react';
import { Select, Spin, Modal, DatePicker, Space, Typography, Checkbox, Button } from 'antd';
import { DebounceSelect } from '../../../components/DebounceSelect';
import { UserContext } from '../../../context';
import { query, updateStudy, fetchPatients, fetchUsers, fetchClients } from '../utils/query';
import Parse from 'parse';
const { Title, Text } = Typography;

const MoreFiltersModal = ({ open, filters, onCancel, onOk, config }) => {
    const [internalFilters, setInternalFilters] = useState({});
    const [modalities, setModalities] = useState([]);
    const [user] = useContext(UserContext);

    useEffect(() => {
        setInternalFilters(filters);
    }, [filters, open]);

    const fetchModalities = async () => {
        const query = new Parse.Query('Modalities');  
        const results = (await query.find()).map((item) => item.toJSON());
        setModalities(results);
    }

    useEffect(() => {
        fetchModalities();
    }, []);

    const TriStateButton = ({labels, filters, prop, setFilters}) => {
        const internalSetState = () => {
            if (filters[prop] === true) {
                setFilters({...filters, [prop]: false});
            } else if (filters[prop] === false) {
                setFilters({...filters, [prop]: null});
            } else {
                setFilters({...filters, [prop]: true});
            }
        }

        const setLabel = () => {
            if (filters[prop] === true) return labels[0];
            if (filters[prop] === false) return labels[1];
            return labels[0];
        }

        const setType = () => {
                if (filters[prop]  === true || filters[prop] === false) return 'primary';
                return 'default';
            }
        
        return (
            <Button style={{ margin: 10}} onClick={() => internalSetState()} type={setType()}>{setLabel()}</Button>
        );
    }
   
    return (
        <Modal
        open={open}
        onCancel={onCancel}
        onOk={() => {
            onOk(internalFilters)
            onCancel()
        }}
        >
            <Space direction="vertical">
                <Title level={3}>Filtros avanzados</Title>

                <Title level={5}>Pacientes</Title>
                <DebounceSelect
                    mode="multiple"
                    value={internalFilters.patients}
                    placeholder="Buscar paciente"
                    fetchOptions={fetchPatients}
                    onChange={(newValue) => setInternalFilters({...internalFilters, patients: newValue})}
                    renderOptions={(option) => <Space direction="vertical">
                      <span>{option.label}</span>
                    </Space>}
                    style={{
                        width: 300,
                    }}
                />
                <Title level={5}>Fechas</Title>
                <DatePicker.RangePicker
                    value={internalFilters.dates}
                    onChange={(newValue) => setInternalFilters({ ...internalFilters, dates: newValue })}
                />
                <Title level={5}>Modalidades</Title>
                 <Select
                    mode="multiple"
                    placeholder="Selecciona una modalidad"
                    style={{ width: 200 }}
                    value={filters.modalities}
                    onChange={(value, item) => setInternalFilters({...filters, modalities: item})}
                    allowClear
                    options={modalities?.map((item) => ({ label: item.commonName, value: item.objectId, key: item.objectId, ...item }))}
                    />
                <Title level={5}>Radiólogos</Title>
                <DebounceSelect
                    mode="multiple"
                    value={internalFilters.radiologists}
                    placeholder="Buscar radiólogo"
                    fetchOptions={(value) => fetchUsers(value, ['Radiologist'])}
                    onChange={(newValue) => setInternalFilters({...internalFilters, radiologists: newValue})}
                    renderOptions={(option) => <Space direction="vertical">
                      <span>{option.label}</span>
                    </Space>}
                    style={{
                        width: 300,
                    }}
                />
                <Title level={5}>Referidores</Title>
                 <DebounceSelect
                    mode="multiple"
                    value={internalFilters.referrers}
                    placeholder="Buscar médico"
                    fetchOptions={(value) => fetchUsers(value, ['Specialist physician'])}
                    onChange={(newValue) => setInternalFilters({...internalFilters, referrers: newValue})}
                    renderOptions={(option) => <Space direction="vertical">
                      <span>{option.label}</span>
                    </Space>}
                    style={{
                        width: 300,
                    }}
                />
                <Title level={5}>Médicos radiólogos</Title>
                 <DebounceSelect
                    mode="multiple"
                    value={internalFilters.interpreters}
                    placeholder="Buscar médico radiólogo"
                    fetchOptions={(value) => fetchUsers(value, ['Radiology physician'])}
                    onChange={(newValue) => setInternalFilters({...internalFilters, interpreters: newValue})}
                    renderOptions={(option) => <Space direction="vertical">
                      <span>{option.label}</span>
                    </Space>}
                    style={{
                        width: 300,
                    }}
                />
                <Title level={5}>Convenio</Title>
                <DebounceSelect
                    mode="multiple"
                    value={internalFilters.clients}
                    placeholder="Buscar convenio"
                    fetchOptions={(value) => fetchClients(value)}
                    onChange={(newValue) => setInternalFilters({...internalFilters, clients: newValue})}
                    renderOptions={(option) => <Space direction="vertical">
                      <span>{option.label}</span>
                    </Space>}
                    style={{
                        width: 300,
                    }}
                />
                <Title level={5}>Estado</Title>
                 <Select
                mode="multiple"
                placeholder="Selecciona un estado"
                style={{ width: 300 }}
                value={internalFilters.status}
                onChange={(value) => setInternalFilters({...internalFilters, status: value})}
                allowClear
                options={[
                  { label: 'Pendiente', value: 'Pendiente' },
                  { label: 'Enviado a modalidad', value: 'Enviado a modalidad' },
                  { label: 'Recibiendo imágenes', value: 'Recibiendo imágenes' },
                  { label: 'Imágenes recibidas', value: 'Imágenes recibidas' },
                    { label: 'Entregado', value: 'Entregado'},
                ]}
              />
                {user?.get('role') === 'Radiology physician' && config?.allowInterpretersToSeeAll && <Checkbox onChange={(e) => setInternalFilters({...filters, asignedToMe: e.target.checked})}>Asignados a mí</Checkbox>}
                <TriStateButton filters={internalFilters} prop="interpreted" setFilters={setInternalFilters} labels={['Interpretados', 'No interpretados', 'Interpretados']} />
                <TriStateButton filters={internalFilters} prop="fullInformation" setFilters={setInternalFilters} labels={['Información completa', 'Falta información', 'Falta información']} />
                </Space>
        </Modal>
    );
    }

export default MoreFiltersModal; 