import React from 'react';
import { Input } from 'antd';

const BirthdateField = ({ value, onChange }) => {
    const handleDateChange = (e) => {
        const input = e.target.value;
        const formattedDate = formatInput(input);
        onChange(formattedDate);
      };
    
      const formatInput = (input) => {
        // Remove all non-digit and non-slash characters
        const cleanedInput = input.replace(/[^\d\/]/g, '');
    
        // Split by slashes
        const parts = cleanedInput.split('/').map(part => part.replace(/\D/g, ''));
        
        if (parts.length > 3) parts.splice(3);
    
        // Format to dd/mm/yyyy
        const day = parts[0] || '';
        const month = parts[1] || '';
        const year = parts[2] || '';
    
        let formatted = day;
        if (month) {
          formatted += `/${month}`;
        }
        if (year) {
          formatted += `/${year}`;
        }
    
        // Handle edge cases for slashes
        if (input.length === 2 && !input.includes('/')) {
          formatted += '/';
        }
        if (input.length === 5 && !input.includes('/', 3)) {
          formatted = `${formatted.slice(0, 5)}/${formatted.slice(5)}`;
        }
    
        return formatted;
      };

    return (
            <Input value={value} onChange={handleDateChange} placeholder="dd/mm/aaaa" />
    );
}

const validateDate = (rule, value) => {
    if (!value) {
      return Promise.reject('Por favor, ingrese la fecha de nacimiento');
    }
    const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    if (value && !datePattern.test(value)) {
      return Promise.reject('La fecha debe tener el formato dd/mm/aaaa');
    }
    return Promise.resolve();
  };

export { BirthdateField, validateDate };