import Parse from 'parse';

const fetchClinics = async (value) => {
    return new Promise(async (resolve, reject) => {
        const query = new Parse.Query('Clinics');
        if (value) query.matches('name', value, 'i');
        query.descending('name');
        const results = await query.find();
        const clinics = results.map(item => {
            return {
                value: item.get('name'),
                label: item.get('name'),
                key: item.id,
                ...item.toJSON()
            }
        });
        resolve(clinics);
    });
}

export default fetchClinics;