import { useState } from "react";
import { Select, Spin, Space, Empty, Button, Divider } from "antd";
import { searchFigures, queryStudies, queryClients } from "../utils/query";
import moment from "moment";

const SearchInput = (props) => {
    const { index } = props;
    const [data, setData] = useState([]);
    const [value, setValue] = useState();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();
    const [searchTimeout, setSearchTimeout] = useState(null);

    const searchFunction = (value) => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        setLoading(true);
        setSearchTimeout(setTimeout(() => {
            if (props.class === 'StudyDescriptions') {
                queryStudies(value).then((results) => {
                    setData(results.map((r) => ({...r, value: r.description, label: r.description})));
                    setLoading(false);
                })
            } else if (props.class === 'Clients') {
                queryClients(value).then((results) => {
                    setData(results.map((r) => ({...r, value: r.name, label: r.name})));
                    setLoading(false);
                })
            } else {
            searchFigures(value, props.role).then((results) => {
                console.log('Resultsss', results);
                setData(results.map((r) => ({...r, value: r.fullName, label: r.fullName})));
                setLoading(false);
            })
        }
        }, 200));
    }

    const setField = (value) => {
        props.form.setFieldsValue({
            [props.name]: {value: value, objectId: data.find((d) => d.fullName === value).objectId}
        });
        setValue(value);
    }

    const setStudy = (value) => {
        const object = data.find((d) => d.description === value);
        props.form.setFieldsValue({
            [props.name]: {
                study: {
                    value: value,
                    ...object
                }
            }
        });
        setValue(value);
    }

    const setClient = (value) => {
        const object = data.find((d) => d.name === value);
        props.form.setFieldsValue({
            [props.name]: {
                value: value,
                ...object
            }
        });
        setValue(value);
    }


    return (
      <Select
        showSearch
        loading={loading}
        value={props.text}
        placeholder={props.placeholder}
        style={props.style}
        onSearch={searchFunction}
        locale={{ emptyText: message }}
        onClick={() => searchFunction('')}
        onSelect={props.class === 'StudyDescriptions' ? (value) => setStudy(value) : props.class === 'Clients' ? (value) => setClient(value) : (value) => setField(value)}
        notFoundContent={
                <Space direction="vertical" size={0} style={{ width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Empty description={'Sin resultados'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    {!props.preventAdd && <Button type="link" onClick={() => props.onAdd()}>Agregar</Button>}
                </Space>
        }
        options={data}
        optionRender={(d) => (
            props.role === 'Patients' ?
            <Space size={0} direction="horizontal" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                <Space size={0} direction="vertical">
                    <div><b>{d.label}</b></div>
                    <div>{moment(d.data.birthday?.iso).format('DD/MM/YYYY')}</div>
                </Space>
                    <Divider type="vertical" />
                    <b style={{ color: 'lightgrey', fontSize: 18, alignSelf: 'flex-end' }}>{d.data.gender?.[0]}</b>
            </Space> :
            <Space size={0} direction="vertical">
                    <div><b>{d.label}</b></div>
                </Space>
        )}
      />
    );
  };

  export { SearchInput };