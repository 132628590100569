import React, {useState, useEffect } from 'react';
import { createContext } from 'react';
import { useLocation } from 'react-router-dom';

import Login from '../sites/Login/Login';
import Study from '../sites/Study/Study';
import Interpretation from '../sites/Interpretation/Interpretation';
import Order from '../sites/Order/Order';
import TermsAndConditions from '../sites/Agreements/TermsAndConditions';
import PrivacyPolicy from '../sites/Agreements/PrivacyPolicy';


const defaultRouter = [
    {
        path: "/login",
        element: <Login/>
    },
    {
        path: '/terms',
        element: <TermsAndConditions />,
    },
    {
        path: "/privacy",
        element: <PrivacyPolicy />,
    },   

];

const newRouter = [
    {
        path: "/*",
        element: <Login/>
    }, 
  
    {
        path: "/order/:id",
        element: <Order />,
        key: "/order",
    },
    {
        path: '/study/:id',
        element: <Study />,
        key: '/study'
    },
    {
        path: '/interpretation/:id',
        element: <Interpretation />,
        key: '/interpretation'
    },
    {
        path: '/terms',
        element: <TermsAndConditions />,
    },
    {
        path: "/privacy",
        element: <PrivacyPolicy />,
    },    
];

export const RoutesContext = createContext();

export const RoutesProvider = ({ children }) => {
    const [dynamicRouter, setDynamicRouter] = useState(newRouter);
  

       useEffect(() => {
        const Element =  require('../components/Interface/Interface').CustomComponent

        if (!defaultRouter.find(route => route.path === "/*")) {
            defaultRouter.push({
                path: "/*",
                element: <Element />,
            });
        }
       
    }, []);

    const changeRouter = (newRouter) => {
        console.log('New router:', newRouter);
        if (newRouter === 'default') setDynamicRouter(defaultRouter);
        else setDynamicRouter(newRouter);
    }
    
    return (
        <RoutesContext.Provider value={{dynamicRouter, setDynamicRouter, changeRouter}}>
          {children}
        </RoutesContext.Provider>
    );
    }