import React, {useState, useEffect } from 'react';
import { Flex, Modal, Button, Input, Form, message, Typography, Space, Tag } from 'antd';
import moment from 'moment';
const { Title, Text } = Typography;

const statusTag = (status) => {

    if (status === 'completed') {
        return <Tag color="green">Pagado</Tag>
    }

    if (status === 'canceled') {
        return <Tag color="red">Cancelado</Tag>
    }

    if (status === 'in_progress') {
        return <Tag color="orange">Pendiente</Tag>
    }

    return <Tag color="red">Vencido</Tag>
}

const BankPaymentModal = ({ open, setOpen, setRefresh }) => {

    if (!open) {
        return null;
    }

    return (
        <Modal title="Datos de pago" open={open} footer={null} onCancel={() => setOpen(false)}>
            <Space direction="vertical">
            {statusTag(open?.status)}
                <Title level={4}>Pago mediante depósito o transferencia bancaria</Title>
                <Text strong>Concepto: {open?.description}</Text>
                <Text strong>Monto: ${String(open?.amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}.00</Text>
                <Text strong>Fecha de vencimiento: {moment(open?.due_date).format('DD/MM/YYYY')}</Text>
                <Text strong>Depósito o transferencia bancaria</Text>
                <Text>Para realizar un pago mediante depósito o transferencia bancaria, realiza el pago a la siguiente cuenta:</Text>
                <Text strong>Nombre del banco: {open?.payment_method?.bank}</Text>
                <Text strong>CLABE: {open?.payment_method?.clabe}</Text>
                <Text strong>Número de acuerdo: {open?.payment_method?.agreement}</Text>
                <Text strong>Referencia: {open?.payment_method?.name}</Text>
                <Text>No es necesario enviar comprobante de pago, tu pago se acreditará automáticamente.</Text>
            </Space>
        </Modal>
    )
}

export default BankPaymentModal;
