import React, { useEffect, useState } from 'react';
import { Space, DatePicker, Select, Skeleton, Checkbox, Row, Col } from 'antd';
import StorageStatus from './components/StorageStatus';
import StorageServiceStatus from './components/StorageServiceStatus';
import ModalityServiceStatus from './components/ModalityServiceStatus';
import PublicRISStatus from './components/PublicRISStatus';
import ImageViewerStatus from './components/ImageViewerStatus';
import DatabaseStatus from './components/DatabaseStatus';
import PrintingServiceStatus from './components/PrintingServiceStatus';
import Title from 'antd/es/typography/Title';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const ServerStatus = () => {
    const [loading, setLoading] = useState(true);
    const screens = useBreakpoint();



    if (screens.xs) {
        return (
            <Space direction='vertical' style={{padding: 20, width: '100%'}} >
                <Title level={2}>Estado del servidor</Title>
                <Row justify='space-between' gutter={[16, 16]}>
                    <Col span={24}>
                        <Title level={3}>Almacenamiento</Title>
                        <StorageStatus />
                    </Col>
                    <Col span={24}>
                        <Title level={3}>Servicio de almacenamiento</Title>
                        <StorageServiceStatus />
                    </Col>
                    <Col span={24}>
                        <Title level={3}>Servicio de envío a modalidades</Title>
                        <ModalityServiceStatus />
                    </Col>
                    <Col span={24}>
                        <Title level={3}>Sitio web público</Title>
                        <PublicRISStatus />
                    </Col>
                    <Col span={24}>
                        <Title level={3}>Visualizador de imágenes</Title>
                        <ImageViewerStatus />
                    </Col>
                    <Col span={24}>
                        <Title level={3}>Base de datos</Title>
                        <DatabaseStatus />
                    </Col>
                   
                </Row>
            </Space>
        )
    }

    return (
        <Space direction='vertical' style={{padding: 20, width: '100%'}} >
             <Title level={2}>Estado del servidor</Title>
            <Row justify='space-between' gutter={[16, 16]}>
                <Col span={6}>
                    <Title level={3}>Almacenamiento</Title>
                    <StorageStatus />
                </Col>
                <Col span={6}>
                    <Title level={3}>Servicio de almacenamiento</Title>
                    <StorageServiceStatus />
                </Col>
                <Col span={6}>
                    <Title level={3}>Servicio de envío a modalidades</Title>
                    <ModalityServiceStatus />
                </Col>
                <Col span={8}>
                    <Title level={3}>Sitio web público</Title>
                    <PublicRISStatus />
                </Col>
                <Col span={6}>
                    <Title level={3}>Visualizador de imágenes</Title>
                    <ImageViewerStatus />
                </Col>
                <Col span={6}>
                    <Title level={3}>Base de datos</Title>
                    <DatabaseStatus />
                </Col>
               
            </Row>
        </Space>
    )
}

export default ServerStatus