import Parse from 'parse';
import moment from 'moment';

const queryProps = ['fullName', 'email', 'phoneNumber'];
const  constructAccentedRegexPattern = (text)  => {
    // Map of characters to their possible accented versions
    const accentMap = {
      'a': '[aá]',
      'e': '[eé]',
      'i': '[ií]',
      'o': '[oó]',
      'u': '[uú]',
      'A': '[AÁ]',
      'E': '[EÉ]',
      'I': '[IÍ]',
      'O': '[OÓ]',
      'U': '[UÚ]'
    };
  
    // Replace each character in the text with its regex pattern
    return text.split('').map(char => accentMap[char] || char).join('');
  }
  

const updateStudy = async (data) => {
    return new Promise(async (resolve, reject) => {
        const Studies = Parse.Object.extend('Studies');
        const study = new Studies(data);

        study?.save().then((result) => {
            console.log('Study created', result);
            resolve(result.toJSON());
        }, (error) => {
            console.error('Error while creating Study: ', error);
            reject(error);
        });
    });
}

const fetchPatients = async (value) => {
    if (!value) return [];
    let regValue = value.split(' ').join('.*');  
    regValue = constructAccentedRegexPattern(regValue);
    return new Promise(async (resolve, reject) => {
        const query = new Parse.Query('Patients');
        const queryObjects = []
        queryProps.forEach(prop => {
            const q = new Parse.Query('Studies');
            q.matches(prop, regValue, 'i');
            queryObjects.push(q);
        });
        query._orQuery(queryObjects);
        query.descending('fullName');
        query.limit(10);
        const results = (await query.find()).map(item => item.toJSON());
        console.log(results);
        const patients = results.map(item => {
            return {
                value: item.fullName,
                label: item.fullName,
                key: item.objectId,
                phoneNumber: item.phoneNumber,
                ...item
            }
        });
        console.log(patients);
        resolve(patients);
    });
}

    const fetchUsers = async (value, role) => {
        if (!value) return [];
        return new Promise(async (resolve, reject) => {
            try {
                const params = { value, role };
                const parseUsers = await Parse.Cloud.run('queryUsers', params);
                    const users = parseUsers.map(item => {
                        return {
                            value: item.fullName,
                            label: item.fullName,
                            ...item
                        }
                    });
                    console.log(users);
                    resolve(users);
                }
                catch (error) {
                    console.error('Error while fetching patients: ', error);
                    reject(error);
                }
            });
        }

    const fetchClients = async (value) => {
        if (!value) return [];
        let regValue = value.split(' ').join('.*');  
        regValue = constructAccentedRegexPattern(regValue);
        return new Promise(async (resolve, reject) => {
            const query = new Parse.Query('Clients');
            query.matches('name', regValue, 'i');
            query.descending('name');
            query.limit(10);
            const results = (await query.find()).map(item => item.toJSON());
            const clients = results.map(item => {
                return {
                    value: item.name,
                    label: item.name,
                    key: item.objectId,
                    ...item
                }
            });
            resolve(clients);
        });
    }

       

  export { updateStudy, fetchPatients, fetchUsers, fetchClients };