import React, {useState, useEffect, useContext} from 'react';
import { useParams } from 'react-router-dom';
import StudyInfo from '../../components/StudyInfo';
import InterpretationInfo from '../../components/InterpretationInfo';
import DeliveryInfo from '../../components/DeliveryInfo';
import StudyTimeline from './components/StudyTimeline';
import PrintingInfo from '../../components/PrintingInfo';
import SendModal from './components/SendModal';
import PrintModal from './components/PrintModal';
import StudyDownloader from './components/StudyDownloader';
import StudyFiles from './components/StudyFiles';
import StudyNotes from './components/StudyNotes';
import Parse from 'parse';
import { Row, Col, Space, Steps, Result, Typography, Button, Tabs, Grid, Tag, Spin, FloatButton, Card, Collapse } from 'antd';
import AccessInfo from '../../components/AcessInfo';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext, SessionContext, LocationContext } from '../../context';
import { Eye, Send, Printer, ThreeDots, Person, QrCode } from 'react-bootstrap-icons';
import { DataSheets } from '../Order/components';
import LogoMini from '../../assets/Logo-Mini.png';
import moment from 'moment';
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;


const Study = () => {
  const [study, setStudy] = useState({});
  const [sending, setSending] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openDataSheets, setOpenDataSheets] = useState(false);
    // Read the :id parameter from the URL
  const { id } = useParams();
  const {location} = useContext(LocationContext);
  const [sessionObject] = useContext(SessionContext);
  const [user] = useContext(UserContext);
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const navLocation = useLocation();

  useEffect(() => {
    // Fetch the study from the server
    const fetchStudy = async () => {
      try {
        const params = { studyId: id };
        const result = await Parse.Cloud.run('getStudy', params);
        console.log('Study', result);
        if (!result) {
          setNotFound(true);
          return;
        }
        setStudy(result);
      } catch (error) {
        console.error('Error while fetching study: ', error);
        setNotFound(true);
      }
    }
    fetchStudy();
  }, [id, refresh]);

  useEffect(() => {
    console.log('Navlocation', navLocation);
    if (!navLocation.state === null) navigate('/login', { state: { route: `/study/${id}` } });
    if (user && !navLocation?.state) navigate('/login', { state: { route: `/study/${id}` } });
    addAccess();
  }, [study, location, sessionObject, user])

  const addAccess = async () => {
    console.log('Adding access', study?.objectId, location, sessionObject?.id);
    if(location && sessionObject.id && study.objectId) {
      const studyPointer = {
        __type: 'Pointer',
        className: 'Studies',
        objectId: study.objectId
      }

      let userPointer = Parse.User.current();

      if(!userPointer) {
        // Load the user from local storage
        const user = localStorage.getItem('user');
        if (user) {
          userPointer = {
            __type: 'Pointer',
            className: '_User',
            objectId: JSON.parse(user).objectId
          }
        } 
      }

      const query = new Parse.Query('Access');
      query.equalTo('study', studyPointer);
      query.equalTo('user', userPointer);
      query.equalTo('session', sessionObject.id);
      const results = await query.find();
      console.log('Access results', results);
      if (results.length > 0) return;

      const Access = Parse.Object.extend('Access');
      const access = new Access();
      access.set('study', studyPointer);
      access.set('user', userPointer);
      access.set('location', location);
      access.set('session', sessionObject.id);
      await access.save();
    }
  }

  const renderTag = (tag) => {
    switch (tag) {
      case 'Pendiente':
        return <Tag style={{padding:10, marginLect: 20, fontSize: 20}}>{tag}</Tag>;
      case 'Enviado a modalidad':
        return <Tag style={{padding:10, marginLect: 20, fontSize: 20}} color="orange">{tag}</Tag>;
      case 'Recibiendo imágenes':
        return <Tag style={{padding:10, marginLect: 20, fontSize: 20}} color="purple">{tag}{<Spin size="small" style={{ paddingLeft: 10}} />}</Tag>;
      case 'Imágenes recibidas':
        return <Tag style={{padding:10, marginLect: 20, fontSize: 20}} color="blue">{tag}</Tag>;
      case 'Entregado':
        return <Tag style={{padding:10, marginLect: 20, fontSize: 20}} color="green">{tag}</Tag>;
      default:
        return <Tag style={{padding:10, marginLect: 20, fontSize: 20}} color="red">{tag}</Tag>;
    }
  }

  const StudyTabs = () => {
    const role = user?.get('role');
    if (role === 'Radiology physician' || role === 'Specialist physician' || role === 'Employed physician' || !user) {
      return <InterpretationInfo study={study} />
    } else {
      return  <Tabs defaultActiveKey="1" style={{ width: '100%' }}>
              <Tabs.TabPane tab="Interpretación" key="1" style={{ width: '100%' }}>
                <InterpretationInfo study={study} noTitle />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Impresiones" key="2" style={{ width: '100%' }}>
                <PrintingInfo study={study} noTitle />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Entregas" key="3" style={{ width: '100%' }}>
                <DeliveryInfo study={study} noTitle />
              </Tabs.TabPane>
              {(role === 'Auditor' || role === 'BOFH') && <Tabs.TabPane tab="Accesos" key="4" style={{ width: '100%' }}>
                <AccessInfo study={study} noTitle />
              </Tabs.TabPane>}
            </Tabs>
    }
  }

const ActionButtons = () => <FloatButton.Group shape='square' style={{position: 'fixed', bottom: 20, right: 20}} trigger='hover' icon={<ThreeDots />} type='primary'>
              {(study.status === 'Imágenes recibidas' || study.status === 'Recibiendo imágenes' || study.status === 'Entregado') &&
                 <FloatButton 
                  type="primary"  
                  onClick={() => window.open(`${process.env.REACT_APP_VIEWER_URL}/viewer?StudyInstanceUIDs=${study?.instanceUUID}`, '_blank')}
                  tooltip='Abrir en visor'
                  icon={<Eye />}
                />}

            {(study.status === 'Imágenes recibidas'  || study.status === 'Entregado') && user &&
              <FloatButton 
                type='primary'
                onClick={() => setSending(study)}
                tooltip='Enviar'
                icon={<Send />}
              />}
            {(study.status === 'Imágenes recibidas' || study.status === 'Entregado') && <StudyDownloader study={study} /> }
             {user && (user?.get('role') === 'BOFH' || user?.get('role') === 'Radiologist') && <FloatButton type='primary' onClick={() => setPrinting(study)} tooltip='Registrar impresión' icon={<Printer />} />}
             {user && (user?.get('role') === 'BOFH' || user?.get('role') === 'Radiologist') && <FloatButton type='primary' onClick={() => setOpenDataSheets(study)} tooltip='Abrir hoja de datos' icon={<QrCode />} />}
            </FloatButton.Group>

  if (notFound) return <Result status='404' title='Estudio no encontrado' subTitle='El estudio al que intentas acceder no existe' />

  if (screens.xs) return (
    <Space direction='vertical' style={{width: '80%', padding: 20}}>
      <Space direction='horizontal' style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <img src={LogoMini} style={{width: 100, height: 100}} />
        <Space direction='vertical' style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
          <Title level={2}>Estudio de radiología</Title>
            {user && renderTag(study.status)}
        </Space>
        </Space>
        <Space direction='vertical' style={{width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start'}}>
          <Title level={3}>{study?.description}</Title>
          <Text level={5}>{study?.patient?.fullName}</Text>
          <Text level={5}>{study?.date && moment(study.date).format('DD/MM/YYYY')}</Text>
          </Space>
          {(study.status === 'Imágenes recibidas' || study.status === 'Recibiendo imágenes' || study.status === 'Entregado') &&
                 <Button 
                  type="primary"  
                  onClick={() => window.open(`${process.env.REACT_APP_VIEWER_URL}/viewer?StudyInstanceUIDs=${study?.instanceUUID}`, '_blank')}
                  tooltip='Abrir en visor'
                  icon={<Eye />}
                >Abrir imágenes</Button>}
                <StudyNotes study={study} user={user} />
                {study?.files?.length > 0 && <StudyFiles study={study} />}
                {study.status !== 'Pendiente' && study.status !== 'Cancelado' && <InterpretationInfo study={study} noTitle />}
            {user && <Collapse defaultActiveKey={['1']} items = {[
              {key: '1', 
                label: 'Información del estudio', 
                children: <StudyInfo study={study} refresh={() => setRefresh(!refresh)} />
              }
            ]}>
            </Collapse>}
          {study.status !== 'Pendiente' && study.status !== 'Cancelado' && <ActionButtons />}
          {!user && <Button type='primary' style={{position: 'fixed', bottom: 20, right: 80}} onClick={() => navigate('/login', { state: { route: `/study/${id}` } })} tooltip='Iniciar sesión' icon={<Person />}>Iniciar sesión</Button>}

      <SendModal open={sending} setOpen={setSending} study={study} setStudy={setStudy} setRefresh={setRefresh} />
      <PrintModal open={printing} setOpen={setPrinting} study={study} setStudy={setStudy} setRefresh={setRefresh} />
      <DataSheets open={openDataSheets} setOpen={setOpenDataSheets} studies={[openDataSheets]} />
    </Space>
  );


  return (
    <Space direction='vertical' style={{width: '100%', padding: 20, overflowY: 'hidden'}}>
      <Row gutter={[16, 16]} style={{width: '100%'}}>
      <Col span={16}>
        <Space direction='horizontal' style={{width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: 20}}>
        <img src={LogoMini} style={{width: 120, height: 100, paddingRight: 20}} />

          <Title level={2}>Estudio</Title>
          <div style={{marginLeft: '105w'}}>
            {renderTag(study.status)}
          </div>
        </Space>
        <StudyInfo study={study} refresh={() => setRefresh(!refresh)} />
        {(study.status === 'Imágenes recibidas' || study.status === 'Recibiendo imágenes' || study.status === 'Entregado') &&
                 <Button 
                  type="primary"  
                  onClick={() => window.open(`${process.env.REACT_APP_VIEWER_URL}/viewer?StudyInstanceUIDs=${study?.instanceUUID}`, '_blank')}
                  tooltip='Abrir en visor'
                  icon={<Eye />}
                >Abrir imágenes</Button>}
        
        {study?.files?.length > 0 && <StudyFiles study={study} />}
        <StudyNotes study={study} user={user} />
        {study.status !== 'Pendiente' && study.status !== 'Cancelado' && <StudyTabs />}
        {study.status !== 'Pendiente' && study.status !== 'Cancelado' && <ActionButtons />}

      </Col>
      <Col span={8}>
        {user && <StudyTimeline study={study} />}
      </Col>
    </Row>
    {!user && <Button type='primary' style={{position: 'fixed', bottom: 20, right: 80}} onClick={() => navigate('/login', { state: { route: `/study/${id}` } })} tooltip='Iniciar sesión' icon={<Person />}>Iniciar sesión</Button>}

      <DataSheets open={openDataSheets} setOpen={setOpenDataSheets} studies={[openDataSheets]} />
      <SendModal open={sending} setOpen={setSending} study={study} setStudy={setStudy} setRefresh={setRefresh} />
      <PrintModal open={printing} setOpen={setPrinting} study={study} setStudy={setStudy} setRefresh={setRefresh} />
    </Space>
  )
}

export default Study;