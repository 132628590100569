import React from 'react';
import { Card, Typography, Space, Grid } from 'antd';

const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

const ReferrerInfo = ({ order, loading }) => {
  const screens = useBreakpoint();

  return (
    <Card loading={loading} title={<Title level={screens.xs ? 5 : 4}>Médico referidor</Title>} style={{ width: screens.xs ? '50vw' : '30vw', height: screens.xs ? '20vh' : '25vh' }}>
      <Space direction="vertical">
        <Text>{order?.clinician?.fullName}</Text>
        <Text>{order?.clinician?.email}</Text>
        <Text>{order?.clinician?.phoneNumber}</Text>
      </Space>
    </Card>
  );
};

export default ReferrerInfo;