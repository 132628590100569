import React, { useEffect, useContext } from 'react';
import { Space, Typography } from 'antd';
import axios from 'axios';
import { GeoAlt, Wifi } from 'react-bootstrap-icons';
import { LocationContext, ThemeContext } from '../../context';
const { Title } = Typography;

const LocateConnection = ({collapsed}) => {
    const {location, setLocation, fetchLocation} = useContext(LocationContext);
    const { theme } = useContext(ThemeContext);


    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchLocation();
          }, 5000); //
      
          // Cleanup function to clear the interval
          return () => clearInterval(intervalId);
        }, []);

    if (process.env.REACT_APP_MULTICLINIC) return
    return (
        <Space direction='vertical' style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 20}} >
            {location === 'Internal' ? <Space direction='horizontal' style={{ width: '100%', justifyContent: 'center', alignItems: 'center'}} >
                <GeoAlt  size={32} color={theme?.algorithm === 'darkAlgorithm' ? 'white' : 'black'} />
                {!collapsed && <Title level={5}>Conexión interna</Title>}
            </Space> : <Space direction='horizontal' style={{ width: '100%', justifyContent: 'center', alignItems: 'center'}} >
                <Wifi size={32} color={theme?.algorithm === 'darkAlgorithm' ? 'white' : 'black'} />
                {!collapsed && <Title level={5}>Conexión externa</Title>}
            </Space>}
        </Space> 
    )
}

export default LocateConnection;