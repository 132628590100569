import { useState } from "react";
import { Select, Spin, Space, Empty, Button, Divider } from "antd";
import moment from "moment";

const SearchInput = (props) => {
    const { index } = props;
    const [data, setData] = useState([]);
    const [value, setValue] = useState();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();
    const [searchTimeout, setSearchTimeout] = useState(null);

    const searchFunction = (value) => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        setLoading(true);
        setSearchTimeout(setTimeout(() => {

            props.fetchOptions(value, props.role).then((results) => {
                if (results.length === 0) {
                    setMessage('No se encontraron resultados');
                }
                console.log('Resultsss', results);
                setData(results);
                setLoading(false);
            })
        }, 200));
    }


    const options = (data || []).map((d) => ({
        value: props.label ? d[props.label] : d.fullName,
        label: props.label ? d[props.label] : d.fullName,
        ...d,
      }));

    return (
      <Select
        showSearch
        loading={loading}
        value={props.text}
        placeholder={props.placeholder}
        style={props.style}
        onSearch={searchFunction}
        locale={{ emptyText: message }}
        onSelect={props.onChange}
        onClick={() => searchFunction('')}
        notFoundContent={
                <Space direction="vertical" size={0} style={{ width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Empty description={'Sin resultados'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    <Button type="link" onClick={() => props.onAdd()}>Agregar</Button>
                </Space>
        }
        options={props.class === 'StudyDescriptions' ? data : options}
        optionRender={(d) => (
            props.role === 'Patients' ?
            <Space size={0} direction="horizontal" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                <Space size={0} direction="vertical">
                    <div><b>{d.label}</b></div>
                    <div>{moment(d.data.birthday?.iso).format('DD/MM/YYYY')}</div>
                </Space>
                    <Divider type="vertical" />
                    <b style={{ color: 'lightgrey', fontSize: 18, alignSelf: 'flex-end' }}>{d.data.gender?.[0]}</b>
            </Space> :
            <Space size={0} direction="vertical">
                    <div><b>{d.label}</b></div>
                </Space>
        )}
      />
    );
  };

  export { SearchInput };