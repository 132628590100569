import React, { useEffect, useState } from 'react';
import { Space, Badge, Timeline, Typography, Divider, Tag, Row, Col } from 'antd';
import Parse from 'parse';
import moment from 'moment';
import { BorderBottom } from 'react-bootstrap-icons';

const { Title, Text } = Typography;

const StudyTimeline = ({study}) => {
    const [events, setEvents] = useState([])    
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const queryEvents = async () => {
            // Fetch events from API
            const params = { studyId: study?.objectId };
            try {
                const results = await Parse.Cloud.run('getEvents', params);
                console.log('Events', results);
                setEvents(results);
                setLoading(false);
            }
            catch (e) {
                console.error('Error while fetching events', e);
                setLoading(false);
            }
        }
        queryEvents();
        }, [study])

        const parseMessage = (event) => {
            let message = ''
            switch(event.type) {
                case 'Study created':
                    message = 'Estudio creado'
                    break;
                case 'Study information completed':
                    message = 'Información del estudio completada'
                    break;
                case 'Study sent to modality':
                    message = 'Estudio enviado a modalidad'
                    break;
                case 'Study made':
                    message = 'Estudio realizado'
                    break;
                case 'Receiving study images':
                    message = 'Recibiendo imágenes'
                    break;
                case 'Study images received':
                    message = 'Imágenes recibidas'
                    break;
                case 'Study images printed':
                    message = 'Estudio impreso'
                    break;
                case 'Interpretation created':
                    message = 'Estudio interpretado'
                    break;
                case 'Interpretation edited':
                    message = 'Interpretación editada'
                    break;
                case 'Study delivered physically':
                    message = 'Estudio entregado físicamente'
                    break;
                case 'Study delivered by email':
                    message = 'Estudio entregado por correo electrónico'
                    break;
                case 'Study delivered by WhatsApp':
                    message = 'Estudio entregado por WhatsApp'
                    break;
                case 'Study canceled':
                    message = 'Estudio cancelado'
                    break;
                case 'Cancelation sent to modality':
                    message = 'Cancelación enviada a modalidad'
                    break;
                default:
                    message = 'Evento desconocido'
            }
            return message
        }

        const parseDateLabel = (event) => {
            const date = event.createdAt?.iso ? moment(event.createdAt.iso) : moment(event.createdAt)
            if (date.isSame(moment(), 'day')) {
                return date.fromNow()
            } else {
                return date.format('LL [a las] HH:mm')
            }
        }


    return (
        <Space direction='vertical' style={{width: '100%' }}>
                    <Title level={3} style={{ alignSelf: 'flex-end' }}>Historial del estudio</Title>
            {loading ? (
                <Text>Cargando...</Text>
            ) : (
                <Space direction='vertical'  style={{width: '100%', height: '70vh', overflowY: 'auto', overflowX: 'hidden', paddingTop: 20, paddingLeft: 40, paddingRight: 10, BorderBottom: '1px solid #f0f0f0'}}>
                    <Divider />
                    <Timeline mode='left' reverse>
                    {events.map(event => {
                        console.log(event)
                        return <Timeline.Item key={event.objectId} label={parseDateLabel(event)} color={event.type === 'Study canceled' ? 'red' : 'blue'} >
                            <Space direction='vertical'>
                                <Text style={{fontWeight: 'bold'}}>{parseMessage(event)}</Text>
                                <Text>{event.user ? event.user.fullName : 'Usuario desconocido'}</Text>
                            </Space>
                        </Timeline.Item>
                    }
                    )}
                    </Timeline>
                </Space>
            )}
        </Space>
    )
}



export default StudyTimeline