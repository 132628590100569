import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Typography, Space, Grid, Button } from 'antd';
import moment from 'moment';

const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

const PatientInfo = ({ order, loading }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();

  return (
    <Card loading={loading} title={<Title level={screens.xs ? 5 : 4}>Paciente</Title>} style={{ width: screens.xs ? '50vw' : '30vw', height: screens.xs ? '20vh' : '25vh' }}>
      <Space direction="vertical">
        {order?.patient?.fullName ? <Button type="link" onClick={() => navigate(`/patient/${order?.patient?.uuid}`)}>{order?.patient?.fullName}</Button> : <Text style={{ fontSize: 20, color: 'red' }}>{order?.patientName}</Text>}
        <Text>{order?.patient?.gender}</Text>
        <Text>{moment(order?.patient?.birthday).format('DD/MM/YYYY')}</Text>
      </Space>
    </Card>
  );
};

export default PatientInfo;