import React, { useEffect } from 'react';
import { FloatButton,  Space, Table, Tag, Modal,  Form, Input, Select, Button, message, Dropdown } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { DebounceSelect } from '../../../components/DebounceSelect';
import Parse from 'parse';


const StudiesList = ({form}) => {
  const [data, setData] = React.useState([]);
  const [studies, setStudies] = React.useState([]);

  const queryStudies = async (value) => {
    const studies = await Parse.Cloud.run('getStudyDescriptions', { value });
    setStudies(studies);
  }


  const columns = [
    {
      title: 'Estudio', 
      dataIndex: 'study',
      key: 'study',
    },
    {
      title: 'Protocolos',
      dataIndex: 'protocols',
      key: 'protocols',
      render: (protocols, study) => (
        <Space>
          {
          protocols.sort((a, b) => a.name.localeCompare(b.name)).map((protocol) => (
            <Tag color={protocol.selected && 'blue' }
            onClick={() => {
              let newStudy = data.filter((d) => d.key === study.key)[0]
              let newProtocols = newStudy.protocols.map((p) => {
                if (p === protocol) {
                  return {...p, selected: !p.selected}
                }
                return p;
              })
              newStudy.protocols = newProtocols;
              const newData = data.map((d) => {
                if (d.key === study.key) {
                  return newStudy;
                }
                return d;
              });
              newData.sort((a, b) => a.key - b.key);
              setData(newData);
              form.setFieldsValue({
                studies: newData
              });
              
            }}
            key={protocol.name}>{protocol.name}</Tag>
          ))}
        </Space>
      )
    },
    {
      title: 'Eliminar',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => {
            setData(data.filter((d) => d.key !== record.key));
            form.setFieldsValue({ studies: data.filter((d) => d.key !== record.key) });
          }}>Eliminar</Button>
        </Space>
      ),
    },
  ];

  const disableSelected = (options) => {
    const selectedSet = new Set(data.map((d) => d.study));
    const newOptions = options.map((o) => {
      if (selectedSet.has(o.description)) {
        return {...o, disabled: true}
      }
      return o;
    }
    );
    newOptions.sort((a, b) => a.description.localeCompare(b.description));
    return newOptions;
  }


    return (
       <Space direction="vertical" style={{ width: '100%' }}>
            <Select
              style={{ width: '100%' }}
              onSearch={queryStudies}
              options={disableSelected(studies.map((s) => ({value: s.description, label: s.description, key: s.objectId, ...s})))}
              showSearch
              value={[]}
              placeholder="Buscar estudio"
              onSelect={(value) => {
                setData([...data, {key: data.length + 1, study: value, ...studies.find((s) => s.description === value)}]);
                form.setFieldsValue({ studies: [...data, {key: data.length + 1, study: value, ...studies.find((s) => s.description === value)}] });
              }}
            />
            <Table columns={columns} dataSource={data} />
        </Space>
    )
}

export  {StudiesList};