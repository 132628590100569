import Parse from 'parse';
import moment from 'moment';

const queryProps = ['fullName', 'email', 'phoneNumber'];
const  constructAccentedRegexPattern = (text)  => {
    // Map of characters to their possible accented versions
    const accentMap = {
      'a': '[aá]',
      'e': '[eé]',
      'i': '[ií]',
      'o': '[oó]',
      'u': '[uú]',
      'A': '[AÁ]',
      'E': '[EÉ]',
      'I': '[IÍ]',
      'O': '[OÓ]',
      'U': '[UÚ]'
    };
  
    // Replace each character in the text with its regex pattern
    return text?.split('').map(char => accentMap[char] || char).join('');
  }

  const fetchUsers = async (value, role) => {
    console.log('Role', role);
    console.log('Value', value);
    let regValue = value?.split(' ').join('.*');  
    regValue = constructAccentedRegexPattern(regValue);
    console.log('RegValue', regValue);
    return new Promise(async (resolve, reject) => {
        const results = await Parse.Cloud.run('queryUsers', { value: regValue, role: role });
        console.log(results);
        const users = results.map(item => {
            return {
                value: item.fullName,
                label: item.fullName,
                key: item.objectId,
                email: item.email,
                ...item
            }
        });
        resolve(users);
    });
}

export default fetchUsers;