import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', backgroundColor: '#4287f5', color: 'white', borderRadius: '10px', margin: '10px' }}>
      <h1>Política de Privacidad de ImageLink</h1>

      <h2>1. Introducción</h2>
      <p>
        En ImageLink, nos comprometemos a proteger su privacidad. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos su información personal cuando utiliza nuestros servicios.
      </p>

      <h2>2. Información que Recopilamos</h2>
      <h3>Información Personal:</h3>
      <ul>
        <li>Nombre</li>
        <li>Fecha de nacimiento</li>
        <li>Sexo</li>
        <li>Correo electrónico</li>
        <li>Número de teléfono</li>
      </ul>

      <h3>Información de Servicios Médicos:</h3>
      <ul>
        <li>Bitácora de servicios prestados a los pacientes, incluyendo tipo de estudio, fecha, imágenes adquiridas en el estudio y su interpretación médica</li>
      </ul>

      <h3>Registro de Accesos:</h3>
      <ul>
        <li>Datos de acceso a la plataforma para proveer seguridad en el sistema</li>
      </ul>

      <h3>Datos de Pago:</h3>
      <ul>
        <li>Información de pago provista por la pasarela de pagos integrada al sistema, propiedad de un tercero</li>
      </ul>

      <h2>3. Cómo Recopilamos la Información</h2>
      <p>Recopilamos información a través de:</p>
      <ul>
        <li>Formularios de registro completados por los usuarios, empleadores de los usuarios o proveedores de servicio de los pacientes</li>
        <li>Registro de servicios prestados y accesos a la plataforma</li>
      </ul>

      <h2>4. Uso de la Información</h2>
      <p>Utilizamos la información recopilada para:</p>
      <ul>
        <li>Prestar los servicios de uso del software</li>
        <li>Brindar actualizaciones de servicio por medios electrónicos</li>
        <li>Proveer seguridad en el sistema</li>
      </ul>

      <h2>5. Compartición de la Información</h2>
      <p>No compartimos su información personal con terceros, excepto para:</p>
      <ul>
        <li>Procesar pagos a través de la pasarela de pagos integrada al sistema, propiedad de un tercero</li>
      </ul>

      <h2>6. Protección de la Información</h2>
      <p>Tomamos medidas de seguridad razonables para proteger su información personal:</p>
      <ul>
        <li>Transmitimos los datos a través de SSL</li>
        <li>Almacenamos los datos en bases de datos encriptadas</li>
      </ul>

      <h2>7. Derechos del Usuario</h2>
      <p>Usted tiene derecho a:</p>
      <ul>
        <li>Acceder a su información personal</li>
        <li>Solicitar la corrección de su información personal</li>
        <li>Solicitar la eliminación de su información personal</li>
      </ul>
      <p>
        Para ejercer estos derechos, por favor contacte con nosotros a través del correo electrónico: <a href="mailto:soporte@devtek.mx" style={{ color: 'white', textDecoration: 'underline' }}>soporte@devtek.mx</a>
      </p>

      <h2>8. Cambios a esta Política de Privacidad</h2>
      <p>
        Podemos actualizar esta Política de Privacidad periódicamente. Le notificaremos sobre cualquier cambio mediante la publicación de la nueva política en nuestra plataforma. Le recomendamos revisar esta política regularmente para estar informado sobre cómo protegemos su información.
      </p>

      <h2>9. Contacto</h2>
      <p>
        Si tiene preguntas o solicitudes relacionadas con su privacidad, por favor contacte con nosotros en:
        <br />
        Correo electrónico: <a href="mailto:soporte@devtek.mx" style={{ color: 'white', textDecoration: 'underline' }}>soporte@devtek.mx</a>
      </p>
      <footer style={{ marginTop: '20px', textAlign: 'center', fontSize: '14px' }}>
        pixOS 2024 Todos los derechos reservados
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
