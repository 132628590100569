import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Space, Select, Spin, Tabs, Checkbox, Divider, message } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { UserOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import Parse from 'parse';

const SendModal = ({ study, setStudy, open, setOpen, setRefresh }) => {
    const [physicalDelivery, setPhysicalDelivery] = useState(false);
    const [other, setOther] = useState(false);
    const [radiologists, setRadiologists] = useState([]);
    const [radiologist, setRadiologist] = useState(undefined);

    useEffect(() => {
        const fetchRadiologists = async () => {
            const params = { role: ['Radiology physician'] };
            const radiologists = await Parse.Cloud.run('queryUsers',  params);
            setRadiologists(radiologists);
        }
        console.log('Fetching radiologists');
        fetchRadiologists();
    }, [open]);

    const saveDelivery = (figure) => {
        try {
            const Deliveries = Parse.Object.extend('Deliveries');
            const delivery = new Deliveries();
            delivery.set('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study.objectId
            });
            delivery.set('type', 'Physical');
            delivery.set('content', [].concat(physicalDelivery.images ? 'Imágenes' : []).concat(physicalDelivery.report ? 'Interpretación' : []));
            delivery.set('user', {
                __type: 'Pointer',
                className: '_User',
                objectId: Parse.User.current()?.id
            });
            delivery.set('deliveredTo', figure)
            delivery.save(); 

            const Events = Parse.Object.extend('Events');
            const event = new Events();
            event.set('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study.objectId
            });
            event.set('type', 'Study delivered physically');
            event.set('user', {
                __type: 'Pointer',
                className: '_User',
                objectId: Parse.User.current()?.id
            });
            event.set('figure', figure);
            event.save();
            
            const Studies = Parse.Object.extend('Studies');
            const studyObject = new Studies();
            studyObject.set('objectId', study.objectId);
            studyObject.set('status', 'Entregado');
            studyObject.save();
            
            message.success('Entrega física registrada correctamente');
            setPhysicalDelivery(undefined);
            setRefresh((prev) => !prev);
            console.log(physicalDelivery);
        } catch (error) {
            message.error('Ocurrió un error al registrar la entrega física');
        }
    }

    const assignRadiologist = async () => {
        console.log(radiologist);
        try {
            const radiologistPointer = {
                __type: 'Pointer',
                className: '_User',
                objectId: radiologist.objectId
            }

            const Studies = Parse.Object.extend('Studies');
            const studyObject = new Studies();
            studyObject.set('objectId', study.objectId);
            studyObject.set('interpreter', radiologistPointer);
            await studyObject.save();

            setStudy({...study, interpreter: radiologist});
            message.success('Radiólogo asignado correctamente');
            // sendEmail('Médico radiólogo');
            setOpen(false);
        } catch (error) {
            message.error('Ocurrió un error al asignar el radiólogo');
        }
    }

    const generateWhatsAppLink = (figure) => {
        switch (figure) {
            case 'Paciente':
                if (!study?.patient?.phoneNumber || study?.patient?.phoneNumber === '') {
                    message.error('No se ha encontrado un número de teléfono para el paciente');
                    return;
                }
                return `https://wa.me/${study?.patient?.phoneNumber}/?text=Buen día, ${study?.patient?.fullName}, le envío ${study?.description}.%0APara acceder al estudio, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`)}`;
            case 'Médico referidor':
                if (!study?.referrer?.phoneNumber || study?.referrer?.phoneNumber === '') {
                    message.error('No se ha encontrado un número de teléfono para el médico referidor');
                    return;
                }
                return `https://wa.me/${study?.referrer?.phoneNumber}/?text=Buen día, Dr. ${study?.referrer?.lastName}, le envío ${study?.description} de ${study?.patient?.fullName}.%0APara acceder al estudio, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`)}`;
            case 'Médico radiólogo':
                if (!study?.interpreter?.phoneNumber || study?.interpreter?.phoneNumber === '') {
                    message.error('No se ha encontrado un número de teléfono para el médico radiólogo');
                    return;
                }
                return `https://wa.me/${study?.interpreter?.phoneNumber}/?text=Buen día, Dr. ${study?.interpreter?.lastName}, le envío ${study?.description} de ${study?.patient?.fullName} para su interpretación.%0APara acceder al estudio, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`)}`;
            case 'Otro':
                return `https://wa.me/${other.phoneNumber}/?text=Buen día, ${other.name}, le envío ${study?.description} de ${study?.patient?.fullName}.%0APara acceder al estudio, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`)}`;
            default:
                return `https://wa.me/${study?.patient?.phoneNumber}/?text=Buen día, le envío ${study?.description} de ${study?.patient?.fullName}.%0APara acceder al estudio, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`)}`;
        }
    }
    const getEmailAddress = (figure) => {
        switch (figure) {
            case 'Paciente':
                return study?.patient?.email;
            case 'Médico referidor':
                return study?.referrer?.email;
            case 'Médico radiólogo':
                return study?.interpreter?.email;
            case 'Otro':
                return other.email;
            default:
                return ''
        }
    }

    const  generateEmail = (figure) => {
        switch (figure) {
            case 'Paciente':
                return `mailto:${study?.patient?.email}?subject=Estudio ${study?.description} de paciente ${study?.patient?.fullName}&body=Buen día, ${study?.patient?.fullName}, le envío ${study?.description}.%0APara acceder al estudio, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`)}`;
            case 'Médico referidor':
                return `mailto:${study?.referrer?.email}?subject=Estudio ${study?.description} de paciente ${study?.patient?.fullName}&body=Buen día, Dr. ${study?.referrer?.lastName}, le envío ${study?.description} de ${study?.patient?.fullName}.%0APara acceder al estudio, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`)}`;
            case 'Médico radiólogo':
                return `mailto:${study?.interpreter?.email}?subject=Estudio ${study?.description} de paciente ${study?.patient?.fullName}&body=Buen día, Dr. ${study?.interpreter?.lastName}, le envío ${study?.description} de ${study?.patient?.fullName} para su interpretación.%0APara acceder al estudio, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`)}`;
            case 'Otro':
                return `mailto:${other.email}?subject=Estudio ${study?.description} de paciente ${study?.patient?.fullName}&body=Buen día, ${other.name}, le envío ${study?.description} de ${study?.patient?.fullName}.%0APara acceder al estudio, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`)}`;
            default:
                return `mailto:${study?.patient?.email}?subject=Estudio ${study?.description} de paciente ${study?.patient?.fullName}&body=Buen día, le envío ${study?.description} de ${study?.patient?.fullName}.%0APara acceder al estudio, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`)}`;
        }
    }

    const sendWhatsApp = (figure) => {
        window.open(generateWhatsAppLink(figure));
        try {
            const Deliveries = Parse.Object.extend('Deliveries');
            const delivery = new Deliveries();
            delivery.set('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study.objectId
            });
            delivery.set('type', 'WhatsApp');
            delivery.set('user', {
                __type: 'Pointer',
                className: '_User',
                objectId: Parse.User.current()?.id
            });
            delivery.set('deliveredTo', figure)
            delivery.save(); 

            const Events = Parse.Object.extend('Events');
            const event = new Events();
            event.set('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study.objectId
            });
            event.set('type', 'Study delivered by WhatsApp');
            event.set('user', {
                __type: 'Pointer',
                className: '_User',
                objectId: Parse.User.current()?.id
            });
            event.set('figure', figure);
            event.save();

            if (figure === 'Paciente') {
                const Studies = Parse.Object.extend('Studies');
                const studyObject = new Studies();
                studyObject.set('objectId', study.objectId);
                studyObject.set('status', 'Entregado');
                studyObject.save();
            }
            

            message.success('Entrega por WhatsApp registrada correctamente');
            setRefresh((prev) => !prev);
        } catch (error) {
            message.error('Ocurrió un error al registrar la entrega por WhatsApp');
        }
    }

    const sendEmail = async (figure) => {
        try {
            const email = getEmailAddress(figure);
            if (!email) {
                message.error('No se ha encontrado una dirección de correo electrónico');
                return;
            }
            const link = encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/study/${study?.internalUUID}`);
            await Parse.Cloud.run('sendResultsEmail', { recipient: email, link });
            const Deliveries = Parse.Object.extend('Deliveries');
            const delivery = new Deliveries();
            delivery.set('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study.objectId
            });
            delivery.set('type', 'Email');
            delivery.set('user', {
                __type: 'Pointer',
                className: '_User',
                objectId: Parse.User.current()?.id
            });
            delivery.set('deliveredTo', figure)
            delivery.save(); 

            const Events = Parse.Object.extend('Events');
            const event = new Events();
            event.set('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study.objectId
            });
            event.set('type', 'Study delivered by email');
            event.set('user', {
                __type: 'Pointer',
                className: '_User',
                objectId: Parse.User.current()?.id
            })
            event.set('figure', figure);
            event.save();

            if (figure === 'Paciente') {
                const Studies = Parse.Object.extend('Studies');
                const studyObject = new Studies();
                studyObject.set('objectId', study.objectId);
                studyObject.set('status', 'Entregado');
                studyObject.save();
            }
            

            message.success('Entrega por correo electrónico registrada correctamente');
            setRefresh((prev) => !prev);
        } catch (error) {
            message.error('Ocurrió un error al registrar la entrega por correo electrónico');
        }
    }
        
    return (
        <Modal
            open={open}
            title={<Title level={2}>Enviar estudio</Title>}
            onCancel={() => setOpen(false)}
            style={{ width: '80vw', top: 20 }}
            okButtonProps={{ style: { display: 'none' } }}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Tabs style={{ width: '100%' }} defaultActiveKey={study?.patient ? '1' : '4'}>
                        <Tabs.TabPane tab="Paciente" key="1" disabled={!study?.patient}>
                            <Space direction="horizontal">
                            <Space direction="vertical">
                                <Space direction="horizontal">
                                    <UserOutlined style={{ fontSize: 20 }} />
                                    <Text strong>{study?.patient?.fullName}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <PhoneOutlined style={{ fontSize: 20 }} />
                                    <Text>{study?.patient?.phoneNumber}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <MailOutlined style={{ fontSize: 20 }} />
                                    <Text>{study?.patient?.email}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <Button type="primary" onClick={() => sendWhatsApp('Paciente')} style={{ marginRight: 10, backgroundColor: '#25D366', borderColor: '#25D366' }}><WhatsAppOutlined /> Enviar por WhatsApp</Button>
                                    <Button type="primary" onClick={() => sendEmail('Paciente')} style={{ marginRight: 10, backgroundColor: '#1890FF', borderColor: '#1890FF' }}><MailOutlined /> Enviar por correo</Button>
                                </Space>
                                <Button type="primary" onClick={() => setPhysicalDelivery({})}><MailOutlined /> Entregar físicamente</Button>
                                {physicalDelivery && <Space direction="vertical">
                                    <Divider style={{ width: '100%' }} />
                                    <Space direction="horizontal">
                                    <Checkbox.Group>
                                        <Checkbox value="1" onChange={() => setPhysicalDelivery({...physicalDelivery, images: true})}>Imágenes</Checkbox>
                                        <Checkbox value="2" onChange={() => setPhysicalDelivery({...physicalDelivery, report: true})}>Interpretación</Checkbox>
                                    </Checkbox.Group>
                                    <Button type="primary" onClick={() => saveDelivery('Paciente')}>Confirmar entrega</Button>
                                    <Button type="secondary" onClick={() => setPhysicalDelivery(undefined)}>Cancelar</Button>
                                </Space>
                                </Space>}
                            </Space>
                        </Space>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Médico tratante" key="2" disabled={!study?.referrer}>
                            <Space direction="horizontal">
                            <Space direction="vertical">
                                <Space direction="horizontal">
                                    <UserOutlined style={{ fontSize: 20 }} />
                                    <Text strong>{study?.referrer?.fullName}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <PhoneOutlined style={{ fontSize: 20 }} />
                                    <Text>{study?.referrer?.phoneNumber}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <MailOutlined style={{ fontSize: 20 }} />
                                    <Text>{study?.referrer?.email}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <Button type="primary" onClick={() => sendWhatsApp('Médico referidor')} style={{ marginRight: 10, backgroundColor: '#25D366', borderColor: '#25D366' }}><WhatsAppOutlined /> Enviar por WhatsApp</Button>
                                    <Button type="primary" onClick={() => sendEmail('Médico referidor')} style={{ marginRight: 10, backgroundColor: '#1890FF', borderColor: '#1890FF' }}><MailOutlined /> Enviar por correo</Button>
                                </Space>
                            </Space>
                            </Space>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Médico radiólogo" key="3">
                            {study?.interpreter && <Space direction="horizontal">
                            <Space direction="vertical">
                                <Space direction="horizontal" style={{ width: '100%', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Space direction="vertical">
                                        <Space direction="horizontal">
                                            <UserOutlined style={{ fontSize: 20 }} />
                                            <Text strong>{study?.interpreter?.fullName}</Text>
                                        </Space>
                                        <Space direction="horizontal">
                                            <PhoneOutlined style={{ fontSize: 20 }} />
                                            <Text>{study?.interpreter?.phoneNumber}</Text>
                                        </Space>
                                        <Space direction="horizontal">
                                            <MailOutlined style={{ fontSize: 20 }} />
                                            <Text>{study?.interpreter?.email}</Text>
                                        </Space>
                                    </Space>
                                    {!study?.interpretation ? <Button type="primary" onClick={() => setStudy({...study, interpreter: undefined})}>Cambiar radiólogo</Button> : <Button disabled type="primary">Interpretación realizada</Button>}
                                </Space>
                                <Space direction="horizontal">
                                    <Button type="primary" onClick={() => sendWhatsApp('Médico radiólogo')} style={{ marginRight: 10, backgroundColor: '#25D366', borderColor: '#25D366' }}><WhatsAppOutlined /> Enviar por WhatsApp</Button>
                                    <Button type="primary" onClick={() => sendEmail('Médico radiólogo')} style={{ marginRight: 10, backgroundColor: '#1890FF', borderColor: '#1890FF' }}><MailOutlined /> Enviar por correo</Button>
                                </Space>
                            </Space>
                            </Space>}
                            {!study?.interpreter && <Space direction="horizontal">
                                <Text>Asignar radiólogo</Text>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="Seleccionar radiólogo"
                                    onChange={(value) => setRadiologist(radiologists.find(radiologist => radiologist.objectId === value))}
                                >
                                    {radiologists.map(radiologist => <Select.Option key={radiologist.objectId} value={radiologist.objectId}>{radiologist.fullName}</Select.Option>)}
                                </Select>
                                <Button type="primary" onClick={() => assignRadiologist()}>Asignar</Button>
                            </Space>}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Otro" key="4">
                            <Space direction="horizontal">
                            <Space direction="vertical">
                                <Space direction="horizontal">
                                    <UserOutlined style={{ fontSize: 20 }} />
                                    <Input placeholder="Nombre completo" onChange={(e) => setOther({...other, name: e.target.value})} />
                                </Space>
                                <Space direction="horizontal">
                                    <PhoneOutlined style={{ fontSize: 20 }} />
                                    <Input placeholder="52 378 111 11 11" onChange={(e) => setOther({...other, phoneNumber: e.target.value})} />
                                </Space>
                                <Space direction="horizontal">
                                    <MailOutlined style={{ fontSize: 20 }} />
                                    <Input placeholder="email@email.com" onChange={(e) => setOther({...other, email: e.target.value})} />
                                </Space>
                                <Space direction="horizontal">
                                    <Button type="primary" onClick={() => sendWhatsApp('Otro')} style={{ marginRight: 10, backgroundColor: '#25D366', borderColor: '#25D366' }}><WhatsAppOutlined /> Enviar por WhatsApp</Button>
                                    <Button type="primary" onClick={() => sendEmail('Otro')} style={{ marginRight: 10, backgroundColor: '#1890FF', borderColor: '#1890FF' }}><MailOutlined /> Enviar por correo</Button>
                                </Space>
                            </Space>
                            </Space>
                        </Tabs.TabPane>
                    </Tabs>
                </Space>
        </Modal>
    );
}

export default SendModal;