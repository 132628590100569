import React, { useEffect } from "react";
import { FloatButton,  Space, Table, Tag, Modal,  Form, Input, Card, Button, message } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import NewOrderModal from "./components/NewOrderModal";
import { Grid, DatePicker } from 'antd';
import Title from "antd/es/typography/Title";
import { Filter, ArrowClockwise } from "react-bootstrap-icons";
import moment from 'moment';
import Parse from "parse";
import esES from 'antd/lib/locale/es_ES';  // Import Ant Design locale
const { useBreakpoint } = Grid;


const Orders = () => {

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [Order, setOrder] = React.useState({});
    const [isNew, setIsNew] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [isSearching, setIsSearching] = React.useState(false);
    const [modalities, setModalities] = React.useState([]);
    const [modality, setModality] = React.useState({});
    const [screen, setScreen] = React.useState('');
    const [openFilters, setOpenFilters] = React.useState(false);
    const [filters, setFilters] = React.useState({ dates: [], value: '' });
    const screens = useBreakpoint();
    const navigate = useNavigate();

    useEffect(() => {
      if (screens.xs) {
        setScreen('xs');
      } else if (screens.sm) {
        setScreen('sm');
      } else if (screens.md) {
        setScreen('md');
      } else if (screens.lg) {
        setScreen('lg');
      } else if (screens.xl) {
        setScreen('xl');
      } else if (screens.xxl) {
        setScreen('xxl');
      }
    }, [screens]);



    const formRef = React.useRef(null);
    const [form] = Form.useForm();

    let searchTimeout = null;

    const { Search } = Input;


    useEffect(() => {
        queryOrders();
    }, []);

    useEffect(() => {
      searchTimeout = setTimeout(() => {
        setIsSearching(true);
        queryOrders(filters);
      }, 300);
      return () => clearTimeout(searchTimeout);
    }, [filters]);



    const queryOrders = async (filters) => {
      setLoading(true);
      let params = { ...filters };
      params.dates = params.dates?.length > 0 ? [moment(params.dates[0].$d).startOf('month').toDate(), moment(params.dates[1].$d).endOf('month').toDate()] : [];
      console.log('Params', params);
      const result = await Parse.Cloud.run('getOrders', params);
      setData(result);
      setLoading(false);
      setIsSearching(false);
    }

    const columns = [
      {
        title: 'Número de orden',
        dataIndex: 'orderID',
        key: 'orderID',
      },
      {
        title: 'Paciente',
        dataIndex: 'patient',
        key: 'patient',
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        render: (patient, item) => <a onClick={() => navigate(`/patient/${item.patient.uuid}`)}>{patient?.fullName}</a>,
      },
      {
        title: 'Fecha',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date) => new Date(date).toLocaleDateString(),
      },
      {
        title: 'Referidor',
        dataIndex: 'clinician',
        key: 'clinician',
        render: (clinician) => clinician?.fullName,
      },
      {
        title: 'Convenio',
        dataIndex: 'client',
        key: 'client',
        render: (client) => client ? <Tag color="purple">{client?.name}</Tag> : 'Particular',
      },
      {
        title: 'Hora',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date) => new Date(date).toLocaleTimeString(),
      },
      // {
      //   title: 'Número de estudios',
      //   dataIndex: 'items',
      //   key: 'items',
      //   render: (items) => console.log(items) 
      // },
      // {
      //     title: 'Registrada por',
      //     dataIndex: 'createdBy',
      //     key: 'createdBy',
      //     render: (createdBy) => createdBy?.fullName,
      // },
      {
          title: 'Médico solicitante',
          dataIndex: 'clinician',
          key: 'clinician',
          render: (clinician) => clinician?.fullName,
      },
      {
        title: 'Acciones',
        key: 'actions',
        render: (text, record) => (
            <Space direction="horizontal">
                <Button type='link' onClick={() => navigate(`/order/${record.internalUUID}`, { state: { fromMenu: true } })}>Ver</Button>
            </Space>
        )
      }
    ]; 

    const AddOrderButton = () => <FloatButton icon={<UserAddOutlined />} onClick={() => {
      setOpen(true)
      setIsNew(true)
      setOrder({})
      form.resetFields();
    }} tooltip="Agregar Orden" 
    type="primary" />

    const filterComponents = () => <Space direction="horizontal">
     <DatePicker.RangePicker
                style={{
                  width: 300,
                }}
                value={filters.dates}
                onChange={(value) => setFilters({...filters, dates: value})}
                locale={esES}
              />
    <Search 
    placeholder="Buscar Orden"
    onChange={(e) => setFilters({...filters, value: e.target.value})}
    style={{ width: 200, float: 'right' }}
    loading={isSearching}
    />
    <Button onClick={() => queryOrders()} loading={loading}><ArrowClockwise /></Button>
    </Space>

    const FiltersModal = () => <Modal
    style={{ top: 20, height: '80vh' }}
    open={openFilters}
    onCancel={() => setOpenFilters(false)}
    footer={[
      <Button key="submit" type="primary" onClick={() => setOpenFilters(false)} >Ok</Button>
    ]}
    >
      <Space direction="vertical">
        <Title level={3}>Filtros</Title>
        {filterComponents()}
      </Space>
    </Modal>

    const FilterButton = () => 
      <Button onClick={() => setOpenFilters(true)}>
      <Filter style={{ fontSize: 20 }}/>
      </Button>

    const CardRenderer = () => <Space direction="vertical" style={{ width: '100%', overflowY: 'auto', height: '80vh' }}>
    {data.length > 0 && data.map((order) => (
      <Card key={order.orderID} title={<Space direction="horizontal">{order.orderID}<Tag color={order.status === 'Realizado' ? 'green' : 'blue'}>{order.status}</Tag></Space>} style={{ width: '100%', height: '30vh' }} onClick={() => navigate(`/order/${order.internalUUID}`, { state: { fromMenu: true } })} hoverable>
        <Space direction="vertical">
          <p>Paciente: {order.patient?.fullName}</p>
          <p>{moment(order.date).format('DD/MM/YYYY')} {moment(order.date).format('HH:mm')}</p>
          <p>Médico referidor: {order.clinician?.fullName}</p>
        </Space>
      </Card>
    ))}
  </Space>

if (screen === 'xs') {
  return (
    <Space direction="vertical" style={{ width: '80vw', padding: 20}}>
      <FiltersModal />
      <NewOrderModal setOpen={setOpen} open={open} editing={editing} setEditing={setEditing} Order={Order} setOrder={setOrder} isNew={isNew} setIsNew={setIsNew} submitting={submitting} setSubmitting={setSubmitting} modalities={modalities} setModalities={setModalities} modality={modality} setModality={setModality} formRef={formRef} form={form} queryOrders={queryOrders} />
      <AddOrderButton />
    
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
          <Title level={3}>Ordenes</Title>
          <FilterButton />
          <Button onClick={() => queryOrders()} loading={loading}><ArrowClockwise /></Button>

        </Space>

        <CardRenderer />
    </Space>
  );
}
  

    return (
        <Space direction="vertical" style={{ width: '80vw', padding: 20 }}>
          <NewOrderModal setOpen={setOpen} open={open} editing={editing} setEditing={setEditing} Order={Order} setOrder={setOrder} isNew={isNew} setIsNew={setIsNew} submitting={submitting} setSubmitting={setSubmitting} modalities={modalities} setModalities={setModalities} modality={modality} setModality={setModality} formRef={formRef} form={form}  queryOrders={queryOrders} />
          <AddOrderButton />
          <Space direction="horizontal" style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Title level={1}>Ordenes</Title>
            {filterComponents()}
          </Space>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{ pageSize: 10 }}
            style={{ width: '100%', overflowX: 'auto', overflowY: 'auto', height: '80vh' }}
          />
        </Space>
    );
}

export default Orders;