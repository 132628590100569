import React, { useEffect, useState, useContext } from 'react';
import { DebounceSelect, ProceduresTable, ProceduresChart, StatCards } from './components';
import { Space, DatePicker, Select, Skeleton, Input, Grid, Modal, Button, Flex, Typography, Spin } from 'antd';
import {Filter } from 'react-bootstrap-icons';
import fetchUsers from './utils/fetchUsers';
import fetchModality from './utils/fetchModality';
import fetchStudies from './utils/fetchStudies';
import fetchClinics from './utils/fetchClinics';
import { ClinicConfigContext } from '../../context';
import Title from 'antd/es/typography/Title';
import Parse from 'parse';

const { RangePicker } = DatePicker;
const { useBreakpoint } = Grid;
const { Text } = Typography;

const Analysis = () => {    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({ radiologists: [], referrers: [], interpreters: [], modality: [], dates: []});
    const [showFilters, setShowFilters] = useState(false);
    const [chartGroup, setChartGroup] = useState('radiologists');
    const { clinicConfig } = useContext(ClinicConfigContext);
    const screens = useBreakpoint();

    useEffect(() => {
        const handleQuery = async () => {
            setLoading(true);
            console.log('Filters', filters);
            const data = await fetchStudies({ ...filters, clinicId: clinicConfig?.clinic?.objectId });
            console.log(data);
            setLoading(false);
            setData(data);
        }

        handleQuery();
    }, [filters, clinicConfig]);

    const generateReport = async () => {
        console.log('Generating report');
        let newData = data
        delete newData.rawData
        const result = await Parse.Cloud.run("studiesReport", { filters, data: newData, chartGroup });
        console.log(result);
    }

    const TriStateButton = ({labels, filters, prop, setFilters}) => {
        const internalSetState = () => {
            if (filters[prop] === true) {
                setFilters({...filters, [prop]: false});
            } else if (filters[prop] === false) {
                setFilters({...filters, [prop]: null});
            } else {
                setFilters({...filters, [prop]: true});
            }
        }

        const setLabel = () => {
            if (filters[prop] === true) return labels[0];
            if (filters[prop] === false) return labels[1];
            return labels[0];
        }

        const setType = () => {
                if (filters[prop]  === true || filters[prop] === false) return 'primary';
                return 'default';
            }
        
        return (
            <Button style={{ margin: 10}} onClick={() => internalSetState()} type={setType()}>{setLabel()}</Button>
        );
    }

    const NumberFilter = ({filters, prop, setFilters, label}) => {
        return (
            <Space direction="vertical">
                <Text>{label}</Text>
                    <Select options={[{value: '>', label: 'Mayor  o igual que'}, {value: '<', label: 'Menor o igual que'}, {value: '=', label: 'Igual a'}]}  onChange={(newValue) => setFilters({...filters, [prop]: {...filters[prop], type: newValue}})} style={{ width: 200 }} value={filters[prop]?.type} />
                    <Input value={filters[prop]?.value} onChange={(e) => setFilters({...filters, [prop]: {...filters[prop], value: e.target.value}})} />
            </Space>
        );
    }

    const filterOptions = ({direction}) => <Space direction="vertical" style={{ width: '100%' }}>
    <Space direction={direction} style={{ width: '100%' }}>
    {process.env.REACT_APP_MULTICLINIC && <DebounceSelect
        mode="multiple"
        value={filters.clinics}
        placeholder="Buscar clínicas"
        fetchOptions={(value) => fetchClinics(value)}
        onFocus={() => fetchClinics('')}
        onChange={(newValue) => setFilters({...filters, clinics: newValue})}
        renderOptions={(option) => <Space direction="vertical">
            {console.log(option)}
            <span>{option.name}</span>
        </Space>}
        style={{
            width: direction === 'vertical' ? '100%' : '15vw'
        }}
    />}
    <DebounceSelect
        mode="multiple"
        value={filters.radiologists}
        placeholder="Buscar radiólogo"
        fetchOptions={(value) => fetchUsers(value, ['Radiologist'])}
        onFocus={() => fetchUsers('', ['Radiologist'])}
        onChange={(newValue) => setFilters({...filters, radiologists: newValue})}
        renderOptions={(option) => <Space direction="vertical">
            <span>{option.label}</span>
        </Space>}
        style={{
            width: direction === 'vertical' ? '100%' : '15vw'
        }}
    />
     <DebounceSelect
        mode="multiple"
        value={filters.referrers}
        placeholder="Buscar referidor"
        fetchOptions={(value) => fetchUsers(value, ['Specialist physician', 'Employed physician'])}
        onFocus={() => fetchUsers('', ['Specialist physician', 'Employed physician'])}
        onChange={(newValue) => setFilters({...filters, referrers: newValue})}
        renderOptions={(option) => <Space direction="vertical">
            <span>{option.label}</span>
        </Space>}
        style={{
            width: direction === 'vertical' ? '100%' : '15vw'
        }}
    />
    <DebounceSelect
        mode="multiple"
        value={filters.interpreters}
        placeholder="Buscar intérprete"
        onFocus={() => fetchUsers('', ['Radiology physician'])}
        fetchOptions={(value) => fetchUsers(value, ['Radiology physician'])}
        onChange={(newValue) => setFilters({...filters, interpreters: newValue})}
        renderOptions={(option) => <Space direction="vertical">
            <span>{option.label}</span>
        </Space>}
        style={{
            width: direction === 'vertical' ? '100%' : '15vw'
        }}
    />
    <DebounceSelect
        mode="multiple"
        value={filters.modality}
        placeholder="Buscar modalidad"
        fetchOptions={(value) => fetchModality(value)}
        onFocus={() => fetchModality('')}
        onChange={(newValue) => setFilters({...filters, modality: newValue})}
        renderOptions={(option) => <Space direction="vertical">
            <span>{option.label}</span>
        </Space>}
        style={{
            width: direction === 'vertical' ? '100%' : '15vw'
        }}
    />
    <RangePicker picker="month" onChange={(dates) => setFilters({...filters, dates})} value={filters.dates} />
    <Button type="primary" onClick={() => setFilters({})}>Limpiar filtros</Button>

    {/* <Select placeholder={'Select user types'} mode="multiple" value={userTypes} onChange={(newValue) => setUserTypes(newValue)} options={[{value: 'User', label: 'User'}, {value: 'Trainning', label: 'Trainning'}, {value: 'Collaborator', label: 'Collaborator'}, {value: 'Demo', label: 'Demo'}]} style={{ width: 200 }} /> */}
    </Space>
    <Flex vertical={direction === 'vertical'} style={{ width: '100%', justifyContent: 'space-around' }}>
        {loading && <Spin />}
        <TriStateButton filters={filters} prop="onlyCompleted" setFilters={setFilters} labels={['Realizados', 'Pendientes', 'Cancelados', 'Realizados']} />
        <TriStateButton filters={filters} prop="specialScheduled" setFilters={setFilters} labels={['Ordinarios', 'Guardia', 'Guardia']} />
        <TriStateButton filters={filters} prop="interpreted" setFilters={setFilters} labels={['Interpretados', 'No interpretados', 'Interpretados']} />
        <TriStateButton filters={filters} prop="incompleteInformation" setFilters={setFilters} labels={['Información completa', 'Falta información', 'Falta información']} />
        <TriStateButton filters={filters} prop="createdFromModality" setFilters={setFilters} labels={['Creados desde sistema', 'Creados desde modalidad', 'reados desde modalidad']} />
        <NumberFilter filters={filters} prop="deliveriesNumber" setFilters={setFilters} label="Envíos" />
        <NumberFilter filters={filters} prop="interpretationsNumber" setFilters={setFilters} label="Impresiones" />
        </Flex>
    </Space>

    const FiltersModal = () => <Modal
    title="Filtros"
    open={showFilters}
    onCancel={() => setShowFilters(false)}
    footer={[
      <Button key="submit" type="primary" onClick={() => setShowFilters(false)} >Ok</Button>,
        <Button key="reset" onClick={() => {
            setFilters({ radiologists: [], referrers: [], interpreters: [], modality: [], dates: [], onlyCompleted: false })
            setShowFilters(false)
        }}>Limpiar</Button>
    ]}
    >
      {filterOptions({direction: 'vertical'})}
    </Modal>

    if (screens.xs) {
        return (
            <Space direction="vertical" style={{ width: '100%', padding: '20px' }}>
                <FiltersModal />
                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Title level={3}>Análisis</Title>
                    <Filter style={{ fontSize: 40 }} onClick={() => setShowFilters(true)} />
                </Space>
                <Space direction="vertical" style={{ width: '100%', padding: '20px', alignItems: 'flex-end' }}>
                    <StatCards data={data} filters={filters} />
                    {/* <Button type="primary" onClick={() => generateReport()}>Generar reporte</Button> */}

                    <ProceduresChart data={data} setChartGroup={setChartGroup} chartGroup={chartGroup} />
                    <ProceduresTable data={data?.rawData} />
                </Space>
            </Space>
        );
    }

    return (
            <Space direction="vertical" style={{ width: '100%', padding: '20px' }}>
                <b style={{ fontSize: '2rem' }}>Análisis</b>
                <Space direction="vertical" style={{ width: '100%', padding: '20px', alignItems: 'flex-end' }}>
                    {filterOptions({direction: 'horizontal'})}
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <StatCards data={data} filters={filters} />
                        {/* <Button type="primary" onClick={() => generateReport()}>Generar reporte</Button> */}

                        <ProceduresChart data={data} setChartGroup={setChartGroup} chartGroup={chartGroup} />
                        <ProceduresTable data={data?.rawData} />
                    </Space>
                </Space>
            </Space>
        );
}

export default Analysis;