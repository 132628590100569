import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, Space, Radio, Select, message } from 'antd';
import moment from 'moment';
import { saveClient } from '../utils/query';
import { UserAddOutlined } from '@ant-design/icons';
import { set } from 'immutable';

const ClientModal = ({open, setOpen, client, refresh}) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        form.resetFields();
        console.log('Client', client);
        if (client?.editing) {
            console.log('Client', client);
            form.setFieldsValue({
                name: client.name,
                contactName: client.contactName,
                phoneNumber: client.phoneNumber,
                email: client.email,
            });
        }
    }, [client]);

    const onFinish = async (values) => {
        console.log('Finish', values);
        form.validateFields().then(async () => {      
            console.log('Finishhh', values);
            try{
                const patientInfo = {
                    ...client,
                    ...values,
                }
                console.log(patientInfo);
                setSubmitting(true);
                const patientObject = await saveClient(patientInfo);
                console.log(patientObject);
                setOpen(false);
                setSubmitting(false);
                message.success('Cliente guardado correctamente');
                refresh();
            } catch (error) {
                setSubmitting(false);
                message.error('Error al guardar el cliente');
            }
        }).catch(() => {
            message.error('Por favor, complete los campos requeridos');
        })
    }

    const saveButton = (
        <Button 
            type="primary" 
            style={{ width: '100%' }} 
            onClick={() => form.submit()}
            disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
            loading={submitting}
            >
                {client?.editing ? 'Guardar cliente' : 'Crear cliente'}
            </Button>
    );

 
    return (
        <Modal
        title={client?.editing ? 'Editar cliente' : 'Crear cliente'}
        open={open}
        footer={saveButton}
        onCancel={() => setOpen(undefined)}
        >
        <Form
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            form={form}
            onFinish={onFinish}
        >
            <Form.Item label="Nombre" required name="name" rules={[{ required: true, message: 'Por favor, ingrese el nombre' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Persona de contacto" key="contactName" name="contactName"
            rules={[{ required: true, message: 'Por favor, ingrese el nombre de la persona de contacto' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Teléfono" key="phoneNumber" name="phoneNumber"
            rules={[{ required: true, pattern: /^[0-9]{1,2}[0-9]{10}$/, message: 'Por favor, ingrese un número de teléfono válido, incluyendo el código de país' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Correo" key="email" name="email"
            rules={[{ required: true, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: 'Por favor, ingrese un correo válido' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
        </Form>
        </Modal>
    );
}

export default ClientModal;