import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Table, Tag, Card, Select, Button, message, Spin, DatePicker, Grid, Checkbox } from "antd";
import Text from "antd/lib/typography/Text";
import { DebounceSelect } from "../../components/DebounceSelect";
import MoreFiltersModal from './components/MoreFiltersModal';
import { fetchPatients } from './utils/query';
import moment from "moment";
import esES from 'antd/lib/locale/es_ES';  // Import Ant Design locale
import Title from "antd/es/typography/Title";
import { Check, Filter, ArrowClockwise, CloudSnowFill } from "react-bootstrap-icons";
import Parse from "parse";
import { UserContext, LocationContext, ClinicConfigContext } from '../../context';

const { useBreakpoint } = Grid;


export default function Dashboard () {

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [modalities, setModalities] = React.useState([]);
    const [isSearching, setIsSearching] = React.useState(false);
    const [filters, setFilters] = React.useState({patients: [], dates: [], status: []});
    const [filtersModal, setFiltersModal] = React.useState(false);
    const [user, setUser] = useContext(UserContext);
    const {location, setLocation} = useContext(LocationContext);
    const { clinicConfig } = useContext(ClinicConfigContext);
    const screens = useBreakpoint();
    const navigate = useNavigate();


    useEffect(() => {
        queryStudies();
    }, [filters]);

    const getModalities = async () => {
      const query = new Parse.Query('Modalities');
      const results = (await query.find()).map((item) => item.toJSON());
      setModalities(results);
    }

    useEffect(() => {
      getModalities();
    }, []);
      

    const queryStudies = async (value) => {
      setLoading(true);
      console.log('Clinic ID', clinicConfig?.clinic?.objectId);
      console.log('Role', user?.get('role'));
      let params = { ...filters, clinicId: clinicConfig?.clinic?.objectId, location };
      console.log('Paramsss', params.dates);
      params.dates = params.dates?.length > 0 ? [moment(params.dates[0].$d).startOf('day').toDate(), moment(params.dates[1].$d).endOf('day').toDate()] : [];
      console.log('Params', params.dates);
      const results = await Parse.Cloud.run('getStudies', params);
      console.log('Studies', results);
      setData(results);
      setLoading(false);
    }


    const renderTag = (tag) => {
      switch (tag) {
        case 'Pendiente':
          return <Tag>{tag}</Tag>;
        case 'Enviado a modalidad':
          return <Tag color="orange">{tag}</Tag>;
        case 'Recibiendo imágenes':
          return <Tag color="purple">{tag}{<Spin size="small" style={{ paddingLeft: 5}} />}</Tag>;
        case 'Imágenes recibidas':
          return <Tag color="blue">{tag}</Tag>;
        case 'Entregado':
          return <Tag color="green">{tag}</Tag>;
        default:
          return <Tag color="red">{tag}</Tag>;
      }
    }

    const columns = [
      {
        title: 'ID',
        dataIndex: 'studyID',
        key: 'studyID',
        sorter : (a, b) => a.studyID.localeCompare(b.studyID),
      },
      {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status',
        render: (status) => renderTag(status),
        sorter: (a, b) => a.status.localeCompare(b.status),
      },
      {
        title: 'Paciente',
        dataIndex: 'patient',
        key: 'patient',
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        render: (patient, item) => item.patient ? <a onClick={() => navigate(`/patient/${item.patient.uuid}`)}>{patient.fullName}</a> : item.patientName,
        sorter: (a, b) => a.patient?.fullName.localeCompare(b.patient?.fullName),
      },
      {
        title: 'Fecha',
        dataIndex: 'date',
        key: 'date',
        render: (date) => moment(date).format('L'),
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      },
      {
        title: 'Hora',
        dataIndex: 'date',
        key: 'date',
        render: (date) => moment(date).format('HH:mm A'),
      },
      {
        title: 'Estudio',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
          title: 'Radiólogo',
          dataIndex: 'radiologist',
          key: 'radiologist',
          render: (radiologist) => radiologist?.fullName,
          sorter: (a, b) => a.radiologist?.fullName.localeCompare(b.radiologist?.fullName),
      },
      {
        title: 'Referidor',
        dataIndex: 'referrer',
        key: 'referrer',
        render: (referrer) => referrer?.fullName,
        sorter: (a, b) => a.referrer?.fullName.localeCompare(b.referrer?.fullName),
      },
      {
        title: 'Convenio',
        dataIndex: 'client',
        key: 'client',
        render: (client) => client ? <Tag color="purple">{client?.name}</Tag> : 'Particular',
      },
      {
        title: 'Interpretación',
        dataIndex: 'interpretation',
        key: 'interpretation',
        render: (interpretation, study) => interpretation ? <Button type='link' onClick={() => navigate(`/interpretation/${study.internalUUID}`, { state: { fromMenu: true } })}>Ver</Button> : 'Sin interpretar',
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
         <Space size="middle">
            {record.specialScheduled ? <Tag color="purple">Guardia</Tag> : null}
            {record.validationPending ? <Tag color="red">Validación pendiente</Tag> : null}
          </Space>
        ),
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Button type="primary" 
            style={{ backgroundColor: record.incompleteInformation ? 'orange' : '' }}
            onClick={
              () => navigate(`/study/${record.internalUUID}`, { state: { fromMenu: true } })
            }>{record.incompleteInformation && (
                user?.get('role') === 'Radiologist' || 
                user?.get('role') === 'Administrative' || 
                user?.get('role') === 'BOFH') 
              ? 'Completar' : 'Ver estudio'}</Button>
          </Space>
        ),
      }
    ];

    const countFilters = () => {
      console.log('Filters', filters);
      let count = 0;
      Object.keys(filters).forEach(key => {
        if (Array.isArray(filters[key]) && filters[key].length > 0) {
          count += 1
        }
        if (typeof filters[key] === 'boolean' && filters[key] !== null) {
          count += 1;
        }
      });
      return count;
    }
    const mainFilters = () => <Space>
      <DebounceSelect
                  mode="multiple"
                  value={filters.patients}
                  placeholder="Buscar paciente"
                  fetchOptions={fetchPatients}
                  onChange={(newValue) => setFilters({...filters, patients: newValue})}
                  renderOptions={(option) => <Space direction="vertical">
                    <span>{option.label}</span>
                  </Space>}
                  style={{
                      width: 200,
                  }}
              />
              <DatePicker.RangePicker
                style={{
                  width: 300,
                }}
                value={filters.dates}
                onChange={(value) => setFilters({...filters, dates: value})}
                locale={esES}
              />
              <Select
                mode="multiple"
                placeholder="Selecciona un estado"
                style={{ width: 200 }}
                value={filters.status}
                onChange={(value) => setFilters({...filters, status: value})}
                allowClear
                options={[
                  { label: 'Pendiente', value: 'Pendiente' },
                  { label: 'Enviado a modalidad', value: 'Enviado a modalidad' },
                  { label: 'Recibiendo imágenes', value: 'Recibiendo imágenes' },
                  { label: 'Imágenes recibidas', value: 'Imágenes recibidas' },
                  { label: 'Entregado', value: 'Entregado'}
                ]}
              />
              <Select
                mode="multiple"
                placeholder="Selecciona una modalidad"
                style={{ width: 200 }}
                value={filters.modalities}
                onChange={(value, item) => setFilters({...filters, modalities: item})}
                allowClear
                options={modalities?.map((item) => ({ label: item.commonName, value: item.objectId, key: item.objectId, ...item }))}
              />

              {user?.get('role') === 'Radiology physician' && clinicConfig?.allowInterpretersToSeeAll &&  <Checkbox onChange={(e) => setFilters({...filters, asignedToMe: e.target.checked})}>Asignados a mí</Checkbox>}
              <Button onClick={() => setFiltersModal(true)}>Filtros avanzados {countFilters() > 0 && <Tag style={{ marginLeft: 8}} color="blue">{countFilters()}</Tag>}</Button>
              <Button onClick={() => setFilters({patients: [], dates: [], status: []})}>Limpiar filtros</Button>
              <Button onClick={() => queryStudies()} loading={loading}><ArrowClockwise /></Button>
    </Space>

    const FiltersButton = () => <Button onClick={() => setFiltersModal(true)} ><Filter style={{ fontSize: 20 }} /></Button>

    const StudyList = () => <Space direction="vertical" style={{ paddingBottom: 20, width: '100%' }}>
      {data.length > 0 && data.map((study) => (
        <Card key={study.studyID} title={<Space direction="horizontal">{study.studyID}{renderTag(study.status)}</Space>} style={{ width: '100%' }} onClick={() => navigate(`/study/${study.internalUUID}`, { state: { fromMenu: true } })} hoverable>
            <Space size="middle">
                {study.specialScheduled ? <Tag color="purple">Guardia</Tag> : null}
                {study.validationPending ? <Tag color="red">Validación pendiente</Tag> : null}
              </Space>
          <Space direction="vertical">
            <p>Paciente: {study.patient?.fullName ? study.patient?.fullName : study.patientName}</p>
            <Space direction="horizontal">
              <Text>Estudio {study.description}</Text>
            </Space>
            <Space direction="horizontal">
              <Text>{moment(study.date).format('L')}</Text>
              <Text>{moment(study.date).format('HH:mm A')}</Text>
            </Space>
           
            <Space direction="horizontal">
              <Text>Referidor: {study.referrer?.fullName ? study.referrer?.fullName : study.referingPhysicianName}</Text>
            </Space>
            <Space direction="horizontal">
              <Text>Operador: {study.radiologist?.fullName ? study.radiologist?.fullName : 'Sin radiólogo asignado'}</Text>
            </Space>
            <Space direction="horizontal">
              <Text>Intérprete: {study.interpretation ? study.interpretation?.user?.fullName : 'Sin interpretar'}</Text>
            </Space>
          </Space>
        </Card>
      ))}
    </Space>
    

    
    if (screens.xs) return <Space direction="vertical" style={{ padding: 20, width: '100%' }}>
          <MoreFiltersModal open={filtersModal} filters={filters} onCancel={() => setFiltersModal(false)} onOk={setFilters} config={clinicConfig} modalities={modalities} />
          <div>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
              <Title>Estudios</Title>
              <FiltersButton />
              <Button onClick={() => queryStudies()} loading={loading}><ArrowClockwise /></Button>
            </Space>

          </div>
          <StudyList />
      </Space>

    return <Space direction="vertical" style={{ padding: 20, width: '100%' }}>
            <MoreFiltersModal open={filtersModal} filters={filters} onCancel={() => setFiltersModal(false)} onOk={setFilters} config={clinicConfig} />
            <div>
              <Space direction="vertical">
                <Title>Estudios</Title>
                {mainFilters()}
              </Space>

            </div>
            <Table columns={columns} dataSource={data} loading={loading} locale={{ emptyText: 'No hay Estudios'  }} 
              rowClassName={(record, index) => {
                if (record.validationPending) {
                  return 'validation-pending';
                }
              }}
            />
        </Space>
    
}