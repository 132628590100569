import React, { useState, useEffect } from "react";
import { Space, Grid, Button, Input, Flex, Card } from "antd";
import Parse from "parse";

const sendTestNotification = async (title, body) => {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('No token found');
        return;
    }

    const userId = Parse.User.current()?.id;
    Parse.Cloud.run('sendNotification', { userId, body, title })
        .then((response) => {
            console.log('Notification sent:', response);
        })
        .catch((error) => {
            console.error('Error sending notification:', error);
        });
}



const TestNotification = () => {
    const [user, setUser] = useState(null);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    return (
        <Space direction="vertical">
            <Input placeholder="Título" onChange={(e) => setTitle(e.target.value)} />
            <Input placeholder="Cuerpo" onChange={(e) => setBody(e.target.value)} />
            <Button onClick={() => sendTestNotification(title, body)}>Enviar notificación de prueba</Button>
        </Space>
    )
}

const Tools = () => {
    return (
        <Flex direction="row" align="flex-start" justify="space-around" style={{ height: '100vh', padding: 20 }}>
            <Card title="Notificación de prueba">
                <TestNotification />
            </Card>
        </Flex>
    )
}

export default Tools;