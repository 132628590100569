import React, { useEffect, useContext, useState } from 'react';
import { createBrowserRouter, RouterProvider, Routes } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';
import Parse from 'parse';
import esES from 'antd/lib/locale/es_ES';  // Import Ant Design locale
import moment from 'moment';
import 'moment/locale/es';  // Import moment locale
import { UserProvider, LocationProvider, SessionProvider, RoutesProvider, RoutesContext, ClinicConfigProvider, ThemeProvider, ThemeContext } from './context';
import { messageService } from './Firebase';
import { getToken, requestPermission } from 'firebase/messaging';
import Login from './sites/Login/Login';
import { outerRouter } from './routes';
const { defaultAlgorithm, darkAlgorithm } = theme;

moment.locale('es');


Parse.initialize(process.env.REACT_APP_APP_ID, process.env.REACT_APP_JS_API_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_HOST_URL;

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
    console.log('Service Worker registration successful with scope: ', registration.scope);
  }).catch((err) => {
    console.log('Service Worker registration failed: ', err);
  });
}

const getMessaging = async () => {
  if (typeof Notification === 'undefined') {
    console.log('Notifications not supported');
    return;
}
  if (Notification.permission === 'granted') {
    console.log("Requesting permission for messaging");
   
    console.log("Requesting token for messaging");
    const token = await getToken(messageService, {
      vapidKey: "BCqBgJpJqzqoppqcHKqc5ZOBEwAFV5_s9poit6OmZrKiFEbogHrBz6w6w1NYmZmICNBzBRWP6-qMBqeIWBOqN8g"
    });
    if (token) {
      localStorage.setItem('token', token);
      Parse.Cloud.run('addNotificationsToken', { token, userId: Parse.User.current()?.id });
    }
  }
}

function App() {
  const [currentRouter, setCurrentRouter] = useState(outerRouter); 

  useEffect(() => {
    getMessaging();
  }, []);


  const Route  = ({children}) => {
    const {dynamicRouter} = useContext(RoutesContext);

    useEffect(() => {
      console.log('Dynamic router:', dynamicRouter);
      console.log('Current router:', currentRouter);
      if (dynamicRouter && dynamicRouter !== currentRouter) {
        console.log('Dinamyc router:', dynamicRouter);
        setCurrentRouter(dynamicRouter);
      }
    }, [dynamicRouter]);

    return children;
  }

  console.count('App render');

  console.log('Current router:', currentRouter);

  if (!currentRouter || currentRouter.length === 0) return <div>Loading...</div>;

  const router = createBrowserRouter(currentRouter);

  const ConfigComponent = ({children}) => {
    const {theme, locale, componentSize} = useContext(ThemeContext);

   return <ConfigProvider 
      theme={{ token: { 
        colorPrimary: '#4287f5',
        colorSecondary: '#FF7700',
      },
      algorithm : theme.algorithm !== 'darkAlgorithm' ? defaultAlgorithm : darkAlgorithm
    }} 
      locale={esES} 
      componentSize="middle"
    >
    <Route>
      <RouterProvider router={router} />
    </Route>
  </ConfigProvider>
  }

  return (
    <RoutesProvider>
      <UserProvider>
        <LocationProvider>
          <SessionProvider>
            <ClinicConfigProvider>
              <ThemeProvider>
               <ConfigComponent>
               
              </ConfigComponent>
              </ThemeProvider>
            </ClinicConfigProvider>
          </SessionProvider>
        </LocationProvider>
      </UserProvider>
    </RoutesProvider>
  );
}

export default App;
