import React, { useEffect, useState } from 'react';
import { Space, Badge, Table, Typography, Divider, Tag, Row, Col, DatePicker, Grid, Card } from 'antd';
import Parse from 'parse';
import moment from 'moment';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const Access = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const screens = useBreakpoint();

    useEffect(() => {
        const queryAccess = async () => {
            const data = await Parse.Cloud.run('getAccess', {});
            setData(data);
            setLoading(false);
        }
        queryAccess();
    }, [])

    const columns = [
        {
            title: 'Usuario',
            dataIndex: 'user',
            key: 'user',
            render: (user) => user?.fullName || 'Usuario desconocido'
        },
        {
            title: 'Estudio',
            dataIndex: 'study',
            key: 'study',
            render: (study) => <Space direction='horizontal'>
                <Text>{study?.description},</Text>
                <Text>{study?.patient?.fullName},</Text>
                <Text>{moment(study?.date?.iso).format('DD/MM/YYYY')}</Text>
            </Space>
        },
        {
            title: 'Ubicación',
            dataIndex: 'location',
            key: 'location',
            render: (location) => location === 'Internal' ? 'Interno' : 'Externo'
        },
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('DD/MM/YYYY HH:mm')
        }
    ]

    const Filters = () =>  <Space direction='horizontal' style={{width: '100%', justifyContent: 'flex-end', paddingRight: 20}}>
    <DatePicker.RangePicker
        style={{width: '90%'}}
        onChange={(dates) => {
            const query = new Parse.Query('Access');
            query.include(['user', 'study', 'study.patient']);
            query.descending('createdAt');
            query.greaterThanOrEqualTo('createdAt', new Date(moment(dates?.[0]?.$d).startOf('day').format()));
            query.lessThanOrEqualTo('createdAt', new Date(moment(dates?.[1]?.$d).endOf('day').format()));
            query.find().then((results) => {
                const data = results.map(access => access.toJSON());
                setData(data);
            })
        }}
    /> 
    </Space>


    if (screens.xs) {
        return (
            <Space direction='vertical' style={{padding: 20, width: '100%'}} >
                <Title level={3}>Accesos</Title>
                <Filters />
                <Divider />
                {data && data.map(access => (
                    <Card key={access.objectId} title={access.user?.fullName || 'Usuario desconocido'} style={{ width: '60vw' }}>
                        <Space direction='vertical'>
                        <Text>{moment(access.createdAt).format('L HH:mm')}</Text>

                           <Text>{access.study?.description}</Text>
                            <Text>{access.study?.patient?.fullName}</Text>
                            <Text>{access.location === 'Internal' ? 'Interno' : 'Externo'}</Text>
                        </Space>
                    </Card>
                ))}
            </Space>
        )
    }

    return (
        <Space direction='vertical' style={{padding: 20, width: '100%'}} >
            <Space direction='horizontal' style={{width: '100%', justifyContent: 'space-between'}}>
                <Title level={1}>Accesos</Title>
                <Filters />
            </Space>
            <Table dataSource={data} columns={columns} loading={loading} />
        </Space>
    )
}

export default Access;
