import React, { useEffect } from "react";
import { FloatButton,  Space, Table, Tag, Modal,  Card, Input, Select, Button, message, Grid } from "antd";
import ClientModal from './components/ClientModal';
import { useNavigate } from "react-router-dom";
import { UserAddOutlined } from "@ant-design/icons";
import {query } from './utils/query';
import moment from "moment";
import Title from "antd/es/typography/Title";
import { Filter } from "react-bootstrap-icons";
const { useBreakpoint } = Grid;

export default function Dashboard () {

    const [clients, setClients] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [isSearching, setIsSearching] = React.useState(false);
    const [client, setClient] = React.useState(undefined);
    const [showFilters, setShowFilters] = React.useState(false);
    const screens = useBreakpoint();
    const navigate = useNavigate();

    let searchTimeout = null;

    const { Search } = Input;


    useEffect(() => {
        queryClients();
    }, []);

    const queryClients = async (value) => {
        const results = await query(value);
        console.log(results);
        setClients(results);
        setIsSearching(false);
        setLoading(false);
    }
    

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => <Button type="link" onClick={() => {
          setClient({...record, editing: true});
          setOpen(true);
        }}>{name}</Button>
      },
      {
        title: 'Persona de contacto', 
        dataIndex: 'contactName',
        key: 'contactName',
      },
      {
        title: 'Teléfono',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: (phone) => phone?.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5')
      },
      {
        title: 'Correo electrónico',
        dataIndex: 'email',
        key: 'email',
      },
    ];

    const filters = () =>  <Search 
    placeholder="Buscar cliente"
    onChange={(e) => {
      setIsSearching(true);
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        queryClients(e.target.value);
      }, 300);
    }}
    style={{ width: 200, float: 'right' }}
    loading={isSearching}
    />

    const FiltersModal = () => <Modal
    title="Filtros"
    open={showFilters}
    onCancel={() => setShowFilters(false)}
    footer={[
      <Button key="submit" type="primary" onClick={() => setShowFilters(false)} >Ok</Button>
    ]}
    >
      {filters()}
    </Modal>

    const ClientsList = () => <Space direction="vertical" style={{ paddingBottom: 20, width: '100%' }}>
      {clients.length > 0 && clients.map((client) => (
        <Card key={client.uuid} title={<Space direction="horizontal">{client.fullName}</Space>} style={{ width: '100%' }} onClick={() => navigate(`/client/${client.uuid}`)} hoverable>
          <Space direction="vertical">
            <p>{client.gender}</p>
            <p>{client.phoneNumber}</p>
            <p>{client.email}</p>
            <p>Número de estudios: {client.studies}</p>
          </Space>
        </Card>
      ))}
    </Space>

    if (screens.xs) return  <Space direction="vertical" style={{ width: '100%', padding: 20 }}>
    <FloatButton icon={<UserAddOutlined />} onClick={() => setOpen(true)} tooltip="Agregar cliente" type="primary" />
    <ClientModal open={open} setOpen={setOpen} refresh={queryClients} client={client} />
    <FiltersModal />
      <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
        <Title level={3}>Clientes</Title>
        <Filter style={{ fontSize: 40 }} onClick={() => setShowFilters(true)} />
      </Space>
      <ClientsList />
</Space>

    return (
        <Space direction="vertical" style={{ width: '100%', padding: 20 }}>
            <FloatButton icon={<UserAddOutlined />} onClick={() => setOpen(true)} tooltip="Agregar cliente" type="primary" />
            <ClientModal open={open} setOpen={setOpen} refresh={queryClients} client={client} />
            <Space size="large">
                <Title level={1}>Clientes</Title>
                {filters()}
              </Space>
            <Table columns={columns} dataSource={clients} loading={loading} locale={{ emptyText: 'No hay clientes'  }} />
        </Space>
    );
}