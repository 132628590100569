import React, { useEffect, useState } from 'react';
import { Space, Card, Button, Typography, Skeleton, Tag, Row, Col, Table } from 'antd';
import Parse from 'parse';
import moment from 'moment';
const { useNavigate } = require('react-router-dom');

const { Title, Text } = Typography;
const DeliveryInfo = ({study, noTitle}) => {
    const [deliveries, setDeliveries] = useState([]); 
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDeliveries = async () => {
            const params = { studyId: study?.objectId };
            const deliveries = await Parse.Cloud.run('getDeliveries', params);
            setDeliveries(deliveries);
            setLoading(false);
            console.log('Fetch deliveries', deliveries);
        }

        fetchDeliveries();
    }, [study])

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [])

    const parseType = (type) => {
        let message = ''
        switch(type) {
            case 'Physical':
                message = 'Físico'
                break;
            case 'Email':
                message = 'Correo electrónico'
                break;
            case 'WhatsApp':
                message = 'WhatsApp'
                break;
            default:
                message = 'Evento desconocido'
        }
        return message
    }

    const columns = [
        {
            title: 'Usuario',
            dataIndex: 'user',
            key: 'user',
            render: (user) => user ? user.fullName : ''
        }, 
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt.iso).format('LL [a las] HH:mm')
        },
        {
            title: 'Entregado a',
            key: 'deliveredTo',
            render: (info) => info.deliveredTo
        },
        {
            title: 'Tipo',
            key: 'type',
            render: (info) => parseType(info.type)
        },
        {
            title: 'Contenido',
            key: 'content',
            render: (info) => info?.content?.map(content => content).join(', ') 
        }
    ]


    if (noTitle) {
        return (
            <Space direction='horizontal' style={{padding: 20, width: '60vw'}} >
                    {!loading ? deliveries.length > 0 ? (
                        <Row style={{width: '60vw'}}>
                           <Table dataSource={deliveries} columns={columns} style={{width: '60vw'}} />
                    </Row>
                    ) : (
                        <Title level={3}>Este estudio no ha sido entregado</Title>
                    ) : <Skeleton active style={{ width: '60vw' }} />}
            </Space>
        )
    }
    return (
        <Space direction='horizontal' style={{padding: 20, width: '100%'}} >
            <Card title="Entregas" style={{ width: '100%' }} loading={loading}>
                {!loading ? deliveries.length > 0 ? (
                    <Row>
                       <Table dataSource={deliveries} columns={columns} />
                </Row>
                ) : (
                    <Title level={3}>Este estudio no ha sido entregado</Title>
                ) : <Skeleton active style={{ width: '50vw' }} />}
                
            </Card>
        </Space>
    )
}

export default DeliveryInfo
