import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, message, Space } from 'antd';
import Parse from 'parse';
import ForgotPassword from './ForgotPassword';


import './Login.scss';
import { login } from './utils/login';
import { UserContext, RoutesContext } from '../../context';

import Logo from '../../assets/Logo-HRSL.webp';
import pixosBanner from '../../assets/pixOS-banner.png';

const Login = () => {
    const [ user, setUser ] = useContext(UserContext);
    const [username, setUsername ] = useState('');
    const [password, setPassword ] = useState('');
    const [errorState, setErrorState ] = useState('');
    const [openPassword, setOpenPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const location = useLocation();
    const {changeRouter} = useContext(RoutesContext);

    useEffect(() => {
        if (user) {
            console.log('User: ', user);
            handleRoute();
        }
    }, [user]);

    const handleLogin = async () => {
        setLoading(true);
        let errorMessage = ''
        try {
            const user = await Parse.User.logIn(username.toLowerCase(), password);
            setUser(user);
            messageApi.open({
                type: 'success',
                content: 'Bienvenido'
              });
        } catch (error) {
            setErrorState('error');
            console.log(error);
            switch (error.code) {
                case 101:
                    errorMessage = 'Contraseña incorrecta'
                    break;
                case 200:
                    errorMessage = 'El usuario no existe';
                    break;
                default:
                    errorMessage = 'Error desconocido, intenta de nuevo';
                    break;
            }

            messageApi.open({
                type: 'error',
                content: errorMessage
              });
        }
        setLoading(false);
        
    }
    const handleRoute = () => {
        console.log('Location', location);
        changeRouter('default');
        setTimeout(() => {
            if (location.state && location.state.route) {
                navigate(location.state.route, { state: { fromLogin: true } });
            } else {
                navigate('/studies')
            }
        }, 1000);
    }



    return(
        <div className='app'>
            {contextHolder}
            <div className = "login-form-box">
            <div className="form">
                <div>    
                    <div className='Login-header'>
                        <a href="https://pixos.com.mx/">
                            <img className="logo-img" src={pixosBanner} alt="Logo" style={{marginBottom: 40 }}/>
                        </a>
                       <br />
                    </div>
                    <ForgotPassword open={openPassword} setOpen={setOpenPassword}/>
                    <Form 
                        name="normal_login" 
                        className="login-form" 
                        initialValues={{ remember: true }} 
                        onFinish={handleLogin} 
                        //onFinish={handleSubmit}
                    >
                        
                        <div className='input-container'>
                            <Form.Item 
                                name="username" 
                                rules={[{ required: true, message: 'Por favor, introduce tu usuario' }]} 
                            >
                                <Input status={errorState} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Usuario" value={username} onChange={(e) => setUsername(e.target.value)}/>
                            </Form.Item>
                        </div> 
    
                        <div className='input-container'>
                            <Form.Item 
                                name="password" 
                                rules={[{ required: true, message: 'Por favor, introduce tu contraseña' }]} 
                            >
                                <Input.Password status={errorState} prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />) }/>
                            </Form.Item>
                        </div>
    
                      
    
                        <div className='button-container'>
                            <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>Iniciar sesión</Button>
                            </Form.Item>    
                        </div>    
                    </Form>
                    <Divider/>
                    <div className='button-container'>
                            <Form.Item>
                                <Space direction="vertical" style={{justifyContent: 'center', alignItems: 'center'}}>
                                    <Button type="link" onClick={() => setOpenPassword(true)}>¿Olvidaste tu contraseña?</Button>
                                    {/* <Button type="link" onClick={() => navigate('/register')}>Registrarse</Button> */}
                                    {process.env.REACT_APP_MULTICLINIC && <Button type="link" onClick={() => navigate('/terms')}>Términos y condiciones</Button>}
                                    {process.env.REACT_APP_MULTICLINIC && <Button type="link" onClick={() => navigate('/privacy')}>Aviso de privacidad</Button>}
                                </Space>
                            </Form.Item>
                        </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Login;