import React, { useEffect, useState, useContext } from 'react';
import { Space, Card, Button, Typography, Divider, Tag, Row, Col, Skeleton } from 'antd';
import Parse from 'parse';
import moment from 'moment';
import { UserContext } from '../context';
const { useNavigate } = require('react-router-dom');

const { Title, Text } = Typography;
const InterpretationInfo = ({study, noTitle}) => {
    const [interpretation, setInterpretation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [user] = useContext(UserContext)
    const navigate = useNavigate();

    useEffect(() => {
       const fetchInterpretation = async () => {
        if (study?.interpretation) {
            setLoading(true);
            const params = { studyId: study?.objectId, interpretationId: study?.interpretation?.objectId };
            const interpretation = await Parse.Cloud.run('getInterpretation', params);
            setInterpretation(interpretation);
            setLoading(false);
         } else {
            setLoading(false);
            }}

            fetchInterpretation();
    }, [study])

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [])
    

    if (noTitle) return (
        <Space direction='horizontal' style={{padding: 20, width: '60vw'}} >
            <Card style={{ width: '60vw' }} loading={loading} >
                {!loading ? interpretation ? (
                    <Row>
                        <Col span={24}>
                            <Space direction='vertical'>
                                <Text>Interpretado por</Text>
                                <Title level={4}>{interpretation?.user?.fullName}</Title>
                            </Space>
                        </Col>
                        <Col span={24}>
                            <Space direction='vertical'>
                                <Text>Fecha de interpretación</Text>
                                <Title level={4}>{moment(interpretation?.createdAt).format('LL [a las] HH:mm')}</Title>
                            </Space>
                        </Col>
                        <Col span={24}>
                            <Space direction='horizontal'>
                                <Button type="primary" onClick={() => navigate(`/interpretation/${study?.internalUUID}`, {state: { fromMenu: true }})}>Ver interpretación</Button>
                            </Space>
                        </Col>
                    </Row>
                ) : (
                    <Space direction='vertical' style={{width: '60vw' }}>
                        <Title level={3}>Este estudio no ha sido interpretado</Title>
                        {user?.get('role') === 'Radiology physician' || user?.get('role') === 'BOFH'
                            ? (!study?.interpreter || study?.interpreter?.objectId === user.id) 
                                ? <Button type="primary" onClick={() => navigate(`/interpretation/${study?.internalUUID}`, {state: { fromMenu: true }})}>Interpretar estudio</Button>
                                : <Button type="primary" disabled>Estudio asignado a otro médico radiólogo</Button>
                            : null}
                    </Space>
                ) : <Skeleton active style={{ width: '60vw' }} />}
            </Card>
        </Space>
    )

    return (
        <Space direction='horizontal' style={{padding: 20, width: '100%'}} >
            <Card title="Interpretación" style={{ width: '100%' }} loading={loading}>
                {!loading ? interpretation ? (
                    <Row>
                        <Space direction='horizontal'>
                        <Col span={24}>
                                <Space direction='vertical'>
                                    <Text>Interpretado por</Text>
                                    <Title level={4}>{interpretation?.user?.name}</Title>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction='vertical'>
                                    <Text>Fecha de interpretación</Text>
                                    <Title level={4}>{moment(interpretation?.createdAt?.iso).format('LL [a las] HH:mm')}</Title>
                                </Space>
                        </Col>
                        <Col span={24}>
                            <Space direction='horizontal'>
                                <Button type="primary" onClick={() => navigate(`/interpretation/${study?.internalUUID}`, {state: { fromMenu: true }})}>Ver interpretación</Button>
                            </Space>
                        </Col>
                        </Space>

                </Row>
                ) : (
                    <Space direction='vertical'>
                        <Title level={3}>Este estudio no ha sido interpretado</Title>
                        {user?.get('role') === 'Radiology physician' || user?.get('role') === 'BOFH'
                            ? (!study?.interpreter || study?.interpreter?.objectId === user.id) 
                                ? <Button type="primary" onClick={() => navigate(`/interpretation/${study?.internalUUID}`, {state: { fromMenu: true }})}>Interpretar estudio</Button>
                                : <Button type="primary" disabled>Estudio asignado a otro médico radiólogo</Button>
                            : null}
                    </Space>
                ) : <Skeleton active style={{ width: '50vw' }} />}
                
            </Card>
        </Space>
    )
}

export default InterpretationInfo
