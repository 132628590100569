import React, { useState, useEffect } from "react";
import { Space, Grid, Button, Input, Typography } from "antd";
import RadClinician from "./components/RadClinician";
import RadTechnician from "./components/RadTechnician";
import Parse from "parse";
import { getMessaging , getToken, onMessage } from "firebase/messaging";
const { useBreakpoint } = Grid;
const { Title } = Typography;

const Profile = () => {
    const [notificationPermission, setNotificationPermission] = useState(false);

    useEffect(() => {
        if (typeof Notification === 'undefined') {
            console.log('Notifications not supported');
            return;
        }
        if (Notification.permission !== 'granted') {
            setNotificationPermission(false);
        }
        if (Notification.permission === 'granted') {
            setNotificationPermission(true);
            const token = localStorage.getItem('token');
            if (!token) {
                requestPushPermission();
            } else {
                setNotificationPermission(true);
                Parse.Cloud.run('addNotificationsToken', { token, userId: Parse.User.current()?.id });
            }
        }
    }, []);

    const requestPushPermission = async () => {
        if (Notification.permission !== 'granted') {
            await Notification.requestPermission();
        }
        if (Notification.permission === 'granted') {
                const token = await getToken(getMessaging(), {
                    vapidKey: "BCqBgJpJqzqoppqcHKqc5ZOBEwAFV5_s9poit6OmZrKiFEbogHrBz6w6w1NYmZmICNBzBRWP6-qMBqeIWBOqN8g"
                });
                localStorage.setItem('token', token);
                const userId = Parse.User.current()?.id;
                Parse.Cloud.run('addNotificationsToken', { token, userId });
                setNotificationPermission(true);
        }
    }


    const [user, setUser] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const screens = useBreakpoint();

    useEffect(() => {
       const fetchUser = async () => {
            const user = Parse.User.current()

            await user?.fetch();
            console.log(user?.toJSON());
            setUser(user?.toJSON());
        }

        fetchUser();
    }, [refresh]);

    const renderRoleComponent = () => {
        console.log(user);
        switch (user?.role) {
            case 'Radiology physician':
                return <RadClinician user={user} setRefresh={setRefresh} screens={screens} />
            case 'Radiologist':
                return <RadTechnician user={user} setRefresh={setRefresh} screens={screens} />
            default:
                return null;
        }
    }

    return (
       <Space direction="vertical" style={{width: '100%', padding: 20}}>
              <Title level={1}>Perfil</Title>
            {!notificationPermission ? <Button onClick={() => requestPushPermission()}>Activar notificaciones</Button> : <Button disabled>Notificaciones activas</Button>}
              {user && renderRoleComponent()}
        </Space>
    );

}

export default Profile;