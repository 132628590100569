import React, { createContext, useEffect, useState } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState({
        token: {
        colorPrimary: '#4287f5',
        colorSecondary: '#FF7700',
        },
        algorithm: '',
    });

    useEffect(() => {
        readThemeFromLocalStorage();
    }, []);

    useEffect(() => {
        console.log('Theme changed:', theme);
        if (theme?.algorithm !== '') saveThemeToLocalStorage(theme);
    }, [theme]);

    const readThemeFromLocalStorage = () => {
        console.log('Read theme from local storage');
        const theme = localStorage.getItem('theme');
        console.log('Theme local:', theme);
        if (theme) {
        setTheme(JSON.parse(theme));
        }
    }

    const saveThemeToLocalStorage = (theme) => {
        console.log('Save theme:', theme);
        localStorage.setItem('theme', JSON.stringify(theme));
    }

    
    
    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
        {children}
        </ThemeContext.Provider>
    );
    };