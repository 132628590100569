import React, { useState, useEffect } from 'react';
import {Card, Button, Space, Typography, Divider, Popover, message, Modal, Tabs, Select, Input, Checkbox, Form, Row, Col} from 'antd';

const StudyFiles = ({study, setRefresh}) => {

    const viewFile = (file) => {
        const url = file.url;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.filename);
        document.body.appendChild(link);
        link.click();
    }

    return (
        <Card title="Archivos" style={{ width: '100%', overflowY: 'scroll', maxHeight: 300 }}>
          <Space direction='horizontal'>
               {study?.files?.map((file, index) => (
                <Space key={index} direction='horizontal'>
                     <Space key={index} direction='vertical'>
                          <Typography.Text>{file.filename}</Typography.Text>
                          <Button onClick={() => viewFile(file)} type="primary">Descargar</Button>
                     </Space>
                     <Divider type="vertical" />
                </Space>
                ))}
            </Space>
        </Card>
    )
}

export default StudyFiles;