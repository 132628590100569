import moment from 'moment';
import Parse from 'parse';
import { v4 as uuidv4 } from 'uuid';
const queryProps = ['fullName', 'email', 'phoneNumber'];
const  constructAccentedRegexPattern = (text)  => {
    // Map of characters to their possible accented versions
    const accentMap = {
      'a': '[aá]',
      'e': '[eé]',
      'i': '[ií]',
      'o': '[oó]',
      'u': '[uú]',
      'A': '[AÁ]',
      'E': '[EÉ]',
      'I': '[IÍ]',
      'O': '[OÓ]',
      'U': '[UÚ]'
    };
  
    // Replace each character in the text with its regex pattern
    return text.split('').map(char => accentMap[char] || char).join('');
  }



const query = async (value) => {
   // Count the number of studies for each patient
    return new Promise(async (resolve, reject) => {
        try {
            const Patients = Parse.Object.extend('Patients');
            const query = new Parse.Query(Patients);
            if (value) {
                let regValue = value.split(' ').join('.*');  
                regValue = constructAccentedRegexPattern(regValue);
                const queryObjects = []
                queryProps.forEach(prop => {
                    const q = new Parse.Query('Studies');
                    q.matches(prop, regValue, 'i');
                    queryObjects.push(q);
                });
                query._orQuery(queryObjects);
            }
            const patients = await query.find();
            const patientsWithStudies = await Promise.all(patients.map(async (patient) => {
                const Studies = Parse.Object.extend('Studies');
                const query = new Parse.Query(Studies);
                query.equalTo('patient', patient);
                const studies = await query.find();
                return {
                    ...patient.toJSON(),
                    studies: studies.length
                }
            }));
            resolve(patientsWithStudies);
        }
        catch (error) {
            console.error('Error while fetching patients: ', error);
            reject(error);
        }
    });
}

const savePatient = async (patient) => {
    return new Promise(async (resolve, reject) => {
        const query = new Parse.Query('Index');
        query.equalTo('name', 'patient');
        const index = await query.first();
        index.increment('value');
        console.log(patient);
        try {
            const Patients = Parse.Object.extend('Patients');
            const newPatient = new Patients();
            patient.objectId && newPatient.set('objectId', patient.objectId);
            newPatient.set('patientID', 'P-' + String(index.get('value')).padStart(6, '0'));
            newPatient.set('firstName', patient.firstName?.trim());
            newPatient.set('lastName', patient.lastName?.trim());
            newPatient.set('fullName', `${patient.firstName?.trim()} ${patient.lastName?.trim()}`);
            newPatient.set('birthday', new Date(moment(patient.birthday, 'DD/MM/YYYY').toISOString()));
            newPatient.set('gender', patient.gender);
            newPatient.set('phoneNumber', patient.phoneNumber);
            newPatient.set('uuid', patient.uuid || uuidv4());
            newPatient.set('email', patient.email?.trim()?.toLowerCase());
            const result = await newPatient.save();
            await index.save();
            resolve(result);
        }
        catch (error) {
            console.error('Error while saving patient: ', error);
            reject(error);
        }
    }
    );
}
        

  export { query, savePatient };