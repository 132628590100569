import React, { useEffect, useState } from "react";
import Parse from "parse";
import { Layout, message, Input, Space, Card, Button, Table } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { useNavigate } from "react-router-dom";
import SignatureModal from "./SignatureModal";

const RadClinician = ({user, setRefresh, screens}) => {
    const [templates, setTemplates] = useState([]);
    const [signing, setSigning] = useState(false);
    const [initials, setInitials] = useState('')
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchTemplates = async () => {
            const query = new Parse.Query('Templates');
            const userPointer = {
                __type: 'Pointer',
                className: '_User',
                objectId: user?.objectId
            }
            query.equalTo('user', userPointer);
            const results = await query.find();
            const templates = results.map(template => template.toJSON());
            setTemplates(templates);
            setInitials(user?.initials);
        }

        fetchTemplates();
    }, [user]);

    const saveInitials = async (initials) => {
        setLoading(true);
        const user = Parse.User.current();
        user.set('initials', initials);
        user.save().then(() => {
            message.success('Iniciales guardadas correctamente');
            setLoading(false);
        }).catch((error) => {
            message.error('Ocurrió un error al guardar las iniciales');
            setLoading(false);
        })  
    }

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (text, template) => (
                <Space direction="horizontal">
                    <a onClick={() => navigate('template', {state: {template}})}>Editar</a>
                </Space>
            )
        }
    ]

    const Initials = () => <Card title="Mis iniciales" style={{ width: 300 }}>
        <Space direction="vertical">
            <Input 
                placeholder="Iniciales" 
                value={initials}
                onChange={(e) => setInitials(e.target.value)}
                onPressEnter={(e) => saveInitials(e.target.value)} 
                />
                <Button
                    loading={loading}
                    type="primary"
                    onClick={() => saveInitials(initials)}
                >Guardar</Button>
        </Space>
    </Card>

    const TemplatesTable = () => <Card title="Mis templates" style={{ width: '80vw' }}>
    <Table dataSource={templates} rowKey="objectId" columns={columns} />
  <Button type="primary" onClick={() => navigate('template')}>Crear template</Button>
  </Card>

  const SignatureCard = () => <Card title="Mi firma" style={{ width: 300 }} onClick={() => setSigning(true)}>
  {user?.signature 
      ? <Space direction="vertical">
          <img src={user.signature.url} alt="Firma" style={{width: '100%'}} />
          <span dangerouslySetInnerHTML={{__html: user.signature?.footer}} align="center"></span>
      </Space>
      : 'Sin firma'}
</Card>

const TemplatesCards = () => <Space direction="vertical">
    <Card title="Mis templates" style={{ width: 300 }}>
        <Space direction="vertical">
        {templates.map(template => (
           <Text style={{fontSize: 16, fontWeight: 'bold'}} onClick={() => navigate('template', {state: {template}})}>{template.name}</Text>
        ))}
    <Button type="primary" onClick={() => navigate('template')}>Crear template</Button>
    </Space>

    </Card>
</Space>

if (screens.xs) {
    return (
        <Space direction="vertical" style={{width: '100%', padding: 20}}>
            <SignatureModal open={signing} setOpen={setSigning} user={user} setRefresh={setRefresh} />
            <TemplatesCards />
            <SignatureCard />
            <Initials />
        </Space>
    );
}

    return (
       <Space direction="vertical" style={{width: '100%', padding: 20}}>
        <SignatureModal open={signing} setOpen={setSigning} user={user} setRefresh={setRefresh} />
        <TemplatesTable />
        <Space direction="horizontal" style={{width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            <SignatureCard />
        </Space>
        </Space>
    );
}

export default RadClinician;