import React, { useEffect, useState } from 'react';
import { Space, Row, Col, Skeleton, Button, Table, Card, Typography, Grid, Result, Divider } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import PatientModal from './components/PatientModal';
import Parse from 'parse';
import moment from 'moment';
const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const PatientProfile = () => {
    const [patient, setPatient] = useState(null);
    const [studies, setStudies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const { id } = useParams();
    const screens = useBreakpoint();
    const navigate = useNavigate();

    const openStudies = () => {
        let studiesIds = studies?.map((study) => study.status === 'Recibiendo imágenes' || study.status === 'Imágenes recibidas'  || study.status === 'Entregado' ? study.instanceUUID : undefined);
        studiesIds = studiesIds.filter((item) => item !== undefined);
        studiesIds = studiesIds.join(',');
        console.log('Studies IDs: ', studiesIds);
        window.open(`${process.env.REACT_APP_VIEWER_URL}/viewer?StudyInstanceUIDs=${studiesIds}`, '_blank');
      }
      
      const imagesAvailable = () => {
        const status = studies?.some((study) => study.status === 'Recibiendo imágenes' || study.status === 'Imágenes recibidas' || study.status === 'Entregado');
        console.log('Status: ', status);
        return status;
      }

    useEffect(() => {
        const fetchPatient = async () => {
            const query = new Parse.Query('Patients');
            query.equalTo('uuid', id);
            const patient = await query.first();
            if (!patient) return;
            setPatient(patient.toJSON());
            fetchStudies(patient);
            setLoading(false);
        }

        const fetchStudies = async (patient) => {
            console.log(patient);
            const query = new Parse.Query('Studies');
            query.equalTo('patient', {
                __type: 'Pointer',
                className: 'Patients',
                objectId: patient.id
            });
            query.include(['modality', 'study', 'study.modality'])
            query.containedIn('status', ['Recibiendo imágenes', 'Imágenes recibidas', 'Entregado'])
            const results = await query.find();
            if (!results) return;
            const studies = results.map(study => study.toJSON());
            setStudies(studies);
        }

        fetchPatient();
    }, [id])

    const StudyList = () => {
        return studies.map(study => (
            <Card title={study.description} style={{ width: '50vw' }}>
                <Space direction="vertical">
                    <Text>Fecha de estudio: {moment(study.createdAt).format('DD/MM/YYYY')}</Text>
                    <Text>Modalidad: {study.modality.commonName}</Text>
                    <Button type="link" onClick={() =>  navigate(`/study/${study.internalUUID}`, { state: { fromMenu: true }})}>Ver estudio</Button>
                </Space>
            </Card>
        ))
    }

    const columns = [
        {
            title: 'Estudio',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Fecha de estudio',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('DD/MM/YYYY')
        },
        {
            title: 'Modalidad',
            dataIndex: 'modality',
            key: 'modality',
            render: (modality) => modality?.commonName
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (text, record) => (
                <Space direction="horizontal">
                    <Button type="link" onClick={() => navigate(`/study/${record.internalUUID}`, { state: { fromMenu: true } })}>Ver estudio</Button>
                </Space>
            )
        }
    ]


    if (loading) return <Space direction="vertical">
        <Skeleton active style={{ width: '50vw' }} />
        <Skeleton active style={{ width: '50vw' }} />
        <Skeleton active style={{ width: '50vw' }} />
    </Space>

    if (!patient) return <Result status="404" title="Paciente no encontrado" subTitle="El paciente que buscas no existe" />
    
    return (
        <Space direction="vertical" style={{ width: '100%', padding: 20 }}>
        <PatientModal patient={patient} setPatient={setPatient} open={openModal} setOpen={setOpenModal} />
        <Space direction="vertical">
            <Card  style={{ width: '100%' }}>
                <Title level={2}>{patient?.fullName}</Title>
                <Text style={{ fontSize: 16 }}>Paciente</Text>
                <Divider style={{ width: '100%' }} />
                    <Space direction="horizontal" style={{ width: '100%' }}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Text>Fecha de nacimiento: {moment(patient.birthday.iso).format('DD/MM/YYYY')}</Text>
                            </Col>
                            <Col span={12}>
                                <Text>Género: {patient.gender}</Text>
                            </Col>
                            <Col span={12}>
                                <Text>Teléfono: {patient.phoneNumber}</Text>
                            </Col>
                            <Col span={12}>
                                <Text>Correo electrónico: {patient.email}</Text>
                            </Col>
                        </Row>
                        
                    </Space>
                </Card>
            {imagesAvailable() && <Button type="primary" onClick={openStudies}>Ver imágenes</Button>}
            <Title level={4}>Estudios</Title>
            <Space direction="horizontal">
                {screens.xs ? <StudyList /> : <Table dataSource={studies} columns={columns} style={{ width: '80vw' }} />}
            </Space>
        </Space>
        <Button type="primary" onClick={() => {
            setPatient({...patient, editing: true})
            setOpenModal(true)
         }} style={{ width: '10vw', position: 'fixed', bottom: 20, right: 20 }}>Editar</Button>
        </Space>
    )
}

export default PatientProfile;