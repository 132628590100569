import { Table, Grid, Space, Card } from 'antd';
const { useBreakpoint } = Grid;

const ProceduresTable = ({ data }) => {
    const screens = useBreakpoint();
    const columns = [
        {
            title: 'Fecha del estudio',
            dataIndex: 'date',
            key: 'date',
            render: (date) => new Date(date).toLocaleDateString(),
        },
        {
            title: 'Modalidad',
            dataIndex: 'modality',
            key: 'modality',
            render: (modality) => modality?.commonName,
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Referidor',
            dataIndex: 'referrer',
            key: 'referrer',
            render: (referrer) => referrer?.fullName || 'Sin asignar',
        },
        {
            title: 'Radiólogo',
            dataIndex: 'radiologist',
            key: 'radiologist',
            render: (radiologist) => radiologist?.fullName || 'Sin asignar',
        },
        {
            title: 'Intérprete',
            dataIndex: 'interpreter',
            key: 'interpreter',
            render: (interpreter) => interpreter?.fullName || 'Sin asignar',
        }
    ];

    if (screens.xs) {
        return (
            <Space direction='horizontal' style={{ padding: 20, width: '70vw', overflowX: 'scroll', paddingBottom: 20 }}>
                {data.map((study) => (
                    <Card key={study.objectId} title={study.description} style={{ width: '100%' }}>
                        <Space direction='vertical'>
                            <p>{new Date(study.createdAt).toLocaleDateString()}</p>
                            <p>Referidor: {study.referrer?.fullName || 'Sin asignar'}</p>
                            <p>Radiólogo: {study.radiologist?.fullName || 'Sin asignar'}</p>
                            <p>Intérprete: {study.interpreter?.fullName || 'Sin asignar'}</p>
                        </Space>
                    </Card>
                ))}
            </Space>
        )
    }

    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 8 }}
        />
    );
}

export { ProceduresTable }