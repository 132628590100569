import moment from "moment";
import Parse from "parse";
import { v4 as uuidv4 } from 'uuid';

const fetchPatient = async (objectId) => {
    return new Promise(async (resolve, reject) => {
        let query = new Parse.Query('Patients');
        query.get(objectId).then((result) => {
            console.log('Patient fetched', result.toJSON());
            resolve(result.toJSON());
        }
        , (error) => {
            console.error('Error while fetching Patient: ', error);
            reject(error);
        });
    });
}

const fetchIndex = async (type) => {
    console.log('Fetching index', type);
    return new Promise(async (resolve, reject) => {
        let query = new Parse.Query('Index');
        query.equalTo('name', type);
        query.first().then((result) => {
            console.log('Index fetched', result.toJSON());
            resolve(result.toJSON());
        }
        , (error) => {
            console.error('Error while fetching Index: ', error);
            reject(error);
        });
    });
}

const incrementIndex = (obectId, increment = 1) => {
        const Index = Parse.Object.extend('Index');
        const newIndex = new Index();
        newIndex.set('objectId', obectId);
        newIndex.increment('value', increment);
        return newIndex
}

const saveOrder = async (data) => {
    console.log('Saving order', data);

    return new Promise(async (resolve, reject) => {
        const toSave = []

        console.log(data);
        const Orders = Parse.Object.extend('Orders');
        const order = new Orders();

        const patientPointer = {
            __type: 'Pointer',
            className: 'Patients',
            objectId: data.patient.objectId
        }

        const clinicianPointer = {
            __type: 'Pointer',
            className: '_User',
            objectId: data.clinician.objectId
        }

        const clientPointer = {
            __type: 'Pointer',
            className: 'Clients',
            objectId: data?.client?.objectId
        }

        const interpreterPointer = {
            __type: 'Pointer',
            className: '_User',
            objectId: data?.interpreter?.objectId
        }
        
        const patient = await fetchPatient(data.patient.objectId);
        const orderIndex = await fetchIndex('order');

        order.set('orderID', 'A'+String(orderIndex.value).padStart(6, '0')+'-'+moment().format('YY'));
        order.set('patient', patientPointer);
        order.set('clinician', clinicianPointer);
        order.set('createdBy', Parse.User.current());
        order.set('status', 'Registrada');
        order.set('internalUUID', uuidv4());
        order.set('date', moment().toDate());
        order.set('notes', data.notes);
        order.set('accessionNumber', data.accessionNumber);
        order.set('specialScheduled', data.specialScheduled ? true : false);
        data?.client && order.set('client', clientPointer);
        data?.interpreter && order.set('interpreter', interpreterPointer);
        const orderResult = await order.save();
        toSave.push(incrementIndex(orderIndex.objectId));

        const orderPointer = {
            __type: 'Pointer',
            className: 'Orders',
            objectId: orderResult.id
        }

        console.log('Saving', data.studies);

        const Studies = Parse.Object.extend('Studies');
        const studyIndex = await fetchIndex('study');
        let actualIndex = studyIndex.value;
        data.studies.forEach((study) => {
            const studyPointer = {
                __type: 'Pointer',
                className: 'StudyDescriptions',
                objectId: study.objectId
            }
            const modalityPointer = {
                __type: 'Pointer',
                className: 'Modalities',
                objectId: study.modality.objectId
            }
            const newStudy = new Studies();
            
            actualIndex += 1;
            newStudy.set('studyID', 'E'+String(actualIndex).padStart(6, '0')+'-'+moment().format('YY'));
            newStudy.set('accessionNumber', 'E'+String(actualIndex).padStart(6, '0')+'-'+moment().format('YY'));
            newStudy.set('order', orderPointer);
            newStudy.set('study', studyPointer);
            newStudy.set('description', study.study);
            newStudy.set('protocols', study.protocols.filter(protocol => protocol.selected).map(protocol => protocol.name));
            newStudy.set('status', 'Pendiente');
            newStudy.set('patient', patientPointer);
            newStudy.set('referrer', clinicianPointer);
            newStudy.set('patientName', patient.lastName + '^' + patient.firstName);
            newStudy.set('modality', modalityPointer);
            newStudy.set('internalUUID', uuidv4());
            newStudy.set('fetched', false);
            newStudy.set('notes', data.notes);
            newStudy.set('worklistCreated', false);
            newStudy.set('date', moment().toDate());
            data?.client && newStudy.set('client', clientPointer);
            data?.interpreter && newStudy.set('interpreter', interpreterPointer);
            newStudy.set('createdBy', Parse.User.current());
            // newStudy.set('accessionNumber', data.accessionNumber);
            newStudy.set('specialScheduled', data.specialScheduled ? true : false);
            toSave.push(newStudy);
            toSave.push(incrementIndex(studyIndex.objectId));

        });
        
        Parse.Object.saveAll(toSave).then((results) => {
            const Events = Parse.Object.extend('Events');
            results.forEach((result) => {
                // If the result is not from the Studies class, skip it
                if (result.className !== 'Studies') {
                    return;
                }
                const event = new Events();
                event.set('study', {
                    __type: 'Pointer',
                    className: 'Studies',
                    objectId: result.id
                });
                event.set('type', 'Study created');
                event.set('user', Parse.User.current());
                toSave.push(event);
            });
            Parse.Object.saveAll(toSave).then((results) => {
                console.log('Order saved', results);
                resolve(results);
            });
        }
        , (error) => {
            console.error('Error while creating Order: ', error);
            reject(error);
        });
    });
}

export { saveOrder }