import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, Space, Radio, Select, message } from 'antd';
import moment from 'moment';
import { BirthdateField, validateDate } from './BirthdateField';
import { savePatient } from '../utils/query';
import { UserAddOutlined } from '@ant-design/icons';
import { set } from 'immutable';

const PatientModal = ({patient, setPatient, open, setOpen}) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        form.resetFields();
        if (patient?.editing) {
            form.setFieldsValue({
                firstName: patient.firstName,
                lastName: patient.lastName,
                gender: patient.gender,
                birthday: moment(patient.birthday?.iso).format('DD/MM/YYYY'),
                phoneNumber: patient.phoneNumber,
                email: patient.email,
            });
        }
    }, [patient]);

    const onFinish = async (values) => {
        form.validateFields().then(async () => {      
            try{
                const patientInfo = {
                    ...patient,
                    ...values,
                }
                setSubmitting(true);
                const result = await savePatient(patientInfo);
                message.success('Paciente guardado correctamente');
                setPatient(result);
                setOpen(false);
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                message.error('Error al guardar el paciente');
            }
        }).catch(() => {
            message.error('Por favor, complete los campos requeridos');
        })
    }

    const saveButton = (
        <Button 
            type="primary" 
            style={{ width: '100%' }} 
            onClick={() => form.submit()}
            disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
            loading={submitting}
            >
                {patient?.editing ? 'Guardar paciente' : 'Crear paciente'}
            </Button>
    );

 
    return (
        <Modal
        title={patient?.editing ? 'Editar paciente' : 'Crear paciente'}
        open={open}
        footer={saveButton}
        onCancel={() => setOpen(false)}
        >
        <Form
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            form={form}
            onFinish={onFinish}
        >
            <Form.Item label="Nombre" required name="firstName" rules={[{ required: true, message: 'Por favor, ingrese el nombre' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Apellido" required name="lastName" rules={[{ required: true, message: 'Por favor, ingrese el apellido' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Fecha de nacimiento"  required name="birthday" rules={[{ validator: validateDate }]}>
            <BirthdateField />
            </Form.Item>
            <Form.Item label="Sexo" required name={'gender'} rules={[{ required: true, message: 'Por favor, seleccione el sexo' }]}>
            <Radio.Group>
                <Radio value={'Masculino'}>Masculino</Radio>
                <Radio value={'Femenino'}>Femenino</Radio>
            </Radio.Group>
            </Form.Item>
            <Form.Item label="Teléfono" key="phoneNumber" name="phoneNumber"
            rules={[{ required: false, pattern: /^[0-9]{1,2}[0-9]{10}$/, message: 'Por favor, ingrese un número de teléfono válido, incluyendo el código de país' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Correo" key="email" name="email"
            rules={[{ required: false, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: 'Por favor, ingrese un correo válido' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
        </Form>
        </Modal>
    );
}

export default PatientModal;