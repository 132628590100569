import React, { useEffect, useState } from 'react';
import { Space, Card, Button, Typography, Divider, Tag, Row, Col, Table, Skeleton } from 'antd';
import moment from 'moment';
import Parse from 'parse';
const { useNavigate } = require('react-router-dom');

const { Title, Text } = Typography;
const DeliveryInfo = ({study, noTitle}) => {
    const [printings, setPrintings] = useState([]); 
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPrintings = async () => {
          const params = { studyId: study?.objectId };
            const printings = await Parse.Cloud.run('getPrintings', params);
            setPrintings(printings);
            setLoading(false);
            console.log('Fetch printings')
        }

        fetchPrintings();
    }, [study])

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [])

    const parseType = (type) => {
        let message = ''
        switch(type) {
            case 'Digital interpretation':
                message = 'Interpretación digital'
                break;
            case 'Physical images':
                message = 'Imágenes físicas'
                break;
            default:
                message = 'Evento desconocido'
        }
        return message
    }

    const columns = [
        {
            title: 'Usuario',
            dataIndex: 'user',
            key: 'user',
            render: (user) => user ? user.fullName : ''
        }, 
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('LL [a las] HH:mm')
        },
        {
            title: 'Información',
            dataIndex: 'media',
            key: 'media',
            render: (media) => media.map(m => (
                <Tag key={m.media} color='blue'>{m.copies + ' x ' + m.media}</Tag>
            ))
        }
    ]

    if (noTitle) return (
        <Space direction='horizontal' style={{padding: 20, width: '60vw'}} >
                {!loading ? printings.length > 0 ? (
                    <Row style={{width: '60vw'}}>
                       <Table dataSource={printings} columns={columns} style={{width: '60vw'}} />
                </Row>
                ) : (
                    <Title level={3}>Este estudio no ha sido impreso</Title>
                ) : <Skeleton active style={{ width: '60vw' }} />}
        </Space>
    )


    return (
        <Space direction='horizontal' style={{padding: 20, width: '100%'}} >
            <Card title="Impresiones" style={{ width: '100%' }} loading={loading}>
                {!loading ? printings.length > 0 ? (
                    <Row>
                       <Table dataSource={printings} columns={columns} />
                </Row>
                ) : (
                    <Title level={3}>Este estudio no ha sido impreso</Title>
                ) : <Skeleton active  style={{ width: '50vw' }} />}
                
            </Card>
        </Space>
    )
}

export default DeliveryInfo
