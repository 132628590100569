import { createBrowserRouter } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import Login from './sites/Login/Login';
import Study from './sites/Study/Study';
import Studies from './sites/Studies/Studies';
import Order from './sites/Order/Order';
import Orders from './sites/Orders/Orders';
import Patients from './sites/Patients/Patients';
import PatientProfile from './sites/Patient/PatientProfile';
import Users from './sites/Users/Users';
import ModalitySetings from './sites/Settings/Modalities/ModalitySettings';
import ServerStatus from './sites/Settings/ServerStatus/ServerStatus';
import StudySettings from './sites/Settings/Studies/Studies';
import Profile from './sites/Profile/Profile';
import {CustomComponent} from './components/Interface/Interface';
import Template from './sites/Profile/components/Template';
import Analysis from './sites/Analysis/Studies';
import Timing from './sites/Analysis/Timing';
import Access from './sites/Analysis/Access';
import Billing from './sites/Billing/Billing';
import Printings from './sites/Analysis/Printings';
import PaymentMethods from './sites/Settings/PaymentMethods/PaymentMethods';
import { CarOutlined, TeamOutlined, DatabaseOutlined, DollarOutlined, DotChartOutlined } from '@ant-design/icons';
import Interpretation from './sites/Interpretation/Interpretation'
import { Camera, CameraVideo, CameraVideoFill, Clipboard2DataFill, Clock, FileEarmarkMedical, GearFill, GraphUpArrow, HddStack, ListColumns, ListTask, PersonArmsUp, PersonBadge, PersonCircle, Printer, PrinterFill, SuitcaseLg,  } from 'react-bootstrap-icons';
import { LocationContext } from './context';
import { UserContext } from './context';
import Redirect from './Redirect';
import Tools from './sites/Tools/Tools';
import GeneralSettings from './sites/Settings/GeneralSettings/GeneralSettings';
import Clients from './sites/Clients/Clients';

const outerRoutes = [
    {
        path: "/login",
        element: <Login/>
    },
    
   {
    path: "/*",
    element: <CustomComponent />,
   }
];

if (false) {
    outerRoutes.push({
        path: "/order/:id",
        element: <Order />,
        key: "/order",
    },
    {
        path: '/study/:id',
        element: <Study />,
        key: '/study'
    },
    {
        path: '/interpretation/:id',
        element: <Interpretation />,
        key: '/interpretation'
    })
}


const outerRouter = outerRoutes

const innerRouter = [
    {
        path: "/orders",
        element: <Orders/>,
        label: "Órdenes",
        icon: <ListTask />,
        key: "/orders",
        roles: ['BOFH', 'Radiologist', 'Administrative', 'Auditor', 'System administrator']
    },
    {
        path: "/order/:id",
        element: <Order />,
        key: "/order",
        roles: ['BOFH', 'Radiologist', 'Administrative', 'Auditor', 'System administrator']
    },
    {
        path: "/studies",
        element: <Studies/>,
        label: "Estudios",
        icon: <FileEarmarkMedical />,
        key: "/studies",
        roles: ['BOFH', 'Radiologist', 'Administrative', 'Radiology physician', 'Specialist physician', 'Employed physician', 'Auditor', 'System administrator', 'Insurance']
    },
    {
        path: '/study/:id',
        element: <Study />,
        key: '/study',
        roles: ['BOFH', 'Radiologist', 'Administrative', 'Radiology physician', 'Specialist physician', 'Employed physician', 'Auditor', 'System administrator', 'Insurance']
    },
    {
        path: '/interpretation/:id',
        element: <Interpretation />,
        key: '/interpretation',
        roles: ['BOFH', 'Radiologist', 'Administrative', 'Radiology physician', 'Specialist physician', 'Employed physician', 'Auditor', 'System administrator', 'Insurance']
    },
    {
        path: '/profile/template',
        element: <Template />,
        key: '/profile/template',
        roles: ['BOFH', 'Radiology physician', 'Auditor', 'System administrator']
    },
    {
        path: '/patients',
        element: <Patients />,
        key: '/patients',
        label: 'Pacientes',
        icon: <PersonArmsUp />,
        roles: ['BOFH', 'Radiologist', 'Administrative', 'Employed physician', 'Auditor', 'System administrator', 'Insurance']
    },
    {
        path: '/patient/:id',
        element: <PatientProfile />,
        key: '/patient',
        roles: ['BOFH', 'Radiologist', 'Administrative', 'Radiology physician', 'Specialist physician', 'Employed physician', 'Auditor', 'System administrator', 'Insurance']
    },
    {
        path: '/users',
        element: <Users />,
        key: '/users',
        label: 'Usuarios',
        icon: <PersonBadge />,
        roles: ['BOFH', 'Administrative', 'Auditor', 'RRHH', 'System administrator']
    },
    {
        path: '/redirect',
        key: '/redirect',
        element: <Redirect />,
        roles: ['BOFH', 'Radiologist', 'Administrative', 'Radiology physician', 'Specialist physician', 'Employed physician', 'Auditor', 'System administrator']
    },
    {
        path: 'clients',
        element: <Clients />,
        key: '/clients',
        label: 'Clientes',
        icon: <SuitcaseLg />,
        roles: ['BOFH', 'Administrative', 'Auditor', 'System administrator', 'Insurance']
    },
    {
        key: '/analysis',
        label: 'Analisis',
        icon: <GraphUpArrow />,
        roles: ['BOFH', 'Auditor', 'System administrator'],
        children: [
            {
                path: '/analysis/studies',
                element: <Analysis />,
                key: '/analysis/studies',
                label: 'Estudios',
                icon: <FileEarmarkMedical />,
                roles: ['BOFH', 'Auditor', 'System administrator']
            },
            {
                path: '/analysis/timing',
                element: <Timing />,
                key: '/analysis/timing',
                label: 'Tiempos',
                icon: <Clock />,
                roles: ['BOFH', 'Auditor', 'System administrator']
            },
            {
                path: '/analysis/access',
                element: <Access />,
                key: '/analysis/access',
                label: 'Accesos',
                icon: <CameraVideo />,
                roles: ['BOFH', 'Auditor', 'System administrator']
            },
            {
                path: '/analysis/printings',
                element: <Printings />,
                key: '/analysis/printings',
                label: 'Impresiones',
                icon: <Printer />,
                roles: ['BOFH', 'Auditor', 'System administrator']
            }
        ]
    },
    {
        key: '/settings',
        label: 'Ajustes',
        icon: <GearFill />,
        roles: ['BOFH', 'Administrative', 'Radiologist', 'Auditor', 'System administrator'],
        children: [
            {
                path: '/settings/study',
                element: <StudySettings />,
                key: '/settings/study',
                label: 'Estudios',
                icon: <FileEarmarkMedical />,
                roles: ['BOFH', 'Radiologist', 'Auditor', 'System administrator']
            },
            {
                path: '/settings/server',
                element: <ServerStatus />,
                key: '/settings/server',
                label: 'Status del servidor',
                icon: <HddStack />,
                roles: ['BOFH', 'Administrative', 'Radiologist', 'Auditor', 'System administrator']
            },
            // {
            //     path: '/settings/personalization',
            //     element: <GeneralSettings />,
            //     key: '/settings/personalization',
            //     label: 'Personalización',
            //     icon: <GearFill />,
            //     roles: ['BOFH', 'System administrator']
            // },
            // {
            //     path: '/settings/payment-methods',
            //     element: <PaymentMethods />,
            //     key: '/settings/payment-methods',
            //     label: 'Métodos de pago',
            //     icon: <DollarOutlined />,
            //     roles: ['BOFH', 'SystemAdministrator']
            // }
        ]
    },
    {
        path: '/profile',
        element: <Profile />,
        key: '/profile',
        label: 'Perfil',
        icon: <PersonCircle />,
        roles: ['BOFH', 'Radiologist', 'Radiology physician']
    },
    {
        key: '/tools',
        path: '/tools',
        label: 'Herramientas',
        icon: <ListColumns />,
        roles: ['BOFH'],
        element: <Tools />,
    }
   
];

process.env.REACT_APP_MULTICLINIC && innerRouter.push( {
    key: '/billing',
    path: '/billing',
    label: 'Facturación',
    icon: <DollarOutlined />,
    roles: ['BOFH', 'Administrative', 'Auditor', 'System administrator'],
    element: <Billing />,
})

export { outerRouter, innerRouter }

