import React, { useState, useEffect } from "react";
import { Layout, Menu, Button, Space, Modal, Input, message } from "antd";
import { UserOutlined, LaptopOutlined, NotificationOutlined } from "@ant-design/icons";
import SignatureCanvas from 'react-signature-canvas'
import Parse from "parse";
const { TextArea } = Input;

const SignatureModal = ({open, setOpen, user, setRefresh}) => {
    const [signature, setSignature] = useState({footer: '', url: ''});
    const sigCanvas = React.createRef();

    const clear = () => {
        sigCanvas.current.clear();
    }

    useEffect(() => {
        console.log('Previous signature', user)
        if (user) {
            setSignature(user.signature);
        }
    }, [user])


    const save = () => {
        console.log('Saving signature', sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'), signature.footer);
        // return;
        const signatureUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        const user = Parse.User.current();
        const footer = signature?.footer.replace(/\n/g, '<br>');
        user.set('signature', {url: signatureUrl, footer });
        user.save().then(() => {
            message.success('Firma guardada');
            setOpen(false);
            setRefresh((prev) => !prev);
        }).catch((error) => {
            message.error('Error al guardar la firma');
        })
    }

    return (
        <Modal
            open={open}
            title="Firmar"
            onCancel={() => setOpen(false)}
            footer={[
                <Space>
                    <Button onClick={clear}>Limpiar</Button>
                    <Button onClick={save}>Guardar</Button>
                </Space>
            ]}
        >
            <SignatureCanvas penColor='black' ref={sigCanvas} canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
            <TextArea 
                value={signature?.footer?.replace(/<br>/g, '\n')} 
                onChange={(e) => setSignature({...signature, footer: e.target.value})} 
                placeholder="Pie de firma" 
            />
        </Modal>
    );
}

export default SignatureModal;