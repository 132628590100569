import moment from 'moment';
import Parse from 'parse';
import { v4 as uuidv4 } from 'uuid';
const queryProps = ['fullName', 'email', 'phoneNumber'];
const  constructAccentedRegexPattern = (text)  => {
    // Map of characters to their possible accented versions
    const accentMap = {
      'a': '[aá]',
      'e': '[eé]',
      'i': '[ií]',
      'o': '[oó]',
      'u': '[uú]',
      'A': '[AÁ]',
      'E': '[EÉ]',
      'I': '[IÍ]',
      'O': '[OÓ]',
      'U': '[UÚ]'
    };
  
    // Replace each character in the text with its regex pattern
    return text.split('').map(char => accentMap[char] || char).join('');
  }



const query = async (value) => {
   // Count the number of studies for each client
    return new Promise(async (resolve, reject) => {
        try {
            const Clients = Parse.Object.extend('Clients');
            const query = new Parse.Query(Clients);
            if (value) {
                const search = constructAccentedRegexPattern(value);
                query.matches('name', new RegExp(search, 'i'));
            }
            const clients = await query.find();
            resolve(clients.map(client => client.toJSON()));
        }
        catch (error) {
            console.error('Error while fetching clients: ', error);
            reject(error);
        }
    });
}

const saveClient = async (client) => {
    return new Promise(async (resolve, reject) => {
        const query = new Parse.Query('Index');
        query.equalTo('name', 'client');
        const index = await query.first();
        index.increment('value');
        console.log(client);
        try {
            const Clients = Parse.Object.extend('Clients');
            const newClient = new Clients();
            client.objectId && newClient.set('objectId', client.objectId);
            newClient.set('name', client.name);
            newClient.set('contactName', client.contactName);
            newClient.set('email', client.email?.trim()?.toLowerCase());
            newClient.set('phoneNumber', client.phoneNumber);
            const result = await newClient.save();
            await index.save();
            resolve(result);
        }
        catch (error) {
            console.error('Error while saving client: ', error);
            reject(error);
        }
    }
    );
}
        

  export { query, saveClient };