import Parse from 'parse';

Parse.initialize(process.env.REACT_APP_APP_ID, process.env.REACT_APP_JS_API_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_HOST_URL;

const login = async (username, password) => {
    const user = await Parse.User.logIn(username, password);
    console.log(user);
}

export { login };