import React, { useState, useEffect, useContext } from 'react';
import {Card, Button, Space, Typography, Divider, Popover, message, Modal, Tabs, Select, Input, Checkbox, Form, Row, Col} from 'antd';

const OrderNotes = ({order, user}) => {

   if (!order) return null;
   if (user?.get('role') === 'BOFH' || 
   user?.get('role') === 'Administrative' ||
    user?.get('role') === 'Radiologist' ||
    user?.get('role') === 'Auditor' ||
    user?.get('role') === 'System administrator' ||
    user?.get('role') === 'Radiology physician' ||
    user?.get('role') === 'Employed physician') 
    return (
        <Card title="Observaciones" style={{ width: '100%', overflowY: 'scroll', maxHeight: 300, padding: 20 }}>
            {order?.notes}
        </Card>
    )
}

export default OrderNotes;