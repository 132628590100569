import React, { useState, useEffect } from "react";
import { Layout, Input, Button, Space, Modal, List } from "antd";
import { UserOutlined, LaptopOutlined, NotificationOutlined } from "@ant-design/icons";
import Parse from "parse";

const ModalLoadTemplate = ({open, setOpen,  setContent}) => {
    const [templates, setTemplates] = useState([{}]);
    const [filteredTemplates, setFilteredTemplates] = useState([{}]);
    const [filterValue, setFilterValue] = useState('');

    useEffect(() => {
        const fetchTemplates = async () => {
            const user = Parse.User.current();
            const query = new Parse.Query('Templates');
            console.log(user);
            query.equalTo('user', user);
            const results = await query.find();
            const templates = results.map(template => template.toJSON());
            setTemplates(templates);
            setFilteredTemplates(templates);
            setFilterValue('');
        }

        fetchTemplates();
    }, [open])

    return (
       <Modal
        title="Seleccionar template"
        open={open}
        onCancel={() => setOpen(false)}
        style={{width: '80vw', height: '80vh'}}
        >
            <Space direction="vertical" style={{width: '100%', height: '100%'}}>
                <Input placeholder="Buscar template" 
                    value={filterValue}
                    onChange={(e) => {
                    const query = e.target.value;
                    const filtered = templates.filter(template => template.name.toLowerCase().includes(query.toLowerCase()));
                    setFilteredTemplates(filtered);
                    setFilterValue(query);
                }} />
                <List 
                    pagination={{pageSize: 5}}
                    dataSource={filteredTemplates} 
                    renderItem={item => (
                        <List.Item>
                                <Space direction="horizontal" style={{ justifyContent: 'space-between', flex: 1, width: '100%', flexDirection: 'row' }}>
                                    <h3>{item.name}</h3>
                                    <Button onClick={() => {
                                        setContent(item.content)
                                        setOpen(false);
                                    }}>Seleccionar</Button>
                                </Space>
                        </List.Item>
                    )}
                />
            </Space>
        </Modal>
    );
}

export default ModalLoadTemplate;