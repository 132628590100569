import React, { useEffect, useState, useContext } from 'react';
import { Space, Button, Divider, Typography, Grid, Popover, message, Modal, Tabs, Select } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { UserContext } from '../../../context';
import Barcode from 'react-barcode';
const { Title, Text } = Typography;

const removeAccents = (str) => {
    if (!str) return '';
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const xray = [
    {label: 'Nombres', value: 'firstName'},
    {label: 'Espacio', value: 'empty'},
    {label: 'Apellidos', value: 'lastName'}, 
    {label: 'Espacio', value: 'empty'},
    {label: 'ID de paciente', value: 'patientId'},
    {label: 'Género', value: 'commonGender'},
    {label: 'Fecha de nacimiento', value: 'birthday dd/mm/aaaa'},
    {label: 'Espacio', value: 'empty'},
    {label: 'Descripción del estudio', value: 'studyDescription'},
    {label: 'Número de acceso', value: 'accessionNumber'},
    {label: 'Operador', value: 'operator'},
    {label: 'Intérprete', value: 'interpreter'},
    {label: 'Médico referidor', value: 'referrer'},
];

const ct = [
    {label: 'Número de acceso', value: 'accessionNumber'},
    {label: 'ID de paciente', value: 'patientId'},
    {label: 'Nombre completo', value: 'dicomFullName'},
    {label: 'Género', value: 'oneLetterGender'},
    {label: 'Fecha de nacimiento', value: 'birthday dd/mm/aaaa'},
    {label: 'Espacio', value: 'empty'},
    {label: 'Médico referidor', value: 'referrer'},
    {label: 'Intérprete', value: 'interpreter'},
    {label: 'Operador', value: 'operator'},
    {label: 'Espacio', value: 'empty'},
    {label: 'Descripción del estudio', value: 'studyDescription'},
]

const options = ['xray', 'ct'];


const DataSheets = ({studies, open, setOpen}) => {
    const [selectedStudy, setSelectedStudy] = useState(null);
    const navigate = useNavigate();
    const [user] = useContext(UserContext);
    const [render, setRender] = useState('xray');


    const renderField = (study, field) => {
        console.log('Fieldddd', study);
        switch (field) {
            case 'firstName':
                return study.patient?.firstName ? removeAccents(study.patient?.firstName) + '\t' : '\t';
            case 'lastName':
                return study.patient?.lastName ? removeAccents(study.patient?.lastName) + '\t' : '\t';
            case 'dicomFullName':
                return study.patient?.fullName ? removeAccents(study.patient?.lastName) + '^' + removeAccents(study.patient?.firstName) + '\t' : '\t';
            case 'patientId':
                return study.patient?.patientID ? study.patient?.patientID + '\t' : '\t';
            case 'commonGender':
                if (study?.patient?.gender === 'Femenino') {
                    return 'Mujer\t';
                } else if (study?.patient?.gender === 'Masculino') {
                    return 'Hombre\t';
                } else {
                    return 'Otro\t';
                }
            case 'oneLetterGender':
                if (study?.patient?.gender === 'Femenino') {
                    return 'F\t';
                } else if (study?.patient?.gender === 'Masculino') {
                    return 'M\t';
                }
                return 'O\t';
            case 'birthday dd/mm/aaaa':
                return moment(study.patient?.birthday).format('DD/MM/YYYY') + '\t';
            case 'studyDescription':
                return removeAccents(study.description) + '\t';
            case 'accessionNumber':
                return study.accessionNumber ? study.accessionNumber + '\t' : '\t';
            case 'referrer':
                return study.referrer?.fullName ? removeAccents(study.referrer?.fullName) + '\t' : '\t';
            case 'operator':
                return user?.toJSON().initials ? user.toJSON().initials : '\t';
            case 'interpreter':
                return study.interpreter?.fullName ? removeAccents(study.interpreter?.fullName) + '\t' : '\t';
            default:
                return '\t';
        }
    }

    if (!studies) {
        return null;
    }

    return (
        <Space direction='vertical' style={{ padding: 20, width: '90vw' }}>
        <Modal title="Estudios" open={open} onCancel={() => setOpen(false)} footer={<Button onClick={() => setOpen(false)}>Cerrar</Button>} style={{ top: 20, width: '90vw' }}>
        <Select defaultValue='xray' onChange={(value) => setRender(value)} style={{ width: 120 }} options={options.map((option) => ({label: option.toUpperCase(), value: option}))} value={render} />
        <Space direction='vertical' style={{ width: '100%' }}>
            <Tabs defaultActiveKey="0">
                {studies.map((study, index) => (
                    <Tabs.TabPane tab={study.description} key={index} disabled={study.status === 'Cancelado'}>
                        {study.status === 'Cancelado' ? <Text type='danger'>Este estudio ha sido cancelado</Text>:
                        <Space direction='vertical' style={{ width: '100%', height: '70vh', overflowY: 'auto', overflowX: 'hidden', padding: 10, justifyContent: 'space-between', alignItems: 'center' }}>
                            {render === 'xray' && xray.map((field, index) => (
                                <Space direction='vertical' key={index} style={{ alignItems: 'center' }}>
                                    <Title key={index} level={5}>{field.label}</Title>
                                    <Text key={index} style={{ fontFamily: 'monospace' }}>{field.label === 'Espacio' ? <Barcode value={'\t'} width={.7} height={40} fontSize={10}/> : <Barcode value={renderField(study, field.value)} width={.7} height={40} fontSize={10} />}</Text>
                                </Space>
                            ))}
                            {render === 'ct' && ct.map((field, index) => (
                                <Space direction='vertical' key={index} style={{ alignItems: 'center' }}>
                                    <Title key={index} level={5}>{field.label}</Title>
                                    <Text key={index} style={{ fontFamily: 'monospace' }}>{field.label === 'Espacio' ? <Barcode value={'\t'} width={.7} height={40} fontSize={10}/> : <Barcode value={renderField(study, field.value)} width={.7} height={40} fontSize={10} />}</Text>
                                </Space>
                            ))}
                        </Space>}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </Space>
        </Modal>
    </Space>
    )
}

export default DataSheets;