import React, { useEffect } from 'react';
import { Modal, Form, Button, Space, Input, message, Checkbox } from 'antd';
import {SearchInput} from './SearchInput';
import {StudiesList} from './StudiesList';
import { saveOrder } from '../utils/save';
import PatientModal from '../../Patients/components/PatientModal';
import UserModal from '../../Users/components/UserModal';
import { User } from 'parse';

const NewOrderModal = ({ open, setOpen, isNew, Order, editing, setEditing, setOrder, queryOrders }) => {
    const [form] = Form.useForm();
    const formRef = React.useRef(null);
    const [patient, setPatient] = React.useState(undefined);
    const [clinician, setClinician] = React.useState(undefined);
    const [addUser, setAddUser] = React.useState(false);
    const [addPatient, setAddPatient] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);

    const onSubmit = async () => {
        const timeout = setTimeout(() => {
          setSubmitting(false);
          message.open({
            type: 'error',
            content: 'Error al guardar la orden',
          });
        }, 10000);
  
        setSubmitting(true);
        const toSave = {...form.getFieldsValue()};
        saveOrder(toSave).then((result) => {
          console.log(result);
          setOrder({});
          form.resetFields();
          setOpen(false);
          setEditing(false);
          queryOrders();
          setSubmitting(false);
          clearTimeout(timeout);
          message.open({
            type: 'success',
            content: 'Orden guardada exitosamente',
          });
        }, (error) => {
          console.error('Error while creating Order: ', error);
          clearTimeout(timeout);
          setSubmitting(false);
          message.open({
            type: 'error',
            content: 'Error al guardar la orden',
          });
        });
      }

    const SubmitButton = ({ form }) => {
        const [submittable, setSubmittable] = React.useState(false);
      
        // Watch all values
        const values = Form.useWatch([], form);
        React.useEffect(() => {
            setSubmittable(form.isFieldsTouched(true) && form.getFieldsError().filter(({ errors }) => errors.length).length === 0);
        }, [values]);
        return (
          <Button type="primary" htmlType="submit"  loading={submitting} style={ !submittable && { opacity: 0.7 }} >
            Guardar
          </Button>
        );
      };

      const validateStudies = (rule, studies, callback) => {
        console.log('Validating studies', studies);
        return new Promise((resolve, reject) => {
            if (!studies || studies.length === 0) {
                reject('Debe seleccionar al menos un estudio');
            }
            studies.forEach(study => {
                let selected = 0
                study.protocols.forEach(protocol => {
                    if (protocol.selected) {
                        selected += 1;
                    }
                    console.log('Protocol', protocol, selected);
                })
                if (selected === 0) {
                    console.log('Rejecting protocols');
                    reject('Debe seleccionar al menos un protocolo por estudio');
                }
            })
            resolve();
        })
    }

    console.log('Patietn', patient);
            
            

    return (
        <Modal
        title={!isNew ? Order.orderNumber : 'Nueva orden'}
        centered
        open={open}
        onCancel={() => {
          setOpen(false)
          setEditing(false)
        }}
        footer={null}
        initialValues={Order}
      >
        <PatientModal patient={addPatient} setPatient={setAddPatient} onSave={(patient) => {
          console.log('On save', patient);
          formRef.current.setFieldsValue({
            patient: {
              value: patient.fullName,
              ...patient
            }
        });
            setAddPatient(false);
            setPatient(patient);
          }} />

          <UserModal user={addUser} setUser={setAddUser} onAdd={(user) => {
            formRef.current.setFieldsValue({
              clinician: {
                value: user.fullName,
                ...user
              }
            });
            setAddUser(false);
            setClinician(user);
          }} />

           <Form
              form={form}
              ref={formRef}
              preserve={false}
              onFinish={onSubmit}
              name="control-ref"
              style={{
                maxWidth: 1000,
              }}
            >
              <Form.Item
                name="patient"
                label="Paciente"
                rules={[
                  {
                    required: true,
                    message: 'Este campo es requerido',
                  },
                ]}
              >
                <SearchInput placeholder="Buscar Paciente" role='Patients' form={form} name={'patient'} onAdd={() => setAddPatient(true)} />
              </Form.Item>
              <Form.Item
                name="clinician"
                label="Médico referidor"
                rules={[
                  {
                    required: true,
                    message: 'Este campo es requerido',
                  },
                ]}
              >
                <SearchInput placeholder="Buscar médico" role={['Employed physician', 'Specialist physician']} form={form} name={['clinician']} onAdd={() => setAddUser(true)} />
              </Form.Item>
              <Form.Item
                name="interpreter"
                label="Médico radiólogo"
                rules={[
                  {
                    required: false,
                    message: 'Este campo es requerido',
                  },
                ]}
              >
                <SearchInput placeholder="Buscar médico" role={['Radiology physician']} form={form} name={['interpreter']} />
              </Form.Item>
              <Form.Item
                name="client"
                label="Convenio"
                rules={[
                  {
                    required: false,
                    message: 'Este campo es requerido',
                  },
                ]}
              >
                <SearchInput placeholder="Buscar convenio" form={form} name={['client']} preventAdd={true} class="Clients" />
              </Form.Item>
              <Form.Item
                name="accesionNumber"
                label="Número de ingreso"
                >
                <Input />
                </Form.Item>
                <Form.Item
                  name="specialScheduled"
                  label="Estudio de guardia"
                >
                  <Checkbox />
                </Form.Item> 
                <Form.Item
                name="studies"
                rules={[{ validator: validateStudies }]}
                >
                    <StudiesList  form={form} />
                </Form.Item>
                <Form.Item
                name="notes"
                label="Notas"
                >
                <Input.TextArea />
                </Form.Item>
             {editing || isNew ? (
                <Form.Item>
                  <Space  style={{ float: 'right' }}>
                    <Button onClick={() => {
                      setOpen(false)
                      setEditing(false)
                    }}>
                    Cancelar
                    </Button>
                   <SubmitButton form={form} />
                  </Space>
                    </Form.Item>
                    ) : (
                      <Form.Item>
                        <Space  style={{ float: 'right' }}>
                          <Button type="link" onClick={() => setEditing(true)}>
                        Editar
                      </Button>
                      <Button type="primary" onClick={() => {
                        setEditing(false)
                        setOpen(false)
                      }}>
                          OK
                          </Button>
                          </Space>
                          </Form.Item>
                        )}
              
            </Form>

      </Modal>
    )
}

export default NewOrderModal;