import React, { useEffect, useState } from "react";
import Parse from "parse";
import { Layout, Menu, Breadcrumb, Space, Card, Input, message, Button } from "antd";
import { useNavigate } from "react-router-dom";
import SignatureModal from "./SignatureModal";

const RadTechnician = ({user}) => {
    const inputRef = React.createRef();
    const [initials, setInitials] = useState('')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitials(user?.initials);
    }, [user]);

    const save = async (initials) => {
        setLoading(true);
        const user = Parse.User.current();
        user.set('initials', initials);
        user.save().then(() => {
            message.success('Iniciales guardadas correctamente');
            setLoading(false);
        }).catch((error) => {
            console.error('Error while saving initials: ', error.code, error.message);
            if (error.message === 'Iniciales ya existen') {
                message.error('Las iniciales ya están en uso');
            } else {
                message.error('Ocurrió un error al guardar las iniciales');
            }
            setLoading(false);
        })  
    }

    return (
       <Space direction="vertical" style={{width: '100%', padding: 20}}>
            <Card title="Mis iniciales" style={{ width: 300 }}>
                <Space direction="horizontal">
                    <Input 
                        placeholder="Iniciales" 
                        value={initials}
                        onChange={(e) => setInitials(e.target.value)}
                        onPressEnter={(e) => save(e.target.value)} 
                        ref={inputRef} 
                        />
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => save(initials)}
                        >Guardar</Button>
                </Space>
            </Card>
        </Space>
    );
}

export default RadTechnician;