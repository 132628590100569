import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Space, Card, Typography, Statistic, Row, Col } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
const StorageStatus = () => {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                //REACT_APP_ORTHANC_URL
                let { data } = await axios.get(`${process.env.REACT_APP_ORTHANC_URL}/studies`, {timeout: 5000});
                console.log('Storage service status', data);
                setStatus(data);
            } catch (error) {
                console.log(error);
                setStatus({error: true})
            }
        }

        fetchStatus();
    }, []);
    

    return (
        <Space direction='vertical' style={{padding: 20, width: '100%', height: '100%'}} >
            <Card loading={!status} style={{ width: '100%' }}>
                <Statistic
                    title="Almacenamiento de estudios"
                    value={!status?.error ? 'Activo' : 'Error'}
                    precision={2}
                    valueStyle={{
                        color: !status?.error ? '#3f8600' : '#cf1322',
                    }}
                />
            </Card>
        </Space>    
    )
}

export default StorageStatus