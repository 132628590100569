import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


const Redirect = () => {
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        console.log('EbbbLocation: ', location, navigate);
        navigate(location.state?.route, { state: { fromLogin: true } });
    }, [navigate, location]);

    return (
        <div>
            <h1>React</h1>
            <h2>Redirecting...</h2>
            <p>Redirecting to {location.state?.route}</p>
        </div>
    )
}
    

export default Redirect;