import Parse from 'parse';

const fetchModality = async (value) => {
    return new Promise(async (resolve, reject) => {
        console.log('Modality', value);
        const query = new Parse.Query('Modalities');
        query.matches('commonName', value, 'i');
        const results = (await query.find()).map(item => item.toJSON());
        console.log(results);
        const modalities = results.map(item => {
            return {
                value: item.commonName,
                label: item.commonName,
                key: item.objectId,
                ...item
            }
        });
        console.log(modalities);
        resolve(modalities);
    });
}

export default fetchModality;