import Parse from 'parse';
import { message } from 'antd';

const cancelStudy = async (study, setOrder) => {
  try {
    const Study = Parse.Object.extend('Studies');
    const studyObject = await new Parse.Query(Study).get(study.objectId);
    studyObject.set('status', 'Cancelado');
    studyObject.set('worklistUpdated', true);

    const Events = Parse.Object.extend('Events');
    const event = new Events();
    event.set('study', studyObject);
    event.set('type', 'Study canceled');
    event.set('user', Parse.User.current());

    await Parse.Object.saveAll([studyObject, event]);
    message.success('Estudio cancelado');

    setOrder((prev) => ({
      ...prev,
      studies: prev.studies.map((item) =>
        item.objectId === study.objectId ? { ...item, status: 'Cancelado' } : item
      )
    }));
  } catch (error) {
    message.error('Error al cancelar el estudio');
  }
};

export default cancelStudy;