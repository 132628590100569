import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import React, { useState, useRef, useMemo } from 'react';

function DebounceSelect({ fetchOptions, debounceTimeout = 300, onFocus, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
      console.log('DebounceFetcher');
      const loadOptions = (value) => {
        // If value is nopt a string, set it as ''
        if (typeof value !== 'string') {
          value = '';
        }
        if (options.length > 0 && value === '') {
          return;
        }
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        });
      };
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        onFocus={debounceFetcher}
        {...props}
        options={options}
      />
    );
  }

  export { DebounceSelect };