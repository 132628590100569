import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Space, Card, Typography, Statistic, Row, Col } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';

const ModalityServiceStatus = () => {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                let { data } = await axios.get(`${process.env.REACT_APP_MODALITY_BOT_URL}/is-running`, {timeout: 5000});
                console.log('Modalities status', data);
                setStatus(data);
            } catch (error) {
                console.log(error);
                setStatus({serviceRunning: false, error: true})
            }
        }

        fetchStatus();
    }, []);
    

    return (
        <Space direction='vertical' style={{padding: 20, width: '100%', height: '100%'}} >
                    <Card loading={!status} style={{ width: '100%' }}>
                    <Statistic
                        title="Envío a modalidades"
                        value={status?.serviceRunning ? 'Activo' : 'Error'}
                        precision={2}
                        valueStyle={{
                            color: status?.serviceRunning ? '#3f8600' : '#cf1322',
                        }}
                        />
                    </Card>
        </Space>    
    )
}

export default ModalityServiceStatus 
