import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import React, { useState, useRef, useMemo } from 'react';

const handleFocus = () => {
  console.log('Select component is focused');
};

function DebounceSelect({ fetchOptions, debounceTimeout = 300, renderOptions, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
      const loadOptions = (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        });
      };
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}        
        onFocus={handleFocus}
        onDropdownVisibleChange={(open) => console.log('Dropdown is', open ? 'open' : 'closed')}
        {...props}
        options={options}
        optionRender={renderOptions}
      />
    );
  }

  export { DebounceSelect };