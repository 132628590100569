import React, { useEffect } from 'react';
import { Modal, Table, Tag, Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const renderTime = (time) => {
    if (isNaN(time)) {
        return 'Desconocido';
    }
    let value = 0;
    let suffix = '';

    if (time === 1) {
        value = time;
        suffix = 'minuto';
    } else if (time > 1440) {
        value = (time / 1440).toFixed(1);
        suffix = 'días';
        if (value === 1) {
            suffix = 'día';
        }
    } else if (time > 60) {
        value = (time / 60).toFixed(1);
        suffix = 'horas';
        if (value === 1) {
            suffix = 'hora';
        }
    } else {
        value = time;
        suffix = 'minutos';
    }

    return `${value} ${suffix}`;
}

const renderEvent = (event) => {
    switch (event) {
        case 'Study made':
            return 'Estudio realizado';
        case 'Study delivered':
            return 'Estudio entregado';
        case 'Study information completed':
            return 'Información del estudio completada';
        case 'Interpretation created':
            return 'Interpretación creada';
        default:
            return event;
    }
}

const UserEventList = ({ open, setOpen, data, title }) => {
    console.log('Data: ', data);
    const navigate = useNavigate();

    const columns = [
        {
            title: 'Estudio',
            dataIndex: 'study',
            key: 'study',
            render: (study) => {
                return <Button type="link" onClick={() => window.open(`/study/${study.internalUUID}`)}>{study.accessionNumber}</Button>;
            },
            sorter: (a, b) => a.study.accessionNumber.localeCompare(b.study.accessionNumber),
        },
        {
            title: 'Evento',
            dataIndex: 'eventType',
            key: 'eventType',
            render: (eventType) => {
                return renderEvent(eventType);
            },
            sorter: (a, b) => a.eventType.localeCompare(b.eventType),
        },
        {
            title: 'Tiempo',
            dataIndex: 'time',
            key: 'time',
            render: (time) => {
                return renderTime(time);
            },
            sorter: (a, b) => a.time - b.time,
        },
    ];

    return (
        <Modal
            title={title}
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={800}
        >
            <Table dataSource={data} columns={columns} />
        </Modal>
    );
}

export { UserEventList };
