import Parse from 'parse';
import moment from 'moment';

const fetchAllEvents = async (dates, onlySameDay) => {
    let initialDate;
    let finalDate;
    if (dates?.length) {
        initialDate = moment(dates?.[0]?.$d).startOf('day').toDate();
        finalDate = moment(dates?.[1].$d).endOf('day').toDate();
    }
    const results = await Parse.Cloud.run('getEvents', {limit: 5000, dates: dates ? [initialDate, finalDate] : []});

    // Group all the events by the study
    const groupedEvents = results.reduce((acc, event) => {
        if (!acc[event.study.objectId]) {
            acc[event.study.objectId] = [];
        }
        acc[event.study.objectId].push(event);
        return acc;
    }, {});

    let users = [];

    // Get the user and the time between the 'Study images received' and any 'Study delivered...' event
    let userTimes = Object.keys(groupedEvents).map(studyId => {
        let studyMadeInfo = {};
        let studyDeliveredInfo = {};
        let studyInformationCompletedInfo = {};
        let interpretationCreatedInfo = {};
        let allInfo = [];
        const events = groupedEvents[studyId].sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));
        const studyImagesReceived = events.find(event => event.type === 'Study images received');
        const studyDelivered = events.find(event => event.type === 'Study delivered by WhatsApp' || event.type === 'Study delivered by email');
        const studyCreated = events.find(event => event.type === 'Study created') // && !event.user.objectId === 'bOBOeVlOvW');
        const studyMade = events.find(event => event.type === 'Study made') // && !event.user.objectId === 'bOBOeVlOvW');
        const studyInformationCompleted = events.find(event => event.type === 'Study information completed');
        const interpretationCreated = events.find(event => event.type === 'Interpretation created');
        if (studyCreated && studyMade && studyMade.user) {
            const radiologist = studyMade.study.radiologist
            const user = studyMade.user;
            if (!users.find(u => u.objectId === user.objectId)) {
                users.push(user);
            }
            const study = studyMade.study;
            let time = moment(studyMade.createdAt).diff(moment(studyCreated.createdAt), 'minutes');
            studyMadeInfo = { user, time, eventType: 'Study made', study, radiologist };
            if (studyCreated.study.createdFromModality) {
                studyMadeInfo = {};
            }
            if (onlySameDay && time >= 1440) {
                studyMadeInfo = {};
            }
        }
        if (studyImagesReceived && studyDelivered && studyDelivered.user) {
            const user = studyDelivered.user;
            if (!users.find(u => u.objectId === user.objectId)) {
                users.push(user);
            }
            const study = studyDelivered.study;
            let time = moment(studyDelivered.createdAt).diff(moment(studyImagesReceived.createdAt), 'minutes');
            if (time < 0) {
                time = 0;
            }
            studyDeliveredInfo = { user, time, eventType: 'Study delivered', study };
            if (onlySameDay && time >= 1440) {
                studyDeliveredInfo = {};
            }
        }
       
        if (studyMade && studyInformationCompleted && studyInformationCompleted.user) {
            console.log(studyMade, studyInformationCompleted);
            const user = studyInformationCompleted.user;
            if (!users.find(u => u.objectId === user.objectId)) {
                users.push(user);
            }
            const study = studyInformationCompleted.study;
            let time = moment(studyInformationCompleted.createdAt).diff(moment(studyMade.createdAt), 'minutes');
            if (time < 0) {
                time = 0;
            }
            studyInformationCompletedInfo = { user, time, eventType: 'Study information completed', study };
            if (onlySameDay && time >= 1440) {
                studyInformationCompletedInfo = {};
            }
        }
        if (studyImagesReceived && interpretationCreated && interpretationCreated.user) {
            const user = interpretationCreated.user;
            if (!users.find(u => u.objectId === user.objectId)) {
                users.push(user);
            }
            const study = interpretationCreated.study;
            let time = moment(interpretationCreated.createdAt).diff(moment(studyImagesReceived.createdAt), 'minutes');
            if (time < 0) {
                time = 0;
            }
            interpretationCreatedInfo = { user, time, eventType: 'Interpretation created', study };
            if (onlySameDay && time >= 1440) {
                interpretationCreatedInfo = {};
            }
        }

        studyMadeInfo && allInfo.push(studyMadeInfo);
        studyDeliveredInfo && allInfo.push(studyDeliveredInfo);
        studyInformationCompletedInfo && allInfo.push(studyInformationCompletedInfo);
        interpretationCreatedInfo && allInfo.push(interpretationCreatedInfo);

        return allInfo;
    });
    
    userTimes = userTimes.flat();

    // Group the time by user and calculate the average
    const userTimesGrouped = userTimes.reduce((acc, userTime) => {
        let name = userTime?.user?.fullName;
        let user = userTime?.user;
        if (userTime.study && userTime.study.radiologist && userTime.eventType === 'Study made') {
            name = users.find(u => u.objectId === userTime.study.radiologist.id).fullName;
            user = users.find(u => u.objectId === userTime.study.radiologist.id);
        }
        console.log('Namee', name);
        if (!userTime || !name) {
            return acc;
        }
        if (!acc[name]) {
            acc[name] = [];
        }
        acc[name].push({
            time: userTime.time,
            eventType: userTime.eventType,
            userObject: user,
            study: userTime.study,
            radiologist: userTime.radiologist
        });
        return acc;
    }, {});


    // Get the average time for each user and event type
    const userTimesAverage = Object.keys(userTimesGrouped).map(user => {
        let studyMade = {}
        let studyDelivered = {}
        let studyInformationCompleted = {}
        let interpretationCreated = {}
        const times = userTimesGrouped[user];
        const userObject = times[0].userObject;
        const study = times[0].study;
        const studyDeliveredTimes = times.filter(time => time.eventType === 'Study delivered');
        studyDelivered.min = Math.min(...studyDeliveredTimes.map(time => time.time > 0 ? time.time : 100000));
        studyDelivered.max = Math.max(...studyDeliveredTimes.map(time => time.time));
        studyDelivered.average = studyDeliveredTimes.reduce((acc, time) => acc + time.time, 0) / studyDeliveredTimes.length;
        studyDelivered.num = studyDeliveredTimes.length;
        studyDelivered.events = studyDeliveredTimes;
        const studyMadeTimes = times.filter(time => time.eventType === 'Study made');
        studyMade.min = Math.min(...studyMadeTimes.map(time => time.time > 0 ? time.time : 100000));
        studyMade.max = Math.max(...studyMadeTimes.map(time => time.time));
        studyMade.average = studyMadeTimes.reduce((acc, time) => acc + time.time, 0) / studyMadeTimes.length;
        studyMade.num = studyMadeTimes.length;
        studyMade.events = studyMadeTimes;
        const studyInformationCompletedTimes = times.filter(time => time.eventType === 'Study information completed');
        studyInformationCompleted.min = Math.min(...studyInformationCompletedTimes.map(time => time.time > 0 ? time.time : 100000));
        studyInformationCompleted.max = Math.max(...studyInformationCompletedTimes.map(time => time.time));
        studyInformationCompleted.average = studyInformationCompletedTimes.reduce((acc, time) => acc + time.time, 0) / studyInformationCompletedTimes.length;
        studyInformationCompleted.num = studyInformationCompletedTimes.length;
        studyInformationCompleted.events = studyInformationCompletedTimes;
        const interpretationCreatedTimes = times.filter(time => time.eventType === 'Interpretation created');
        interpretationCreated.min = Math.min(...interpretationCreatedTimes.map(time => time.time > 0 ? time.time : 100000));
        interpretationCreated.max = Math.max(...interpretationCreatedTimes.map(time => time.time));
        interpretationCreated.average = interpretationCreatedTimes.reduce((acc, time) => acc + time.time, 0) / interpretationCreatedTimes.length;
        interpretationCreated.num = interpretationCreatedTimes.length;
        interpretationCreated.events = interpretationCreatedTimes;
        return {
            user: userObject,
            studyMade,
            studyDelivered,
            studyInformationCompleted,
            interpretationCreated,
            study
        };
    });

    // Get the times for each type of event
    let studyMade = {};
    let studyDelivered = {};
    let studyInformationCompleted = {};
    let interpretationCreated = {};
    
    studyMade.min = Math.min(...userTimesAverage.map(user => user.studyMade.min));
    studyMade.max = Math.max(...userTimesAverage.map(user => user.studyMade.max));
    studyMade.average = userTimesAverage.filter(user => !isNaN(user.studyMade.average)).reduce((acc, user) => acc + user.studyMade.average, 0) / userTimesAverage.filter(user => !isNaN(user.studyMade.average)).length;
    studyMade.num = userTimesAverage.reduce((acc, user) => acc + user.studyMade.num, 0);
    studyMade.events = userTimesAverage.map(user => user.studyMade.events).flat();
    studyDelivered.min = Math.min(...userTimesAverage.map(user => user.studyDelivered.min));
    studyDelivered.max = Math.max(...userTimesAverage.map(user => user.studyDelivered.max));
    studyDelivered.average = userTimesAverage.filter(user => !isNaN(user.studyDelivered.average)).reduce((acc, user) => acc + user.studyDelivered.average, 0) / userTimesAverage.filter(user => !isNaN(user.studyDelivered.average)).length;
    studyDelivered.num = userTimesAverage.reduce((acc, user) => acc + user.studyDelivered.num, 0);
    studyDelivered.events = userTimesAverage.map(user => user.studyDelivered.events).flat();
    studyInformationCompleted.min = Math.min(...userTimesAverage.map(user => user.studyInformationCompleted.min));
    studyInformationCompleted.max = Math.max(...userTimesAverage.map(user => user.studyInformationCompleted.max));
    studyInformationCompleted.average = userTimesAverage.filter(user => !isNaN(user.studyInformationCompleted.average)).reduce((acc, user) => acc + user.studyInformationCompleted.average, 0) / userTimesAverage.filter(user => !isNaN(user.studyInformationCompleted.average)).length;
    studyInformationCompleted.num = userTimesAverage.reduce((acc, user) => acc + user.studyInformationCompleted.num, 0);
    studyInformationCompleted.events = userTimesAverage.map(user => user.studyInformationCompleted.events).flat();
    interpretationCreated.min = Math.min(...userTimesAverage.map(user => user.interpretationCreated.min));
    interpretationCreated.max = Math.max(...userTimesAverage.map(user => user.interpretationCreated.max));
    interpretationCreated.average = userTimesAverage.filter(user => !isNaN(user.interpretationCreated.average)).reduce((acc, user) => acc + user.interpretationCreated.average, 0) / userTimesAverage.filter(user => !isNaN(user.interpretationCreated.average)).length;
    interpretationCreated.num = userTimesAverage.reduce((acc, user) => acc + user.interpretationCreated.num, 0);
    interpretationCreated.events = userTimesAverage.map(user => user.interpretationCreated.events).flat();

    console.log('Usertimes', userTimesAverage);

    return { userTimes: userTimesAverage, studyMade, studyDelivered, studyInformationCompleted, interpretationCreated, users };
}

export { fetchAllEvents };