import React, { useEffect, useState } from 'react';
import { Space, Badge, Table, Typography, Divider, Tag, Row, Col, DatePicker, Statistic, Card, Grid } from 'antd';
import Parse from 'parse';
import moment from 'moment';
const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

const Access = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [printings, setPrintings] = useState([]);
    const screens = useBreakpoint();

    useEffect(() => {
        const queryAccess = async () => {
            const data = await Parse.Cloud.run('getPrintings', {});
            setData(data);
            setLoading(false);
        }
        queryAccess();
    }, [])

    const columns = [
        {
            title: 'Usuario',
            dataIndex: 'user',
            key: 'user',
            render: (user) => user?.fullName || 'Usuario desconocido'
        },
        {
            title: 'Estudio',
            dataIndex: 'study',
            key: 'study',
            render: (study) => study?.description
        },
        {
            title: 'Información',
            dataIndex: 'media',
            key: 'media',
            render: (media) => <Tag color='blue'>{media?.map(m => m.media + ' ' + m.copies).join(', ')}</Tag>
        },
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('DD/MM/YYYY HH:mm')
        }
    ]

    const getTotalPrintings = () => {
        const printings = [{media: '', copies: 0}];
        data?.forEach(printing => {
            printing.media?.forEach(media => {
                const index = printings.findIndex(p => p.media === media.media);
                if (index === -1) {
                    printings.push({media: media.media, copies: parseInt(media.copies)});
                } else {
                    printings[index].copies += parseInt(media.copies)
                }
            })
        }
        )
        setPrintings(printings);
    }
            

    useEffect(() => {
        getTotalPrintings();
    }, [data])

    const Filters = () =>  <DatePicker.RangePicker
    style={{width: '100%'}}
    onChange={(dates) => {
        const query = new Parse.Query('Printings');
        query.include(['user', 'study', 'study.patient']);
        query.descending('createdAt');
        query.greaterThanOrEqualTo('createdAt', new Date(moment(dates?.[0]?.$d).startOf('day').format()));
        query.lessThanOrEqualTo('createdAt', new Date(moment(dates?.[1]?.$d).endOf('day').format()));
        query.find().then((results) => {
            const data = results?.map(access => access.toJSON());
            setData(data);
        })
    }}
/> 

const StatCards = () => <Space direction='horizontal' style={{width: '100%', justifyContent: 'flex-start', overflowX: 'auto'}}>
{printings?.map(printing => printing.media && <Card style={{width: '100%'}}>
    <Statistic title={printing.media} value={printing.copies} />
</Card>)}
</Space>

const PrintingList = () => <Space direction='horizontal' style={{width: '100%', justifyContent: 'flex-start', overflowX: 'auto'}}>
    {data?.map(printing =>  <Card style={{width: '100%'}}>
        <Card title={printing?.user?.fullName || 'Usuario desconocido'} style={{width: '100%'}}>
            <Space direction='vertical'>
                <p>Estudio: {printing?.study?.description}</p>
                <p>Paciente: {printing?.study?.patient?.fullName}</p>
                <p>Fecha: {moment(printing?.createdAt).format('DD/MM/YYYY HH:mm')}</p>
                <p>Fecha: {moment(printing?.createdAt).format('DD/MM/YYYY HH:mm')}</p>
                {printing?.media?.map(media => <Tag color='blue'>{media.copies} x {media.media}</Tag>)}
            </Space>
        </Card>
    </Card>)}
</Space>

if (screens.xs) {
    return (
        <Space direction='vertical' style={{padding: 20, width: '100%'}} >
            <Title level={3}>Impresiones</Title>
            <Filters />
            <StatCards />
            <Divider />
            <PrintingList />
        </Space>
    )
}

    return (
        <Space direction='vertical' style={{padding: 20, width: '100%'}} >
            <Title level={1}>Impresiones</Title>
            <Space direction='horizontal' style={{width: '100%', justifyContent: 'flex-end', paddingRight: 20}}>
           <Filters />
            </Space>
            <StatCards />
            <Divider />
            <Space direction='vertical' style={{width: '100%', justifyContent: 'space-between'}}>
                
                <Table dataSource={data} columns={columns} loading={loading} />
            </Space>
        </Space>
    )
}

export default Access;
