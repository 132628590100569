import React, { useEffect, useState } from 'react';
import { Space, Input, Select, Button, Checkbox, Modal, message } from 'antd';
import Parse from 'parse';
import Title from 'antd/es/typography/Title';

const StudyConfigModal = ({ open, onCancel, study, setStudies }) => {
    const [protocols, setProtocols] = useState([]);
    const [data, setData] = useState([]);
    const [value, setValue] = useState();
    const [search, setSearch] = useState();
    const [description, setDescription] = useState(study?.description);
    const [catalogObject, setCatalogObject] = useState();
    const [modality, setModality] = useState(study?.modality?.commonName);
    const [modalities, setModalities] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProtocols = async () => {
            const query = new Parse.Query('Catalogs');
            query.equalTo('type', 'protocols');
            const protocols = await query.first();
            console.log(protocols)
            if (!protocols) return;
            setCatalogObject(protocols);
            setProtocols(protocols?.toJSON().arrayContent.sort((a, b) => a.localeCompare(b)));
            setLoading(false);
        }

        const fetchModalities = async () => {
            const query = new Parse.Query('Modalities');
            const modalities = await query.find();
            if (!modalities) return;
            console.log(modalities.map(modality => {  return { value: modality.get('commonName'), label: modality.get('commonName'), ...modality.toJSON() } }))
            setModalities(modalities.map(modality => {  return { value: modality.get('commonName'), label: modality.get('commonName'), ...modality.toJSON() } }));
        }

        fetchProtocols();
        fetchModalities();
        setData(study?.protocols?.map(protocol => protocol.name) || []);
        setDescription(study?.description);
    }, [study])

    const saveConfig = async () => {
        try {
            const modalityPointer = {
                __type: 'Pointer',
                className: 'Modalities',
                objectId: modality.objectId
            }
            const StudyDescriptions = Parse.Object.extend('StudyDescriptions');
            const studyDescription = new StudyDescriptions();
            study?.editing && studyDescription.set('objectId', study.objectId);
            studyDescription.set('protocols', data.map(protocol => ({ name: protocol })));
            studyDescription.set('description', description);
            studyDescription.set('modality', modalityPointer);
            await studyDescription.save();

            // Review if data.protocols contains an element that is not in the protools array and append it to the catalog  
            const Catalogs = Parse.Object.extend('Catalogs');
            const catalog = new Catalogs();
            let newProtocols = new Set([...protocols, ...data]);
            newProtocols = Array.from(newProtocols).sort((a, b) => a.localeCompare(b));
            console.log('Caatalogid', catalogObject.id)
            catalog.set('objectId', catalogObject.id);
            catalog.set('arrayContent', newProtocols);
            await catalog.save();
        
            message.success('Configuración guardada correctamente');
            setStudies((studies) => studies.map(prev => prev.objectId === study.objectId ? { ...prev, protocols: data.map(protocol => ({ name: protocol })) } : prev));
            onCancel();
        } catch (error) {
            console.error('Error while saving study configuration: ', error);
            message.error('Error al guardar la configuración');
        }
    }
  


        return (
            <Modal
                title="Configuración de estudio"
                open={open}
                onCancel={onCancel}
                footer={<Space><Button type="primary" onClick={saveConfig}>Guardar</Button><Button onClick={onCancel}>Cancelar</Button></Space>}
            >
                <Space direction="vertical">
                <Select style={{ width: '30vw' }} placeholder="Modalidad" value={study?.modality?.commonName} disabled={study?.editing} onChange={(value,item) => setModality(item)} options={modalities} />

                    <Input placeholder="Nombre del estudio" value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: '30vw' }} />
                    <h3>Proyecciones</h3>
                    <Select mode='multiple' style={{ width: '30vw' }} placeholder="Seleccione las proyecciones" value={data} onChange={setData} inputValue={value}
                    onSearch={(value) => setValue(value)}
                    // If not found, capture current input value and create a new projection
                    notFoundContent={<Button type="link" onClick={() => {
                        setData([...data, value])
                        setValue(undefined)
                    }} >Crear proyección</Button>}>
                        {protocols.map(protocol => <Select.Option key={protocol} value={protocol}>{protocol}</Select.Option>)}
                    </Select>
                </Space>
            </Modal>

        )
}


export default StudyConfigModal;

