import { Space, Typography, Button, Card,Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import Parse from 'parse';
const { Title, Text } = Typography;

const toolbarOptions = {
    toolbar: {
      container: [
        [{'header': [1,2,3,0]}],
        ['bold', 'italic', 'underline', 'strike'], // Basic formatting buttons
        [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Ordered and bullet lists
        [{ 'script': 'sub' }, { 'script': 'super' }],  // Subscript and superscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],      // Indent and outdent
        [{ 'color': [] }, { 'background': [] }],        // Text and background color buttons
        [{ 'align': [] }],                              // Text alignment buttons
        ['clean']                                       // Clear formatting button
      ],
    },
  };


const Template = () => {
  const [template, setTemplate] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

    useEffect(() => {
        location.state?.template ? setTemplate(location.state.template) : setTemplate({ new: true, name: '', content: '' });
        setLoading(false);
    }, [location]);

    const saveTemplate = async () => {
       const Templates = Parse.Object.extend('Templates');
       const templateObject = new Templates();
       template?.objectId && templateObject.set('objectId', template.objectId);
       !template?.objectId && templateObject.set('user', Parse.User.current());
       templateObject.set('content', template.content);
       templateObject.set('name', template.name);

       templateObject.save().then(() => {
                message.success('Template guardado');
                setTemplate({...template, new: false});
            }).catch((error) => {
                message.error('Error al guardar el template');
            });
      }

  if (template?.new) return <Space direction='vertical' style={{padding: 20, width: '80vw'}} >
    <Title level={2}>Nuevo template</Title>
    <Input placeholder="Nombre del template" value={template?.name} onChange={(e) => setTemplate({...template, name: e.target.value})} />
    <ReactQuill theme="snow" value={template?.content} onChange={(value) => setTemplate({...template, content: value})} modules={{ toolbar: toolbarOptions }} style={{height: '70vh'}} />
    <Button type="primary" style={{position: 'fixed', bottom: 20, right: 20}} onClick={saveTemplate}>Guardar</Button>
  </Space>

  return  <Space direction='vertical' style={{padding: 20, width: '80vw'}} >
    <Title level={2}>{template?.name}</Title>
        <Space direction='vertical' style={{width: '80vw', padding: 20}} loading={loading}>
            {editing 
                ? <ReactQuill theme="snow" value={template?.content} onChange={(value) => setTemplate({...template, content: value})} modules={{ toolbar: toolbarOptions }} style={{height: '70vh'}} />
                : template && <Card title="Vista previa" style={{ width: '100%' }}>
                    <div dangerouslySetInnerHTML={{__html: template?.content}} />
                </Card>}
              {}
        </Space>
        {editing
        ? <Button 
          type="primary" 
          style={{position: 'fixed', bottom: 20, right: 20}}
          onClick={saveTemplate}
          >Guardar</Button>
        : <Button
            type="primary"
            style={{position: 'fixed', bottom: 20, right: 20}}
            onClick={() => setEditing(true)}
            >Editar</Button>}
            <Button type="primary" style={{position: 'fixed', bottom: 20, right: 100}} onClick={() => navigate(-1)}>Volver</Button>
    </Space>
}

export default Template