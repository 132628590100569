import React from 'react';
import { Card, Typography, Space, Button, Popover } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const { Text, Title } = Typography;

const StudiesList = ({ order, screens, canceling, setCanceling, cancelStudy }) => {
  const navigate = useNavigate();

  return (
    <Space direction="horizontal" style={{ width: '100%', padding: 20, overflowX: 'scroll' }}>
      {order?.studies.map(study => (
        <Card key={study.id} title={<Title level={4}>{study.studyID}</Title>} style={{ width: screens.xs ? '50vw' : '30vw' }} hoverable>
          <Space direction="vertical">
            <Text>{study.status}</Text>
            <Text>{study.description}</Text>
            <Text>{moment(study.createdAt).format('DD/MM/YYYY')}</Text>
            <Text>{study.modality?.commonName}</Text>
            {study.status !== 'Pendiente' && study.status !== 'Enviado a modalidad' ? (
              <Button type="link" onClick={() => navigate(`/study/${study.internalUUID}`, { state: { fromMenu: true } })}>Ver estudio</Button>
            ) : (
              <Popover
                key={study.id} // Ensure each Popover component has a unique key
                content={
                  <Space direction="horizontal">
                    <Text onClick={() => cancelStudy(study)} style={{ color: 'red', cursor: 'pointer' }}>Sí, cancelar</Text>
                    <Text onClick={() => setCanceling(false)} style={{ color: 'blue', cursor: 'pointer' }}>No, regresar</Text>
                  </Space>
                }
                title="¿Quieres cancelar este estudio?"
                trigger="click"
                open={canceling}
              >
                <Text onClick={() => setCanceling(true)} style={{ color: 'red', cursor: 'pointer' }}>Cancelar</Text>
              </Popover>
            )}
          </Space>
        </Card>
      ))}
    </Space>
  );
};

export default StudiesList;