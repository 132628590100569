import React, {useState, useEffect } from 'react';
import { Flex, Modal, Button, Input, Form, message, Typography, Space, Tag, Descriptions } from 'antd';
import moment from 'moment';
const { Title, Text } = Typography;

const PaymentDetailsModal = ({ open, setOpen }) => {

    const transaction = open;

    if (!open) {
        return null;
    }

    const renderPaymentMethod = () => {
        if (transaction.method === 'card') {
            return (
                <Descriptions.Item label="Método de pago">Tarjeta de {transaction.card.cardType === 'debit' ? 'débito' : 'crédito'}</Descriptions.Item>
            )
        }

        return (
            <Descriptions.Item label="Bank Name">{transaction.card.bank_name}</Descriptions.Item>
        )
      }

    const renderCardBrand = () => {
      if (transaction.card.brand === 'visa') {
        return  <Descriptions.Item label="Tipo"><Tag color="gray">Visa</Tag></Descriptions.Item>
      }
      if (transaction.card.brand === 'mastercard') {
        return <Descriptions.Item label="Tipo"><Tag color="orange">Mastercard</Tag></Descriptions.Item>
      }
      if (transaction.card.brand === 'american_express') {
        return <Descriptions.Item label="Tipo"><Tag color="blue">American Express</Tag></Descriptions.Item>
      }
      return <Descriptions.Item label="Tipo"><Tag color="purple">{transaction.card.brand}</Tag></Descriptions.Item>
    }

    return (
        <Modal title="Detalles de pago" open={open} footer={null} onCancel={() => setOpen(false)}>
        <Descriptions bordered column={1}>
        <Descriptions.Item label="Fecha de pago">{moment(transaction.operation_date).format('DD/MM/YYYY')}</Descriptions.Item>
          <Descriptions.Item label="Código de autorización">{transaction.authorization}</Descriptions.Item>
          <Descriptions.Item label="Monto">{String(transaction.amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}.00</Descriptions.Item>
          {renderPaymentMethod()}
          {renderCardBrand()}
          <Descriptions.Item label="Banco">{transaction.card.bank_name}</Descriptions.Item>
          <Descriptions.Item label="Número de tarjeta">{transaction.card.card_number}</Descriptions.Item>
          <Descriptions.Item label="Nombre en la tarjeta">{transaction.card.holder_name}</Descriptions.Item>
          <Descriptions.Item label="Correo electrónico">{transaction.customer.email}</Descriptions.Item>
        </Descriptions>
        {/* <Space direction="horizontal" justify="center" align="flex-end" style={{marginTop: 20}}>
          <Button type="primary" href={transaction.payment_method.url} target="_blank">Ver recibo</Button>
        </Space>   */}
      </Modal>
    )
}

export default PaymentDetailsModal;