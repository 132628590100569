import Parse from "parse";
import moment from "moment";



const fetchStudies = async ({ value, patients, dates, radiologists, interpreters, status, groupBy, modality, referrers, onlyCompleted, specialScheduled, interpreted, incompleteInformation, createdFromModality, clinics, clinicId, deliveriesNumber, interpretationsNumber, createdBy }) => {
  return new Promise(async (resolve, reject) => {
    console.log('Fetching studies from clinic', clinicId, deliveriesNumber, interpretationsNumber);
    let data = {};
    let newDates = dates?.length > 0 ? [moment(dates[0].$d).startOf('month').toDate(), moment(dates[1].$d).endOf('month').toDate()] : [];
    console.log('New dates', newDates);
    const params = { value, patients, dates: newDates, radiologists, interpreters, status, groupBy, modality, referrers, onlyCompleted, specialScheduled, interpreted, incompleteInformation, createdFromModality, clinics, clinicId, deliveriesNumber, interpretationsNumber, createdBy };
    console.log('Params', params.modality);
    const results = await Parse.Cloud.run("getStudiesAnalytics", params);
    const sentStudies = await Parse.Cloud.run("getStudiesAnalytics", { deliveriesNumber: {type: '>', value: '1'}, clinicId });
    const sentNumber = sentStudies.length;
    console.log('Sent studies', sentStudies.length, sentStudies);
    console.log('Studies', results);
    // find any nested parse objects and convert them to JSON
    data.rawData = results
    console.log('Raw data', data.rawData);

    // Count the number of studies per radiologist
    data.radiologists = {};
    results.forEach((item) => {
      if (item.radiologist) {
        if (data.radiologists[item.radiologist.fullName]) {
          data.radiologists[item.radiologist.fullName]++;
        } else {
          data.radiologists[item.radiologist.fullName] = 1;
        }
      }
    });
    // Sort from highest to lowest
    data.radiologists = Object.fromEntries(Object.entries(data.radiologists).sort(([,a],[,b]) => b-a));

    // Count the number of studies per interpreter
    data.interpreters = {};
    results.forEach((item) => {
      if (item.interpreter) {
        if (data.interpreters[item.interpreter.fullName]) {
          data.interpreters[item.interpreter.fullName]++;
        } else {
          data.interpreters[item.interpreter.fullName] = 1;
        }
      }
    });
    // Sort from highest to lowest
    data.interpreters = Object.fromEntries(Object.entries(data.interpreters).sort(([,a],[,b]) => b-a));

    // Count the number of studies per modality
    data.modalities = {};
    results.forEach((item) => {
      if (item.modality) {
        if (data.modalities[item.modality.commonName]) {
          data.modalities[item.modality.commonName]++;
        } else {
          data.modalities[item.modality.commonName] = 1;
        }
      }
    });
    // Sort from highest to lowest
    data.modalities = Object.fromEntries(Object.entries(data.modalities).sort(([,a],[,b]) => b-a));

    // Count the number of studies per groupBy variable, which can be 'day', 'week' and 'month'
    data.groupBy = {};
    results.forEach((item) => {
      const date = moment(item.createdAt).startOf(groupBy)
      if (data.groupBy[date]) {
        data.groupBy[date]++;
      } else {
        data.groupBy[date] = 1;
      }
    });
    // Sort from highest to lowest
    data.groupBy = Object.fromEntries(Object.entries(data.groupBy).sort(([,a],[,b]) => b-a));

    // Count the number of studies per description
    data.descriptions = {};
    results.forEach((item) => {
      if (item.description) {
        if (data.descriptions[item.description]) {
          data.descriptions[item.description]++;
        } else {
          data.descriptions[item.description] = 1;
        }
      }
    });
    // Sort from highest to lowest
    data.descriptions = Object.fromEntries(Object.entries(data.descriptions).sort(([,a],[,b]) => b-a));
    
    data.referrer = {};
    results.forEach((item) => {
      console.log(item.referrer);
      if (item.referrer) {
        console.log(item.referrer.fullName);
        if (data.referrer[item.referrer.fullName]) {
          data.referrer[item.referrer.fullName]++;
        } else {
          data.referrer[item.referrer.fullName] = 1;
        }
      }
    });
    // Sort from highest to lowest
    data.referrer = Object.fromEntries(Object.entries(data.referrer).sort(([,a],[,b]) => b-a));

    data.clients = {};
    results.forEach((item) => {
      if (item.client) {
        if (data.clients[item.client.name]) {
          data.clients[item.client.name]++;
        } else {
          data.clients[item.client.name] = 1;
        }
      }
    });
    // Sort from highest to lowest
    data.clients = Object.fromEntries(Object.entries(data.clients).sort(([,a],[,b]) => b-a));

    console.log(data);

    // Count the total number of studies
    data.totalStudies = results.length;

    // Count the number of studies with status as "Recibiendo imágenes", "Imágenes recibidas", "Interpretado"
    data.completedStudies = results.filter((item) => ["Recibiendo imágenes", "Imágenes recibidas", "Interpretado", "Entregado"].includes(item.status)).length;
    
    // Count the number of canceled studies
    data.canceledStudies = results.filter((item) => item.status === "Cancelado").length;

    // Count the number of studies with interpretation
    data.interpretedStudies = results.filter((item) => item.interpretation).length;

    // Count the number of pending studies, which are not in the previous conditions
    data.pendingStudies = results.filter((item) => !["Recibiendo imágenes", "Imágenes recibidas", "Interpretado", "Cancelado", "Entregado"].includes(item.status)).length;

    // Count the number of incompleteInformation studies
    data.incompleteInformation = results.filter((item) => item.incompleteInformation).length;

    // Count the number of createdFromModality studies
    data.createdFromModality = results.filter((item) => item.createdFromModality).length;

    data.sentNumber = sentNumber;
    
    resolve(data);
  });
}

export default fetchStudies;