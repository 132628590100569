import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Space, Select, Spin, Tabs, Checkbox, Divider, message } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { UserOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import Parse from 'parse';

const SendModal = ({ study, setOrder, open, setOpen, order, setRefresh }) => {
    const [physicalDelivery, setPhysicalDelivery] = useState(false);
    const [other, setOther] = useState(false);
    const [radiologists, setRadiologists] = useState([]);
    const [radiologist, setRadiologist] = useState(undefined);

    console.log('Study', study);

    useEffect(() => {
        const fetchRadiologists = async () => {
            const params = { role: ['Radiology physician'] };
            const radiologists = await Parse.Cloud.run('queryUsers', params);
            setRadiologists(radiologists);
        }
        console.log('Fetching radiologists');
        fetchRadiologists();
    }, [open]);

    const saveOrderDelivery = (figure) => {
            order?.studies.forEach((study) => {
                saveDelivery(figure, study);
            });
    }

    const clearOrderRadiologist = async () => {
        const newStudies = order?.studies.map(study => {
            const newStudy = study;
            newStudy.interpreter = undefined;
            return newStudy;
        });
        setOrder({...order, studies: newStudies});
    }
    

    const saveDelivery = (figure) => {
        try {
            const Deliveries = Parse.Object.extend('Deliveries');
            const delivery = new Deliveries();
            delivery.set('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study.objectId
            });
            delivery.set('type', 'Physical');
            delivery.set('content', [].concat(physicalDelivery.images ? 'Imágenes' : []).concat(physicalDelivery.report ? 'Interpretación' : []));
            delivery.set('user', {
                __type: 'Pointer',
                className: '_User',
                objectId: Parse.User.current()?.id
            });
            delivery.set('deliveredTo', figure)
            delivery.save(); 

            const Events = Parse.Object.extend('Events');
            const event = new Events();
            event.set('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study.objectId
            });
            event.set('type', 'Study delivered physically');
            event.set('user', {
                __type: 'Pointer',
                className: '_User',
                objectId: Parse.User.current()?.id
            });
            event.set('figure', figure);
            event.save();

            const Studies = Parse.Object.extend('Studies');
            const studyObject = new Studies();
            studyObject.set('objectId', study.objectId);
            studyObject.set('status', 'Entregado');
            studyObject.save();

            message.success('Entrega física registrada correctamente');
            setPhysicalDelivery(undefined);
            console.log(physicalDelivery);
        } catch (error) {
            message.error('Ocurrió un error al registrar la entrega física');
        }
    }

    const assignOrderRadiologist = async () => {
        console.log('Assigning radiologist', radiologist);
        try {
            Promise.all(order?.studies.map(study => assignRadiologist(study))).then((radiologists) => {
                console.log(radiologists);
                message.success('Radiólogo asignado correctamente');
                // sendOrderEmail('Médico radiólogo');
                setOpen(false);
                setRefresh((prev) => prev + 1);
            });
        } catch (error) {
            console.error('Error while assigning radiologist: ', error);
            message.error('Ocurrió un error al asignar el radiólogo');
        }
    }

    const sendOrderWhatsApp = (figure) => {
        window.open(generateWhatsAppLink(figure));
        order?.studies.forEach((study) => {
            sendWhatsApp(figure, study);
        });
    }

    const sendOrderEmail = async (figure) => {
        try {
            const email = getEmailAddress(figure);
            if (!email) {
                message.error('No se encontró una dirección de correo electrónico para el ' + figure.toLowerCase());
                return;
            }
            const link = encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`);
            await Parse.Cloud.run('sendResultsEmail', { recipient: email, link });
            Promise.all(order?.studies.map(study => sendEmail(figure, study))).then(() => {
                message.success('Correo electrónico enviado correctamente');
            })
        } catch (error) {
            message.error('Ocurrió un error al enviar el correo electrónico');
        }
    }

    const assignRadiologist = async () => {
        return new Promise(async (resolve, reject) => {
            console.log('Assigning radiologist in study', study.objectId);
            try {
                const radiologistPointer = {
                    __type: 'Pointer',
                    className: '_User',
                    objectId: radiologist
                }

                console.log('Radiologist pointer: ', radiologistPointer);
                console.log('Study: ', study);

                const Studies = Parse.Object.extend('Studies');
                const studyObject = new Studies();
                studyObject.set('objectId', study.objectId);
                studyObject.set('interpreter', radiologistPointer);
                const result = await studyObject.save();

                console.log('Result: ', result);

                resolve();
            } catch (error) {
                reject(error);
            }
        })
    }

    const generateWhatsAppLink = (figure) => {
        switch (figure) {
            case 'Paciente':
                return `https://wa.me/${study?.patient?.phoneNumber}/?text=Buen día, ${study?.patient?.fullName}, le envío los estudios.%0APara acceder a los estudios, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`)}`;
            case 'Médico referidor':
                return `https://wa.me/${study?.referrer?.phoneNumber}/?text=Buen día, Dr. ${study?.referrer?.lastName}, le envío los estudios de ${study?.patient?.fullName}.%0APara acceder a los estudios, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`)}`;
            case 'Médico radiólogo':
                return `https://wa.me/${study?.interpreter?.phoneNumber}/?text=Buen día, Dr. ${study?.interpreter?.lastName}, le envío los estudios de ${study?.patient?.fullName} para su interpretación.%0APara acceder a los estudios, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`)}`;
            case 'Otro':
                return `https://wa.me/${other.phoneNumber}/?text=Buen día, ${other.name}, le envío los estudios de ${study?.patient?.fullName}.%0APara acceder a los estudios, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`)}`;
            default:
                return `https://wa.me/${study?.patient?.phoneNumber}/?text=Buen día, le envío los estudios de ${study?.patient?.fullName}.%0APara acceder a los estudios, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`)}`;
        }
    }

    const  generateEmail = (figure) => {
        switch (figure) {
            case 'Paciente':
                return `mailto:${study?.patient?.email}?subject=Estudios de paciente ${study?.patient?.fullName}&body=Buen día, ${study?.patient?.fullName}, le envío los estudios.%0APara acceder a los estudios, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`)}`;
            case 'Médico referidor':
                return `mailto:${study?.referrer?.email}?subject=Estudios de paciente ${study?.patient?.fullName}&body=Buen día, Dr. ${study?.referrer?.lastName}, le envío los estudios de ${study?.patient?.fullName}.%0APara acceder a los estudios, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`)}`;
            case 'Médico radiólogo':
                return `mailto:${study?.interpreter?.email}?subject=Estudios de paciente ${study?.patient?.fullName}&body=Buen día, Dr. ${study?.interpreter?.lastName}, le envío los estudios de ${study?.patient?.fullName} para su interpretación.%0APara acceder a los estudios, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`)}`;
            case 'Otro':
                return `mailto:${other.email}?subject=Estudios de paciente ${study?.patient?.fullName}&body=Buen día, ${other.name}, le envío los estudios de ${study?.patient?.fullName}.%0APara acceder a los estudios, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`)}`;
            default:
                return `mailto:${study?.patient?.email}?subject=Estudios de paciente ${study?.patient?.fullName}&body=Buen día, le envío los estudios de ${study?.patient?.fullName}.%0APara acceder a los estudios, haga click en el siguiente enlace: ${encodeURI(`${process.env.REACT_APP_PUBLIC_RIS_URL}/order/${order?.internalUUID}`)}`;
        }
    }

    const sendWhatsApp = (figure) => {
        try {
            const Deliveries = Parse.Object.extend('Deliveries');
            const delivery = new Deliveries();
            delivery.set('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study.objectId
            });
            delivery.set('type', 'WhatsApp');
            delivery.set('user', {
                __type: 'Pointer',
                className: '_User',
                objectId: Parse.User.current()?.id
            });
            delivery.set('deliveredTo', figure)
            delivery.save(); 

            const Events = Parse.Object.extend('Events');
            const event = new Events();
            event.set('study', {
                __type: 'Pointer',
                className: 'Studies',
                objectId: study.objectId
            });
            event.set('type', 'Study delivered by WhatsApp');
            event.set('user', {
                __type: 'Pointer',
                className: '_User',
                objectId: Parse.User.current()?.id
            });
            event.set('figure', figure);
            event.save();

            if (figure === 'Paciente') {
                const Studies = Parse.Object.extend('Studies');
                const studyObject = new Studies();
                studyObject.set('objectId', study.objectId);
                studyObject.set('status', 'Entregado');
                studyObject.save();
            }

            message.success('Entrega por WhatsApp registrada correctamente');
        } catch (error) {
            message.error('Ocurrió un error al registrar la entrega por WhatsApp');
        }
    }

    const getEmailAddress = (figure) => {
        switch (figure) {
            case 'Paciente':
                return study?.patient?.email;
            case 'Médico referidor':
                return study?.referrer?.email;
            case 'Médico radiólogo':
                return study?.interpreter?.email;
            case 'Otro':
                return other.email;
            default:
                return ''
        }
    }

    const sendEmail = async (figure) => {
        return new Promise(async (resolve, reject) => {
            try {
                
                const Deliveries = Parse.Object.extend('Deliveries');
                const delivery = new Deliveries();
                delivery.set('study', {
                    __type: 'Pointer',
                    className: 'Studies',
                    objectId: study.objectId
                });
                delivery.set('type', 'Email');
                delivery.set('user', {
                    __type: 'Pointer',
                    className: '_User',
                    objectId: Parse.User.current()?.id
                });
                delivery.set('deliveredTo', figure)
                delivery.save(); 

                const Events = Parse.Object.extend('Events');
                const event = new Events();
                event.set('study', {
                    __type: 'Pointer',
                    className: 'Studies',
                    objectId: study.objectId
                });
                event.set('type', 'Study delivered by email');
                event.set('user', {
                    __type: 'Pointer',
                    className: '_User',
                    objectId: Parse.User.current()?.id
                });
                event.set('figure', figure);
                event.save();

                if (figure === 'Paciente') {
                    const Studies = Parse.Object.extend('Studies');
                    const studyObject = new Studies();
                    studyObject.set('objectId', study.objectId);
                    studyObject.set('status', 'Entregado');
                    studyObject.save();
                }
                resolve();
            } catch (error) {
                reject(error);
            }
        })
    }
        
    return (
        <Modal
            open={open}
            title={<Title level={2}>Enviar estudios</Title>}
            onCancel={() => setOpen(false)}
            style={{ width: '80vw', top: 20 }}
            okButtonProps={{ style: { display: 'none' } }}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Tabs style={{ width: '100%' }} defaultActiveKey={study?.patient ? '1' : '4'}>
                        <Tabs.TabPane tab="Paciente" key="1" disabled={!study?.patient}>
                            <Space direction="horizontal">
                            <Space direction="vertical">
                                <Space direction="horizontal">
                                    <UserOutlined style={{ fontSize: 20 }} />
                                    <Text strong>{study?.patient?.fullName}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <PhoneOutlined style={{ fontSize: 20 }} />
                                    <Text>{study?.patient?.phoneNumber}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <MailOutlined style={{ fontSize: 20 }} />
                                    <Text>{study?.patient?.email}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <Button type="primary" onClick={() => sendOrderWhatsApp('Paciente')} style={{ marginRight: 10, backgroundColor: '#25D366', borderColor: '#25D366' }}><WhatsAppOutlined /> Enviar por WhatsApp</Button>
                                    <Button type="primary" onClick={() => sendOrderEmail('Paciente')} style={{ marginRight: 10, backgroundColor: '#1890FF', borderColor: '#1890FF' }}><MailOutlined /> Enviar por correo</Button>
                                </Space>
                                <Button type="primary" onClick={() => setPhysicalDelivery({})}><MailOutlined /> Entregar físicamente</Button>
                                {physicalDelivery && <Space direction="vertical">
                                    <Divider style={{ width: '100%' }} />
                                    <Space direction="horizontal">
                                    <Checkbox.Group>
                                        <Checkbox value="1" onChange={() => setPhysicalDelivery({...physicalDelivery, images: true})}>Imágenes</Checkbox>
                                        <Checkbox value="2" onChange={() => setPhysicalDelivery({...physicalDelivery, report: true})}>Interpretación</Checkbox>
                                    </Checkbox.Group>
                                    <Button type="primary" onClick={() => saveOrderDelivery('Paciente')}>Confirmar entrega</Button>
                                    <Button type="secondary" onClick={() => setPhysicalDelivery(undefined)}>Cancelar</Button>
                                </Space>
                                </Space>}
                            </Space>
                        </Space>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Médico tratante" key="2" disabled={!study?.referrer}>
                            <Space direction="horizontal">
                            <Space direction="vertical">
                                <Space direction="horizontal">
                                    <UserOutlined style={{ fontSize: 20 }} />
                                    <Text strong>{study?.referrer?.fullName}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <PhoneOutlined style={{ fontSize: 20 }} />
                                    <Text>{study?.referrer?.phoneNumber}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <MailOutlined style={{ fontSize: 20 }} />
                                    <Text>{study?.referrer?.email}</Text>
                                </Space>
                                <Space direction="horizontal">
                                    <Button type="primary" onClick={() => sendOrderWhatsApp('Médico referidor')} style={{ marginRight: 10, backgroundColor: '#25D366', borderColor: '#25D366' }}><WhatsAppOutlined /> Enviar por WhatsApp</Button>
                                    <Button type="primary" onClick={() => sendOrderEmail('Médico referidor')} style={{ marginRight: 10, backgroundColor: '#1890FF', borderColor: '#1890FF' }}><MailOutlined /> Enviar por correo</Button>
                                </Space>
                            </Space>
                            </Space>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Médico radiólogo" key="3">
                            {study?.interpreter && <Space direction="horizontal">
                            <Space direction="vertical">
                                <Space direction="horizontal" style={{ width: '100%', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Space direction="vertical">
                                        <Space direction="horizontal">
                                            <UserOutlined style={{ fontSize: 20 }} />
                                            <Text strong>{study?.interpreter?.fullName}</Text>
                                        </Space>
                                        <Space direction="horizontal">
                                            <PhoneOutlined style={{ fontSize: 20 }} />
                                            <Text>{study?.interpreter?.phoneNumber}</Text>
                                        </Space>
                                        <Space direction="horizontal">
                                            <MailOutlined style={{ fontSize: 20 }} />
                                            <Text>{study?.interpreter?.email}</Text>
                                        </Space>
                                    </Space>
                                    {!study?.interpretation && <Button type="primary" onClick={() => clearOrderRadiologist()}>Cambiar radiólogo</Button>}
                                </Space>
                                <Space direction="horizontal">
                                    <Button type="primary" onClick={() => sendOrderWhatsApp('Médico radiólogo')} style={{ marginRight: 10, backgroundColor: '#25D366', borderColor: '#25D366' }}><WhatsAppOutlined /> Enviar por WhatsApp</Button>
                                    <Button type="primary" onClick={() => sendOrderEmail('Médico radiólogo')} style={{ marginRight: 10, backgroundColor: '#1890FF', borderColor: '#1890FF' }}><MailOutlined /> Enviar por correo</Button>
                                </Space>
                            </Space>
                            </Space>}
                            {!study?.interpreter && <Space direction="horizontal">
                                <Text>Asignar radiólogo</Text>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="Seleccionar radiólogo"
                                    onChange={(value) => setRadiologist(radiologists.find(radiologist => radiologist.objectId === value).objectId)}
                                >
                                    {radiologists.map(radiologist => <Select.Option key={radiologist.objectId} value={radiologist.objectId}>{radiologist.fullName}</Select.Option>)}
                                </Select>
                                <Button type="primary" onClick={() => assignOrderRadiologist()}>Asignar</Button>
                            </Space>}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Otro" key="4">
                            <Space direction="horizontal">
                            <Space direction="vertical">
                                <Space direction="horizontal">
                                    <UserOutlined style={{ fontSize: 20 }} />
                                    <Input placeholder="Nombre completo" onChange={(e) => setOther({...other, name: e.target.value})} />
                                </Space>
                                <Space direction="horizontal">
                                    <PhoneOutlined style={{ fontSize: 20 }} />
                                    <Input placeholder="52 378 111 11 11" onChange={(e) => setOther({...other, phoneNumber: e.target.value})} />
                                </Space>
                                <Space direction="horizontal">
                                    <MailOutlined style={{ fontSize: 20 }} />
                                    <Input placeholder="email@email.com" onChange={(e) => setOther({...other, email: e.target.value})} />
                                </Space>
                                <Space direction="horizontal">
                                    <Button type="primary" onClick={() => sendOrderWhatsApp('Otro')} style={{ marginRight: 10, backgroundColor: '#25D366', borderColor: '#25D366' }}><WhatsAppOutlined /> Enviar por WhatsApp</Button>
                                    <Button type="primary" onClick={() => sendOrderEmail('Otro')} style={{ marginRight: 10, backgroundColor: '#1890FF', borderColor: '#1890FF' }}><MailOutlined /> Enviar por correo</Button>
                                </Space>
                            </Space>
                            </Space>
                        </Tabs.TabPane>
                    </Tabs>
                </Space>
        </Modal>
    );
}

export default SendModal;