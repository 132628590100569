import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Space, Card, Typography, Statistic, Row, Col } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
const StorageStatus = () => {
    const [storageStatus, setStorageStatus] = useState(null);

    useEffect(() => {
        const fetchStorageStatus = async () => {
            try {
                // REACT_APP_STORAGE_URL
                let { data } = await axios.get(`${process.env.REACT_APP_STORAGE_URL}/get-storage`, {timeout: 5000});
                data.percent = data.used_storage/data.total_storage*100;
                setStorageStatus(data);
            }   catch (error) {
                console.log(error);
                setStorageStatus({error: true})
            }
        }

        fetchStorageStatus();
    }, []);
    

    if (storageStatus?.error) return (
        <Space direction='vertical' style={{padding: 20, width: '100%', height: '100%'}} >
            <Card style={{ width: '100%' }}>
                <Statistic
                    title="Almacenamiento"
                    value='Error'
                    precision={2}
                    valueStyle={{
                        color: '#cf1322',
                    }}
                />
            </Card>
        </Space>    
    )
    return (
        <Space direction='vertical' style={{padding: 20, width: '100%'}} >
            <Card style={{ width: '100%' }} loading={!storageStatus}>
                    <Statistic
                        title="Uso de disco"
                        value={storageStatus?.used_storage/storageStatus?.total_storage*100}
                        precision={2}
                        valueStyle={{
                            color: storageStatus?.percent > 80 ? '#cf1322' : '#3f8600',
                        }}
                        suffix='%'
                        />
                    </Card>
                    <Card style={{ width: '100%' }} loading={!storageStatus}>
                    <Statistic
                        title="Espacio libre"
                        value={((storageStatus?.total_storage-storageStatus?.used_storage)/1024).toFixed(2)}
                        precision={2}
                        valueStyle={{
                            color: '#3f8600',
                        }}
                        suffix='GB'
                        />
                    </Card>
        </Space>    
    )
}

export default StorageStatus