import Parse from 'parse';


const queryProps = ['fullName', 'mail', 'email', 'phoneNumber', 'role'];
const  constructAccentedRegexPattern = (text)  => {
    // Map of characters to their possible accented versions
    const accentMap = {
      'a': '[aá]',
      'e': '[eé]',
      'i': '[ií]',
      'o': '[oó]',
      'u': '[uú]',
      'A': '[AÁ]',
      'E': '[EÉ]',
      'I': '[IÍ]',
      'O': '[OÓ]',
      'U': '[UÚ]'
    };
  
    // Replace each character in the text with its regex pattern
    return text.split('').map(char => accentMap[char] || char).join('');
  }



const query = async (value) => {
   // Count the number of studies for each patient
    return new Promise(async (resolve, reject) => {
        try {
            const params = { value };
            const users = await Parse.Cloud.run('queryUsers', params);
            console.log('Users', users);
            resolve(users);
        }
        catch (error) {
            console.error('Error while fetching patients: ', error);
            reject(error);
        }
    });

}

const saveUser = async (user) => {
    return new Promise(async (resolve, reject) => {
        console.log(user);
        try {
            const result = await Parse.Cloud.run('saveUser', {user});
            resolve(result);
        }
        catch (error) {
            console.error('Error while saving user: ', error);
            reject(error);
        }
    }
    );
}
        

  export { query, saveUser };