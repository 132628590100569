import React, { useEffect, useState, useContext } from 'react';
import { Space, DatePicker, Select, Skeleton, Checkbox, Grid, Modal, Button, Flex, Typography, Table, Tag } from 'antd';
import moment from 'moment';
import Parse from 'parse';
import { ClinicConfigContext } from '../../context';
import BankPaymentModal from './components/BankPaymentModal';
import CardPaymentModal from './components/CardPaymentModal';
import PaymentDetailsModal from './components/PaymentDetailsModal';
import { render } from '@testing-library/react';
const { Title, Text  } = Typography;

const Billing = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paymentOrder, setPaymentOrder] = useState(undefined);
    const [paymentDetails, setPaymentDetails] = useState(undefined);
    const [cardUrl, setCardUrl] = useState(undefined);
    const [bankOrderId, setBankOrderId] = useState(undefined);
    const [generatingCardUrl, setGeneratingCardUrl] = useState(false);
    const [generatingBankPayment, setGeneratingBankPayment] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [cardPayment, setCardPayment] = useState(undefined);
    const { clinicConfig } = useContext(ClinicConfigContext);

    const createBankPayment = async (data) => {
        setGeneratingBankPayment(true);
        console.log('Creating bank payment', data);
        const params = { ...data, clinicId: clinicConfig?.clinic?.objectId, billingId: data.objectId };
        const result = await Parse.Cloud.run('createBankPayment', params);
        console.log('Bank payment created', result);
        setBankOrderId(result.id)
        setPaymentOrder(result);
        setGeneratingBankPayment(false);
    }

    const cardRedirectPayment = async (data) => {
        setGeneratingCardUrl(true);
        const params = { ...data, clinicId: clinicConfig?.clinic?.objectId, billingId: data.objectId };
        const result = await Parse.Cloud.run('createRedirectPayment', params);
        console.log('Redirect payment created', result);
        // Open the link in a new tab
        window.open(result, '_blank');
        setCardUrl(result);
        setGeneratingCardUrl(false);

    }

    const fetchPaymentOrder = async (bankOrderId) => {
        setGeneratingBankPayment(true);
        const params = { transactionId: bankOrderId, clinicId: clinicConfig?.clinic?.objectId };
        const result = await Parse.Cloud.run('fetchPaymentOrder', params);
        console.log('Payment order fetched', result);
        setPaymentOrder(result);
        setGeneratingBankPayment(false);
    }

    const getInvoice = async (data) => {
        setLoadingPayment(true);
        const params = {clinicId: clinicConfig?.clinic?.objectId, transactionId: data.transactionId };
        const result = await Parse.Cloud.run('getPaymentDetails', params);
        console.log('Invoice fetched', result);
        setPaymentDetails(result);
        setLoadingPayment(false);
    }
        

    const columns = [
        {
            title: 'Folio',
            dataIndex: 'billId',
            key: 'billId'
        },
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => moment(date?.iso).format('DD/MM/YYYY')
        },
        {  
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Número de estudios',
            dataIndex: 'billedStudies',
            key: 'billedStudies'
        },
        {
            title: 'Total',
            dataIndex: 'amount',
            key: 'amount',
            // Render the amount as $3,500.00
            render: (amount) => '$' + String(amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '.00'
        },
        {
            title: 'Fecha de vencimiento',
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (date) => moment(date?.iso).format('DD/MM/YYYY')
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render : (status) => {
                if (status === 'Pendiente') {
                    return <Tag color="orange">Pendiente</Tag>
                }
                if (status === 'Pagado') {
                    return <Tag color="green">Pagado</Tag>
                }
                if (status === 'Cancelado') {
                    return <Tag color="red">Cancelado</Tag>
                }
                if (status === 'Vencido') {
                    return <Tag color="red">Vencido</Tag>
                }
            }
        },
        {
            title: 'Método de pago',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            render: (paymentMethod, item) => {
                if (paymentMethod === 'Tarjeta') {
                    return <Tag color="blue">Tarjeta de {item.cardType === 'credit' ? 'crédito' : 'débito'}</Tag>
                }
                if (paymentMethod === 'Transferencia') {
                    return <Tag color="green">Banco</Tag>
                }
            }
           
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (record) => (
                <Space size="middle">
                {record.status === 'Pendiente' ?
                    !record.bankOrderId && !bankOrderId 
                    ? <Button type="primary" onClick={() => createBankPayment(record)} loading={generatingBankPayment}>Pagar en banco</Button>
                    : <Button type="primary" onClick={() => fetchPaymentOrder(bankOrderId||record.bankOrderId)} loading={generatingBankPayment}>Ver orden de pago</Button>
                : null}
                    
                { record.status === 'Pendiente' ?
                     !record.redirectUrl && !cardUrl 
                        ? <Button type="primary" onClick={() => cardRedirectPayment(record)} loading={generatingCardUrl}>Pagar con tarjeta</Button>
                        : <Button type="primary" href={cardUrl||record.redirectUrl} target="_blank">Pagar con tarjeta</Button>
                : null}
                {record.status === 'Pagado' ? <Button type="primary" onClick={() => getInvoice(record)} loading={loadingPayment}>Ver detalles de pago</Button> : null}
                </Space>
            )
        }
    ]

    useEffect(() => {
        const fetchData = async () => {
            const clinicPointer = {
                __type: 'Pointer',
                className: 'Clinics',
                objectId: clinicConfig?.clinic?.objectId
            }
            const Billing = Parse.Object.extend('Billing');
            const query = new Parse.Query(Billing);
            query.equalTo('clinic', clinicPointer);
            const results = await query.find();
            setData(results.map(result => result.toJSON()));
            setLoading(false);
        }

        fetchData();
    }, []);
    
    return (
       <Flex vertical gap="10px" style={{ padding: '20px' }}>
            <BankPaymentModal open={paymentOrder} setOpen={setPaymentOrder} setRefresh={fetchPaymentOrder} />
            <CardPaymentModal open={cardPayment} setOpen={setCardPayment} setRefresh={fetchPaymentOrder} clinicId={clinicConfig?.clinic?.objectId} />
            <PaymentDetailsModal open={paymentDetails} setOpen={setPaymentDetails} />
            <Title>Facturación</Title>
            <Table columns={columns} dataSource={data} loading={loading} />
        </Flex>
    );
}

export default Billing;