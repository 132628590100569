// firebase.js
import {initializeApp} from "firebase/app";
import { message } from "antd";
import { getMessaging , getToken, onMessage } from "firebase/messaging";
import Parse from "parse";

const firebaseConfig = {
    apiKey: "AIzaSyBj6dqTbCbEJxtsW92XwFZPx45zjleejMg",
    authDomain: "imagelink-1af14.firebaseapp.com",
    projectId: "imagelink-1af14",
    storageBucket: "imagelink-1af14.appspot.com",
    messagingSenderId: "1023633150431",
    appId: "1:1023633150431:web:bf90810c1ad81f5a06746a",
    measurementId: "G-YQJVHEXWVH"
  };

const app = initializeApp(firebaseConfig);

const messageService = getMessaging(app);

const messaging = getMessaging();
onMessage(messaging, (payload) => {
  message.info(payload.notification.body, 10);
});

export {app, messageService};
