import React, {useState, useEffect } from 'react';
import { createContext } from 'react';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [sessionObject, setsessionObject] = useState(null);

    useEffect(() => {
        const storedSession = localStorage.getItem('sessionObject');
        console.log('Stored session', storedSession);
        if (storedSession) {
            console.log('Found session in local storage', JSON.parse(storedSession));
            if (moment(JSON.parse(storedSession).date).diff(moment(), 'days') > 1) {
                console.log('Session expired');
                localStorage.removeItem('sessionObject')    
                const newSession = {id: uuidv4(), date: moment().format()};
                localStorage.setItem('sessionObject', JSON.stringify(newSession));
                console.log('New session created', newSession);
                setsessionObject(newSession);
            } else {
                console.log('Session still valid');
                setsessionObject(JSON.parse(storedSession));
            }
        } else {
            console.log('No session found in local storage');
            const newSession = {id: uuidv4(), date: moment()};
            localStorage.setItem('sessionObject', JSON.stringify(newSession));
            console.log('New session created', newSession);
            setsessionObject(newSession);
        }
    }, []);

    return (
        <SessionContext.Provider value={[sessionObject, setsessionObject]}>
          {children}
        </SessionContext.Provider>
    );
    }