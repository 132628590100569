import React, { useEffect, useState, useContext } from 'react';
import { Form, Input, Button, Modal, Space, Radio, Select, message } from 'antd';
import moment from 'moment';
import { UserContext } from '../../../context';
import { BirthdateField, validateDate } from './BirthdateField';
import { saveUser } from '../utils/query';
import { UserAddOutlined } from '@ant-design/icons';
import { set } from 'immutable';

const UserModal = ({user, setUser, refresh, onAdd}) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [actualUser] = useContext(UserContext);


    useEffect(() => {
        form.resetFields();
        if (user?.editing) {
            form.setFieldsValue({
                firstName: user.firstName,
                lastName: user.lastName,
                gender: user.gender,
                birthday: moment(user.birthday?.iso).format('DD/MM/YYYY'),
                phoneNumber: user.phoneNumber,
                email: user.email,
                role: user.role,
                username: user.username,
            });
        }
    }, [user]);

    useEffect(() => {
        if (actualUser) {
            const role = actualUser?.get('role');
            if (role === 'Auditor' || role === 'RRHH') {
                setRoleList([
                    {
                        label: 'Médico',
                        value: 'Specialist physician'
                    },
                    {
                        label: 'Médico de guardia',
                        value: 'Employed physician'
                    },
                    {
                        label: 'Médico radiólogo',
                        value: 'Radiology physician'
                    },
                    {
                        label: 'Técnico radiólogo',
                        value: 'Radiologist'
                    },
                    {
                        label: 'Administrativo',
                        value: 'Administrative'
                    },
                    {
                        label: 'Auditor',
                        value: 'Auditor'
                    },
                    {
                        label: 'Convenios',
                        value: 'Insurance'
                    }
                ])
            } else if (role === 'Administrative' || role === 'Radiologist') {
                setRoleList([
                    {
                        label: 'Médico',
                        value: 'Specialist physician'
                    }
                ])
            } 
            if (role === 'System administrator'  || role === 'BOFH') {
                setRoleList([
                    {
                        label: 'Médico',
                        value: 'Specialist physician'
                    },
                    {
                        label: 'Médico de guardia',
                        value: 'Employed physician'
                    },
                    {
                        label: 'Médico radiólogo',
                        value: 'Radiology physician'
                    },
                    {
                        label: 'Técnico radiólogo',
                        value: 'Radiologist'
                    },
                    {
                        label: 'Administrativo',
                        value: 'Administrative'
                    },
                    {
                        label: 'Auditor',
                        value: 'Auditor'
                    },
                    {
                        label: 'Recursos Humanos',
                        value: 'RRHH'
                    },
                    {
                        label: 'Convenios',
                        value: 'Insurance'
                    },
                    {
                        label: 'Administrador del sistema',
                        value: 'System administrator'
                    }
                ])
            }
        }
    }, [actualUser]);

    const onFinish = async (values) => {
        form.validateFields().then(async () => {  
            console.log('values', values);    
            try{
                const userInfo = {
                    ...user,
                    ...values,
                }
                setSubmitting(true);
                const userObject = await saveUser(userInfo);
                setUser(undefined);
                setSubmitting(false);
                onAdd && onAdd(userObject.toJSON());
                message.success('Usuario guardado correctamente');
                refresh();
            } catch (error) {
                setSubmitting(false);
                message.error('Error al guardar el usuario');
            }
        }).catch(() => {
            message.error('Por favor, complete los campos requeridos');
        })
    }

    const saveButton = (
        <Button 
            type="primary" 
            style={{ width: '100%' }} 
            onClick={() => form.submit()}
            // disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
            loading={submitting}
            >
                {user?.editing ? 'Guardar usuario' : 'Crear usuario'}
            </Button>
    );
    

 
    return (
        <Modal
        title={user?.editing ? 'Editar usuario' : 'Crear usuario'}
        open={user}
        footer={saveButton}
        onCancel={() => setUser(undefined)}
        >
        <Form
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            form={form}
            onFinish={onFinish}
        >
            <Form.Item label="Nombre" required name="firstName" rules={[{ required: true, message: 'Por favor, ingrese el nombre' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Apellido" required name="lastName" rules={[{ required: true, message: 'Por favor, ingrese el apellido' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Sexo" required name={'gender'} rules={[{ required: true, message: 'Por favor, seleccione el sexo' }]}>
            <Radio.Group>
                <Radio value={'Masculino'}>Masculino</Radio>
                <Radio value={'Femenino'}>Femenino</Radio>
            </Radio.Group>
            </Form.Item>
            <Form.Item label="Rol" key="role" name="role" rules={[{ required: true, message: 'Por favor, seleccione el rol' }]}>
            <Select style={{ width: '100%' }}>
                {roleList.map((role) => (
                    <Select.Option key={role.value} value={role.value}>{role.label}</Select.Option>
                ))}
            </Select>
            </Form.Item>
            <Form.Item label="Teléfono" key="phoneNumber" name="phoneNumber"
            // Set the pattern to expect 1 or 2 country numbers and then 10 digits
            rules={[{ required: true, pattern: /^[0-9]{1,2}[0-9]{10}$/, message: 'Por favor, ingrese un número de teléfono válido, incluyendo el código de país' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Correo" key="email" name="email"
            // Set the pattern to expect an email
            rules={[{ required: true, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: 'Por favor, ingrese un correo válido' }]}>
            <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Nombre de usuario" key="username" name="username" rules={[{ required: true, message: 'Por favor, ingrese el nombre de usuario' }]}>
            <Input style={{ width: '100%' }} disabled={user?.objectId} />
            </Form.Item>
            {!user?.objectId && <Form.Item label="Contraseña" key="password" name="password" rules={[{ required: true, message: 'Por favor, ingrese la contraseña' }]}>
            <Input.Password style={{ width: '100%' }} />
            </Form.Item>}
           
        </Form>
        </Modal>
    );
}

export default UserModal;