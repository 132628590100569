import React, { useEffect, useState } from 'react';
import { Space, Card, Button, Typography, message, Tag, Row, Col, Modal, Select, Form, Checkbox } from 'antd';
import UserModal from '../sites/Users/components/UserModal';
import PatientModal from '../sites/Patients/components/PatientModal';
import moment from 'moment';
import {SearchInput} from './SearchInput';
import Parse from 'parse';
const { Title, Text } = Typography;

const ModalStudyInfo = ({study, open, setOpen, refresh}) => {
    const [patient, setPatient] = useState(null);
    const [studyDescription, setStudyDescription] = useState(null);
    const [referrer, setReferrer] = useState(null);
    const [radiologist, setRadiologist] = useState(null);
    const [addUser, setAddUser] = React.useState(false);
    const [addPatient, setAddPatient] = React.useState(false);
    const [protocols, setProtocols] = useState([]);
    const [specialScheduled, setSpecialScheduled] = useState(false);
    const formRef = React.createRef();

    const fetchPatients = async (search) => {
        const query = new Parse.Query('Patients');
        query.matches('fullName', search, 'i');
        const results = await query.find();
        return results.map(result => result.toJSON());
    }

    const fetchStudies = async (search) => {
        const StudyDescriptions = Parse.Object.extend('StudyDescriptions');
        const query = new Parse.Query(StudyDescriptions);
        query.matches('description', search, 'i');
        const results = await query.find();
        return results.map(result => result.toJSON());
    }

    const fetchReferrers = async (fullName) => {
        console.log('Fetching referrers', fullName)
        const params = { fullName: fullName, role: ['Employed physician', 'Specialist physician'] };
        const results = await Parse.Cloud.run('queryUsers', params);
        return results;
    }

    const fetchRadiologists = async (fullName) => {
        console.log('Fetching radiologists', fullName)
        const params = { fullName: fullName, role: ['Radiologist', 'Radiology physician'] };
        const results = await Parse.Cloud.run('queryUsers', params);
        return results;
    }

    const handlePatientChange = (value, option) => {
        formRef.current?.setFieldsValue({patient: option});
        setPatient(option);
    }

    const handleStudyChange = (value, option) => {
        formRef.current?.setFieldsValue({studyDescription: option});
        console.log('Study change', value, option)
        setStudyDescription(option);
    }

    const handleReferrerChange = (value, option) => {
        formRef.current?.setFieldsValue({referrer: option});
        setReferrer(option);
    }

    const handleRadiologistChange = (value, option) => {
        formRef.current?.setFieldsValue({radiologist: option});
        console.log('Radiologist change', value, option)
        setRadiologist(option);
    }

    const saveStudy = () => {

        console.log('Patient', patient.objectId, 'Study', studyDescription.objectId, 'Referrer', referrer.objectId, 'Radiologist', radiologist.objectId, 'Protocols', protocols, 'Modality', studyDescription.modality.id)
        const patientPointer = {
            __type: 'Pointer',
            className: 'Patients',
            objectId: patient.objectId
        }

        const studyDescriptionPointer = {
            __type: 'Pointer',
            className: 'StudyDescriptions',
            objectId: studyDescription.objectId
        }
        
        const referrerPointer = {
            __type: 'Pointer',
            className: '_User',
            objectId: referrer.objectId
        }

        const radiologistPointer = {
            __type: 'Pointer',
            className: '_User',
            objectId: radiologist.objectId
        }

        const modalityPointer = {
            __type: 'Pointer',
            className: 'Modalities',
            objectId: studyDescription.modality.id || studyDescription.modality.objectId
        }

        const Study = Parse.Object.extend('Studies');
        const updateStudy = new Study();
        updateStudy.set('objectId', study.objectId);
        updateStudy.set('patient', patientPointer);
        updateStudy.set('study', studyDescriptionPointer);
        updateStudy.set('referrer', referrerPointer);
        updateStudy.set('radiologist', radiologistPointer);
        updateStudy.set('modality', modalityPointer);
        updateStudy.set('protocols', protocols);
        updateStudy.set('specialScheduled', specialScheduled);
        updateStudy.set('description', studyDescription.description);
        updateStudy.save().then(() => {

            const studyPointer = {
                __type: 'Pointer',
                className: 'Studies',
                objectId: updateStudy.id
            }

            console.log('Study pointer', studyPointer)

            const Events =  Parse.Object.extend('Events');
            const event = new Events();
            event.set('user', Parse.User.current());
            event.set('type', 'Study information completed');
            event.set('study', studyPointer);
            event.save().then(() => {
                message.success('Estudio actualizado correctamente');
                refresh();
                setOpen(false);
            }).catch((error) => {
                message.error('Error al actualizar el estudio');
            });
        }
        ).catch((error) => {
            message.error('Error al actualizar el estudio');
        });
    }

    useEffect(() => {
        console.log('Study effetc', study)
        setPatient({value: study?.patient?.objectId, label: study?.patient?.fullName, ...study?.patient});
        setReferrer({value: study?.referrer?.objectId, label: study?.referrer?.fullName, ...study?.referrer});
        setStudyDescription({value: study?.study?.objectId, label: study?.study?.description, ...study?.study});
        setRadiologist({value: study?.radiologist?.objectId, label: study?.radiologist?.fullName, ...study?.radiologist});
        setProtocols(study?.protocols);
        setSpecialScheduled(study?.specialScheduled);

        formRef.current?.setFieldsValue({
            patient: {value: study?.patient?.objectId, label: study?.patient?.fullName, ...study?.patient},
            studyDescription: {value: study?.study?.objectId, label: study?.study?.description, ...study?.study},
            referrer: {value: study?.referrer?.objectId, label: study?.referrer?.fullName, ...study?.referrer},
            radiologist: {value: study?.radiologist?.objectId, label: study?.radiologist?.fullName, ...study?.radiologist},
            protocols: study?.protocols,
            specialScheduled: study?.specialScheduled
        })

    }, [study, open])

    console.log('Study', patient, studyDescription, referrer, radiologist, protocols)

    console.log('Addpatient', addPatient, addUser)

    return (
        <Modal 
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={saveStudy}>
                    Guardar
                </Button>,
            ]}
            width={800}
        >
             <PatientModal patient={addPatient} setPatient={setAddPatient} onSave={(patient) => {
          console.log('On save', patient);
          formRef.current.setFieldsValue({
            patient: {
              value: patient.fullName,
              ...patient
            }
        });
            setAddPatient(false);
          }} />
          

          <UserModal user={addUser} setUser={setAddUser} onAdd={(user) => {
            formRef.current.setFieldsValue({
              clinician: {
                value: user.fullName,
                ...user
              }
            });
            setAddUser(false);
          }} />

            <Space direction='vertical' style={{padding: 20, width: '100%'}} >
                <Title level={2}>Información del estudio</Title>
                <Form 
                    ref={formRef}
                    layout='vertical'
                    >
                    <Form.Item 
                        label='Paciente'
                        name='patient'
                        rules={[{ required: true, message: 'Por favor selecciona un paciente' }]}
                    >
                        <SearchInput placeholder='Buscar paciente' fetchOptions={fetchPatients} onChange={handlePatientChange} text={patient} label='fullName' onAdd={() => setAddPatient(true)} />
                    </Form.Item>
                    <Form.Item 
                        name='studyDescription'
                        label='Descripción del estudio'
                        rules={[{ required: true, message: 'Por favor selecciona un estudio' }]}
                    >
                        <SearchInput placeholder='Buscar estudio' fetchOptions={fetchStudies} onChange={handleStudyChange} label='description' text={studyDescription} />
                    </Form.Item>
                    <Form.Item 
                        name='protocols'
                        label='Protocolos'
                        rules={[{ required: true, message: 'Por favor selecciona al menos un protocolo' }]}
                    >
                        {study && <Select
                        value={protocols}
                        style={{ width: '100%' }}
                        mode="tags"
                        placeholder="Seleccionar protocolos"
                        onChange={value => setProtocols(value)}
                        options={studyDescription?.protocols?.map(protocol => ({value: protocol.name, label: protocol.name}))}
                    />}
                    </Form.Item>
                    <Form.Item 
                        label='Referidor'
                        name='referrer'
                        rules={[{ required: true, message: 'Por favor selecciona un referidor' }]}
                    >
                        <SearchInput placeholder='Buscar referidor' fetchOptions={fetchReferrers} onChange={handleReferrerChange} text={referrer} label='fullName' onAdd={() => setAddUser(true)} />
                    </Form.Item>
                    <Form.Item 
                        label='Operador'
                        name='radiologist'
                        rules={[{ required: true, message: 'Por favor selecciona un radiólogo' }]}
                    >
                        <SearchInput placeholder='Buscar radiólogo' fetchOptions={fetchRadiologists} onChange={handleRadiologistChange} text={radiologist} label='fullName' />
                    </Form.Item>
                    <Form.Item
                        name='specialScheduled'
                    >
                        <Checkbox onChange={
                            (e) => {
                                console.log('Special scheduled', e.target.checked)
                                setSpecialScheduled(e.target.checked)
                            }}
                        style={{marginBottom: 10}}
                        checked={specialScheduled}
                        >Estudio de guardia</Checkbox>
                    </Form.Item>
                    
                </Form>
            </Space>
        </Modal>
    )
}

export default ModalStudyInfo