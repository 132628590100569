import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, {useState, useContext, useEffect} from "react";
import { ThemeContext } from "../../../context";
import { Segmented, Space, Grid, Anchor, Select } from "antd";
import darkUnica from 'highcharts/themes/brand-dark';

const { useBreakpoint } = Grid;

const renderValues = (data, chartGroup, maxItems) => {
    if (!data) return [];
    return Object.values(data?.[chartGroup])?.map((item) => item).slice(0, maxItems)
}

const renderPercent = (data, chartGroup, maxItems, total) => {
    if (!data) return [];
    return Object.values(data?.[chartGroup])?.map((item) => Math.round((item/total*100) * 100) / 100).slice(0, maxItems)
}


const ProceduresChart = ({ data, chartGroup, setChartGroup, maxItems = 10 }) => {
    const screens = useBreakpoint();
    const { theme, setTheme } = useContext(ThemeContext);
    const [total, setTotal] = useState(0);
    const [type, setType] = useState('percent');
    console.log('Data: ', data);

   useEffect(() => {
    if (theme.algorithm === 'darkAlgorithm') {
        darkUnica(Highcharts);
    } else {
        Highcharts.theme = {};
        Highcharts.setOptions(Highcharts.theme);
    }
    }, [theme]);

    useEffect(() => {
        if (!data || Object.keys(data).length === 0) return;
        let total = 0;
        Object.values(data?.[chartGroup])?.map((item) => total += item);
        setTotal(total);
    }, [data, chartGroup, type]);

    if (!data) return null;
    if (Object.keys(data).length === 0) return null;
    const options = {
        credits: {
            enabled: false
          },        
        title: {
            text: 'Estudios'
        },
        series: 
            [{
                name: '',
                type: 'column',
                data:  type === 'values' ? renderValues(data, chartGroup, maxItems) : renderPercent(data, chartGroup, maxItems, total),
            }],
            tooltip: {
                valueSuffix: type === 'percent' ? '%' : '',
              },
        xAxis: {
            categories: Object.keys(data?.[chartGroup])?.map((item) => item).slice(0, maxItems),
        },
        yAxis: {
            title: {
                text: 'Número de estudios',
            },
            labels: {
                format: type === 'percent' ? '{value}%' : '{value}',
              }
        },
    };

    if (screens.xs) {
        return (
            <Space direction="vertical" style={{ width: '70vw', paddingLeft: 20 }}>
               <Segmented 
                options={[
                    { label: 'Radiólogos', value: 'radiologists', title: 'Estudios por radiólogo'},
                    { label: 'Interpretes', value: 'interpreters', title: 'Estudios por interprete'},
                    { label: 'Referidores', value: 'referrer', title: 'Estudios por referidor'},
                    { label: 'Modalidades', value: 'modalities', title: 'Estudios por modalidad'},
                    { label: 'Descripciones', value: 'descriptions', title: 'Estudios por descripción'},
                    { label: 'Convenios', value: 'clients', title: 'Estudios por convenio'},
                ]}
                defaultValue="radiologists"
                onChange={(value) => setChartGroup(value)}
                style={{ overflowX: 'scroll', width: '100%' }}
            />
            <Select 
                defaultValue="percent"
                style={{ width: 120 }}
                onChange={(value) => setType(value)}
            >
                <Select.Option value="percent">Porcentaje</Select.Option>
                <Select.Option value="values">Valores</Select.Option>
            </Select>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </Space>
        );
    }


    return (
        <Space direction="vertical" style={{ width: '80vw' }}>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
            <Segmented 
                options={[
                    { label: 'Radiólogos', value: 'radiologists', title: 'Estudios por radiólogo'},
                    { label: 'Interpretes', value: 'interpreters', title: 'Estudios por interprete'},
                    { label: 'Referidores', value: 'referrer', title: 'Estudios por referidor'},
                    { label: 'Modalidades', value: 'modalities', title: 'Estudios por modalidad'},
                    { label: 'Descripciones', value: 'descriptions', title: 'Estudios por descripción'},
                    { label: 'Convenios', value: 'clients', title: 'Estudios por convenio'},

                ]}
                defaultValue="radiologists"
                onChange={(value) => setChartGroup(value)}
                style={{ overflowX: 'scroll' }}
            />
             <Select 
                defaultValue="percent"
                style={{ width: 120 }}
                onChange={(value) => setType(value)}
            >
                <Select.Option value="percent">Porcentajes</Select.Option>
                <Select.Option value="values">Totales</Select.Option>
            </Select>
            </Space>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
        </Space>
    );
}

export { ProceduresChart };