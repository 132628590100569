import React, {useState, useEffect } from 'react';
import Parse from 'parse';
import { createContext } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const lookForCurrentUser = async () => {
        const currentUser = await Parse.User.currentAsync();
        if (currentUser) {
          // Save current user into local storage
          localStorage.setItem('user', JSON.stringify(currentUser));
          const token = localStorage.getItem('token');
        if (token) {
            console.log('Token:', token);
            const userId = currentUser.id;
            Parse.Cloud.run('addNotificationsToken', { token, userId });
        }
          setUser(currentUser);
        }
        
    }


    useEffect(() => {
      lookForCurrentUser()
    }, []);
    
    return (
        <UserContext.Provider value={[user, setUser]}>
          {children}
        </UserContext.Provider>
    );
    }