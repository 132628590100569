import Parse from 'parse';
import { message } from 'antd';

const fetchOrder = async (id, setOrder, setLoading) => {
    console.log('Fetching order');
  try {
    const params = { orderId: id };
    const order = await Parse.Cloud.run('getOrder', params);
    console.log('Orderrr', order);
    setOrder(order);
  } catch (error) {
    message.error('Error fetching order');
  } finally {
    setLoading(false);
    console.log('Order fetched');
  }
};

export default fetchOrder;