import React, { useEffect, useState } from 'react';
import { Space, Card, Button, Typography, Divider, Tag, Row, Col, Table, Skeleton } from 'antd';
import Parse from 'parse';
import moment from 'moment';
const { useNavigate } = require('react-router-dom');

const { Title, Text } = Typography;
const AccessInfo = ({study, noTitle}) => {
    const [accesses, setAccesses] = useState([]); 
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAccesses = async () => {
            const params = { studyId: study?.objectId };
            let accesses = await Parse.Cloud.run('getAccesses', params);
            accesses = accesses.filter((access, index, self) =>
                index === self.findIndex((t) => (
                    t.session === access.session
                ))
            )
            setAccesses(accesses);
            setLoading(false);
            console.log('Fetch accesses')
        }

        fetchAccesses();
    }, [study])

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [])

    const parseType = (type) => {
        let message = ''
        switch(type) {
            case 'Physical':
                message = 'Físico'
                break;
            case 'Email':
                message = 'Correo electrónico'
                break;
            case 'WhatsApp':
                message = 'WhatsApp'
                break;
            default:
                message = 'Evento desconocido'
        }
        return message
    }

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('LL [a las] HH:mm')
        },
        {
            title: 'Usuario',
            dataIndex: 'user',
            key: 'user',
            render: (user) => user ? user.fullName : 'Usuario desconocido'
        }, 
        {
            title: 'Rol',
            dataIndex: 'user',
            key: 'role',
            render: (user) => user ? parseRole(user.role) : 'Rol desconocido'
        },
        {
            title: 'Ubicación',
            dataIndex: 'location',
            key: 'location',
            render: (location) => parseLocation(location)
        }
    ]

    const parseRole = (role) => {
        let message = ''
        switch(role) {
            case 'Employed physician':
                message = 'Médico de guardia'
                break;
            case 'Radiology physician':
                message = 'Médico radiólogo'
                break;
            case 'Specialist physician':
                message = 'Médico especialista'
                break;
            case 'Administrative':
                message = 'Administrativo'
                break;
            case 'Radiologist':
                message = 'Técnico radiólogo'
                break;
            case 'Auditor':
                message = 'Auditor'
                break;
            case 'BOFH':
                message = 'BOFH'
                break;
            default:
                message = 'Rol desconocido'
        }
        return message
    }


    const parseLocation = (location) => {
        let message = ''
        switch(location) {
            case 'Internal':
                message = 'Interna'
                break;
            case 'External':
                message = 'Externa'
                break;
            default:
                message = 'Ubicación desconocida'
        }
        return message
    }

    if(noTitle)
        return (
            <Space direction='horizontal' style={{ width: '60'}} >
                {console.log('Accesses', accesses.length)}
                {!loading ? (
                    accesses ? (
                        <Row style={{width: '60vw'}}>
                           <Table dataSource={accesses} columns={columns} style={{width: '60vw'}} />
                    </Row>
                    ) : (
                        <Title level={3}>Nadie ha accedido a este estudio</Title>
                    )
                ) : (
                    <Skeleton active style={{ width: '50vw' }} />
                )}
            </Space>
        )
    return (
        <Space direction='horizontal' style={{padding: 20, width: '100%'}} >
            <Card title="Accesos" style={{ width: '100%' }} loading={loading}>
                {!loading ? 
                accesses.length > 0 ? (
                    <Row>
                       <Table dataSource={accesses} columns={columns} />
                </Row>
                ) : (
                    <Title level={3}>Nadie ha accedido a este estudio</Title>
                ) : <Skeleton active  style={{ width: '50vw' }} />}
                
            </Card>
        </Space>
    )
}

export default AccessInfo
