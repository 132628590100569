import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Space, Card, Typography, Statistic, Row, Col } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';

const DatabaseStatus = () => {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                // Set a timeout of 5 seconds to wait for the response
                let { data } = await axios.get(`${process.env.REACT_APP_PARSE_SERVER_URL}`, {timeout: 5000});
                console.log('Database status', data);
                setStatus(data);
            } catch (error) {
                console.log(error);
                if (error.code === "ERR_BAD_REQUEST") setStatus({error: false})
                else setStatus({error: true})
            }
        }

        fetchStatus();
    }, []);
    

    return (
        <Space direction='vertical' style={{padding: 20, width: '100%', height: '100%'}} >
                    <Card loading={!status} style={{ width: '100%' }}>
                    <Statistic
                        title="Base de datos"
                        value={!status?.error ? 'Activo' : 'Error'}
                        precision={2}
                        valueStyle={{
                            color: !status?.error ? '#3f8600' : '#cf1322',
                        }}
                        />
                    </Card>
        </Space>    
    )
}

export default DatabaseStatus 
