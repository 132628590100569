import React from "react";
import { Modal, Button, Form, Input, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Parse from "parse";


const ForgotPassword = ({ open, setOpen }) => {


const onFinish = async (values) => {
    try {
        await Parse.User.requestPasswordReset(values.email);
        message.success('Se ha enviado un correo para recuperar la contraseña');
        setOpen(false);
    } catch (error) {
        console.log(error);
        message.error('Error al enviar el correo');
    }
}

    return (    
        <Modal
            title="Restablecer contraseña"
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
        >
            <p>Ingrese su correo para restablecer la contraseña</p>
            <Form
                name="forgotPassword"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Por favor ingrese su correo' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Correo electrónico" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Restablecer contraseña
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ForgotPassword;
