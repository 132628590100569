import React, { useEffect } from "react";
import { fetchAllEvents } from "./utils";
import { Typography, Space, Col, Tag, Statistic, Card, Skeleton, DatePicker, Row, Checkbox, Spin } from "antd";
import { GeneralEventList, UserEventList } from "./components";
const { Title } = Typography;

const Timing = () => {
    const  [dates, setDates] = React.useState([]);
    const [data, setData] = React.useState({});
    const [showGeneralEventList, setShowGeneralEventList] = React.useState(false);
    const [showUserEventList, setShowUserEventList] = React.useState(false);
    const [eventsData, setEventsData] = React.useState([]);
    const [title, setTitle] = React.useState("");
    const [onlySameDay, setOnlySameDay] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    useEffect(() => {
        const fetchEvents = async () => {
            setLoading(true);
            let results = await fetchAllEvents(dates, onlySameDay);
            console.log("Events", results);
            let userTimes = results.userTimes;
            userTimes = userTimes.sort((a, b) => a.user.role.localeCompare(b.user.role));
            results.userTimes = userTimes;
            console.log("User Times", userTimes);
            setData(results);
            setLoading(false);
        };
        fetchEvents();
    }, [dates, onlySameDay]);

    if (!Object.keys(data).length) {
        return <Skeleton active />;
    }

    console.log('Dates: ', dates);

    const GenerateStatistic = ({time, title}) => {
        console.log('Time: ', time, title);
        if (isNaN(time)) {
            return <Statistic title={title} value={'Desconocido'}  />;
        }
        let value = 0;
        let suffix = "";

        if (time === 1) {
            value = time
            suffix = "minuto";
        }

        else if (time > 1440) {
            value = (time / 1440).toFixed(1);
            suffix = "días";
            if (value === 1) {
                suffix = "día";
            }
        }

        else if (time > 60) {
            value = (time / 60).toFixed(1);
            suffix = "horas";
            if (value === 1) {
                suffix = "hora";
            }
        }

        else {
            value = time.toFixed(1);
            suffix = "minutos";
        }

        return <Statistic title={title} value={value} suffix={suffix} />;
    };

    const renderRole = (role) => {
        if (role === 'Radiology physician') {
            return <Tag color="purple">Médico radiólogo</Tag>;
        }
        if (role === 'Administrative') {
            return <Tag color="blue">Administrativo</Tag>;
        }
        if (role === 'Radiologist') {
            return <Tag color="green">Radiólogo</Tag>;
        }
        return <Tag color="gray">{role}</Tag>;
    }

    const roleBasedFields = (role) => {
        const fields = []
        if (role === 'Radiology physician') {
            fields.push('studyMade');
            fields.push('interpretationCreated');
        }
        if (role === 'Radiologist') {
            fields.push('studyMade');
            fields.push('studyDelivered');
            fields.push('studyInformationCompleted');
        }
        if (role === 'Administrative') {
            fields.push('studyDelivered');
            fields.push('studyInformationCompleted');
        }
        return fields;
    }


    const renderUserCard = (data) =>  
    {
        if (!data.user) {
            return null;
        }
        if (data.user.role === 'BOFH' || data.user.role === 'Bot') {
            return null;
        }

        const fields = roleBasedFields(data.user.role);

        return <Col span={6} style={{ marginBottom: "20px" }}>
        <Card title={data.user.fullName} style={{ width: 300 }} onClick={() => {
        setEventsData([...data.studyMade.events, ...data.studyDelivered.events, ...data.studyInformationCompleted.events, ...data.interpretationCreated.events]);
        setTitle(data.user.fullName);
        setShowUserEventList(true);
    }}>
        <p style={{ fontSize: "12px", color: 'gray' }}>{renderRole(data.user.role)}</p>
        {fields.includes('studyMade') && <GenerateStatistic title="Espera del paciente" time={data.studyMade.average} />}
        {fields.includes('studyMade') && <p style={{ fontSize: "10px", color: 'gray' }}>Basado en {data.studyMade.num} eventos</p>}
        {fields.includes('studyDelivered') && <GenerateStatistic title="Entrega del estudio" time={data.studyDelivered.average} />}
        {fields.includes('studyDelivered') && <p style={{ fontSize: "10px", color: 'gray' }}>Basado en {data.studyDelivered.num} eventos</p>}
        {fields.includes('studyInformationCompleted') && <GenerateStatistic title="Corrección de información" time={data.studyInformationCompleted.average} />}
        {fields.includes('studyInformationCompleted') && <p style={{ fontSize: "10px", color: 'gray' }}>Basado en {data.studyInformationCompleted.num} eventos</p>}
        {fields.includes('interpretationCreated') && <GenerateStatistic title="Interpretación" time={data.interpretationCreated.average} />}
        {fields.includes('interpretationCreated') && <p style={{ fontSize: "10px", color: 'gray' }}>Basado en {data.interpretationCreated.num} eventos</p>}
    </Card>
    </Col>
    }


   
    return (
        <div style={{ padding: "20px" }}>
            <GeneralEventList open={showGeneralEventList} setOpen={setShowGeneralEventList} data={eventsData} title={title} />
            <UserEventList open={showUserEventList} setOpen={setShowUserEventList} data={eventsData} title={title} />
            <Space direction="horizontal" size="large" style={{ marginBottom: "20px" }}>
                <Title level={2}>Tiempos</Title>
                <DatePicker.RangePicker onChange={(dates) => setDates(dates)} />
                <Checkbox onChange={(e) => setOnlySameDay(e.target.checked)} checked={onlySameDay}>Sólo eventos del mismo día</Checkbox>
                {loading && <Spin />}
            </Space>
            <Space direction="horizontal" size="large">
                <Card title="Espera del paciente" style={{ width: 300 }} onClick={() => {
                    setEventsData(data.studyMade.events);
                    setTitle("Espera del paciente");
                    setShowGeneralEventList(true);
                }}>
                    <p style={{ fontSize: "12px", color: 'gray' }}>Desde la creación de la orden</p>
                    <GenerateStatistic title="Mínimo" time={data.studyMade.min} />
                    <GenerateStatistic title="Promedio" time={data.studyMade.average} />
                    <GenerateStatistic title="Máximo" time={data.studyMade.max} />
                    <p style={{ fontSize: "12px", color: 'gray' }}>Basado en {data.studyMade.num} eventos</p>
                </Card>

                <Card title="Entrega del estudio" style={{ width: 300 }} onClick={() => {
                    setEventsData(data.studyDelivered.events);
                    setTitle("Entrega del estudio");
                    setShowGeneralEventList(true);
                }}>
                    <p style={{ fontSize: "12px", color: 'gray' }}>Desde la recepción de las imágenes</p>
                    <GenerateStatistic title="Mínimo" time={data.studyDelivered.min} />
                    <GenerateStatistic title="Promedio" time={data.studyDelivered.average} />
                    <GenerateStatistic title="Máximo" time={data.studyDelivered.max} />
                    <p style={{ fontSize: "12px", color: 'gray' }}>Basado en {data.studyDelivered.num} eventos</p>
                </Card>

                <Card title="Corrección de información" style={{ width: 300 }} onClick={() => {
                    setEventsData(data.studyInformationCompleted.events);
                    setTitle("Corrección de información");
                    setShowGeneralEventList(true);
                }}>
                    <p style={{ fontSize: "12px", color: 'gray' }}>Desde la recepción de las imágenes</p>
                    <GenerateStatistic title="Mínimo" time={data.studyInformationCompleted.min} />
                    <GenerateStatistic title="Promedio" time={data.studyInformationCompleted.average} />
                    <GenerateStatistic title="Máximo" time={data.studyInformationCompleted.max} />
                    <p style={{ fontSize: "12px", color: 'gray' }}>Basado en {data.studyInformationCompleted.num} eventos</p>
                </Card>
                
                <Card title="Interpretación" style={{ width: 300 }} onClick={() => {
                    setEventsData(data.interpretationCreated.events);
                    setTitle("Interpretación");
                    setShowGeneralEventList(true);
                }}>
                    <p style={{ fontSize: "12px", color: 'gray' }}>Desde la recepción de las imágenes</p>
                    <GenerateStatistic title="Mínimo" time={data.interpretationCreated.min} />
                    <GenerateStatistic title="Promedio" time={data.interpretationCreated.average} />
                    <GenerateStatistic title="Máximo" time={data.interpretationCreated.max} />
                    <p style={{ fontSize: "12px", color: 'gray' }}>Basado en {data.interpretationCreated.num} eventos</p>
                </Card>
            </Space>

            <Title level={3}>Usuarios</Title>
            <Row style={{ marginBottom: "20px" }} gutter={16}>
                {data.userTimes.sort((a, b) => a.user.role - b.user.role).map((user) => renderUserCard(user))}
            </Row>
        </div>
    );
}

export default Timing;