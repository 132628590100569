import moment from 'moment';
import Parse from 'parse';

const queryProps = ['fullName', 'email', 'phoneNumber'];
const  constructAccentedRegexPattern = (text)  => {
    // Map of characters to their possible accented versions
    const accentMap = {
      'a': '[aá]',
      'e': '[eé]',
      'i': '[ií]',
      'o': '[oó]',
      'u': '[uú]',
      'A': '[AÁ]',
      'E': '[EÉ]',
      'I': '[IÍ]',
      'O': '[OÓ]',
      'U': '[UÚ]'
    };
  
    // Replace each character in the text with its regex pattern
    return text.split('').map(char => accentMap[char] || char).join('');
  }



const query = async (value) => {
   // Count the number of studies for each patient
    return new Promise(async (resolve, reject) => {
        try {
            const Patients = Parse.Object.extend('Patients');
            const query = new Parse.Query(Patients);
            if (value) {
                let regValue = value.split(' ').join('.*');  
                regValue = constructAccentedRegexPattern(regValue);
                const queryObjects = []
                queryProps.forEach(prop => {
                    const q = new Parse.Query('Studies');
                    q.matches(prop, regValue, 'i');
                    queryObjects.push(q);
                });
                query._orQuery(queryObjects);
            }
            const patients = await query.find();
            const patientsWithStudies = await Promise.all(patients.map(async (patient) => {
                const Studies = Parse.Object.extend('Studies');
                const query = new Parse.Query(Studies);
                query.equalTo('patient', patient);
                const studies = await query.find();
                return {
                    ...patient.toJSON(),
                    studies: studies.length
                }
            }));
            resolve(patientsWithStudies);
        }
        catch (error) {
            console.error('Error while fetching patients: ', error);
            reject(error);
        }
    });
}

const savePatient = async (patient) => {
    return new Promise(async (resolve, reject) => {
        console.log(patient.birthday);
        try {
            const Patients = Parse.Object.extend('Patients');
            const newPatient = new Patients();
            patient.objectId && newPatient.set('objectId', patient.objectId);
            newPatient.set('firstName', patient.firstName?.trim());
            newPatient.set('lastName', patient.lastName?.trim());
            newPatient.set('fullName', `${patient.firstName?.trim()} ${patient.lastName?.trim()}`);
            newPatient.set('birthday', moment(patient.birthday, 'DD/MM/YYYY').add(12, 'hours').toDate());
            newPatient.set('gender', patient.gender);
            newPatient.set('phoneNumber', patient.phoneNumber);
            newPatient.set('email', patient.email?.trim()?.toLowerCase());
            const info = await newPatient.save();
            resolve(info.toJSON());
        }
        catch (error) {
            console.error('Error while saving patient: ', error);
            reject(error);
        }
    }
    );
}
        

  export { query, savePatient };