import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Card, Space, Table, Tag, message, FloatButton, Grid } from 'antd';
import Parse from 'parse';
import StudyConfigModal from './components/StudyConfigModal';
import Search from 'antd/lib/input/Search';
const { useBreakpoint } = Grid;

const StudySettings = () => {
    const [studies, setStudies] = useState([]);
    const [study, setStudy] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [value, setValue] = useState();
    const [searching, setSearching] = useState(false);
    const screens = useBreakpoint();

    console.log(studies, study, open);

    useEffect(() => {
        const fetchStudies = async () => {
            setSearching(true);
            const query = new Parse.Query('StudyDescriptions');
            value && query.matches('description', value, 'i');
            const results = await query.find();
            let studies = results.map(study => study.toJSON());
            studies.protocols = studies?.protocols?.sort((a, b) => a.name.localeCompare(b.name));
            setStudies(studies);
            setSearching(false);
        }

        fetchStudies();
    }, [refresh, value]);

    const columns = [
            {
                title: 'Estudio',
                dataIndex: 'description',
                key: 'description',
                render: (name, record) => <a onClick={() => {
                    setOpen(true)
                    setStudy({...record, editing: true})
                }}>{name}</a>,
                sorter: (a, b) => a.description.localeCompare(b.description)
            },
            {
                title: 'Proyecciones',
                dataIndex: 'protocols',
                key: 'protocols',
                render: (protocols) => protocols?.map(protocol => <Tag key={protocol} color="blue">{protocol.name}</Tag>)
            }]

const StudiesCards = () => {
    return (
        <Space direction="vertical" style={{ width: '100%', padding: '20px' }}>
           {studies.map(study => (
                <Card title={study.description} key={study.objectId} onClick={() => {
                    setStudy({...study, editing: true})
                    setOpen(true)
                }}>
                     {study.protocols.map(protocol => (
                        <Tag key={protocol.objectId} color="blue">{protocol.name}</Tag>
                    ))}
                </Card>
              ))}
        </Space>
    )
}

if (screens.xs) {
    return (
            <Space direction="vertical" style={{ width: '100%', padding: '20px' }}>
                <StudyConfigModal study={study} onCancel={() => {
                    setStudy(undefined)
                    setOpen(false)
                }} open={open} setStudies={setStudies} />
                <h1>Configuración de estudios</h1>
                <Search placeholder="Buscar estudio" onChange={(e) => setValue(e.target.value)} loading={searching} />
                <FloatButton type="primary" onClick={() => {
                    setStudy({editing: false})
                    setOpen(true)
                }}>Agregar modalidad</FloatButton>
                <StudiesCards />
            </Space>
    )
}
    return (
            <Space direction="vertical" style={{ width: '100%', padding: '20px' }}>
                <StudyConfigModal study={study} onCancel={() => {
                    setStudy(undefined)
                    setOpen(false)
                    setRefresh(refresh + 1)
                }} open={open} setStudies={setStudies} />
                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                    <h1>Configuración de estudios</h1>
                    <Search placeholder="Buscar estudio" onChange={(e) => setValue(e.target.value)} loading={searching} />
                </Space>
                <FloatButton type="primary" onClick={() => {
                    setStudy({editing: false})
                    setOpen(true)
                }}>Agregar modalidad</FloatButton>
                <Table dataSource={studies} columns={columns} loading={!studies.length} />
            </Space>
    )
}

export default StudySettings;