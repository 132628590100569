import React, { useEffect } from "react";
import { FloatButton,  Space, Table, Tag, Modal,  Card, Input, Select, Button, message, Grid } from "antd";
import PatientModal from './components/PatientModal';
import { useNavigate } from "react-router-dom";
import { UserAddOutlined } from "@ant-design/icons";
import {query } from './utils/query';
import moment from "moment";
import Title from "antd/es/typography/Title";
import { Filter } from "react-bootstrap-icons";
const { useBreakpoint } = Grid;

export default function Dashboard () {

    const [patients, setPatients] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [isSearching, setIsSearching] = React.useState(false);
    const [patient, setPatient] = React.useState(undefined);
    const [showFilters, setShowFilters] = React.useState(false);
    const screens = useBreakpoint();
    const navigate = useNavigate();

    let searchTimeout = null;

    const { Search } = Input;


    useEffect(() => {
        queryPatients();
    }, []);

    const queryPatients = async (value) => {
        const results = await query(value);
        console.log(results);
        setPatients(results);
        setIsSearching(false);
        setLoading(false);
    }
    

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'fullName',
        key: 'patient',
        render: (name, record) => <Button type="link" onClick={() => navigate(`/patient/${record.uuid}`)}>{name}</Button>
      },
      {
        title: 'Fecha de nacimiento',
        dataIndex: 'birthday',
        key: 'birthday',
        render: (date) => moment(date?.iso).format('DD/MM/YYYY')
      },
      {
        title: 'Sexo', 
        dataIndex: 'gender',
        key: 'gender',
      },
      {
        title: 'Teléfono',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: (phone) => phone?.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5')
      },
      {
        title: 'Correo electrónico',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Número de estudios',
        dataIndex: 'studies',
        key: 'studies',
      }
    ];

    const filters = () =>  <Search 
    placeholder="Buscar Paciente"
    onChange={(e) => {
      setIsSearching(true);
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        queryPatients(e.target.value);
      }, 300);
    }}
    style={{ width: 200, float: 'right' }}
    loading={isSearching}
    />

    const FiltersModal = () => <Modal
    title="Filtros"
    open={showFilters}
    onCancel={() => setShowFilters(false)}
    footer={[
      <Button key="submit" type="primary" onClick={() => setShowFilters(false)} >Ok</Button>
    ]}
    >
      {filters()}
    </Modal>

    const PatientsList = () => <Space direction="vertical" style={{ paddingBottom: 20, width: '100%' }}>
      {patients.length > 0 && patients.map((patient) => (
        <Card key={patient.uuid} title={<Space direction="horizontal">{patient.fullName}</Space>} style={{ width: '100%' }} onClick={() => navigate(`/patient/${patient.uuid}`)} hoverable>
          <Space direction="vertical">
            <p>{moment(patient.birthday.iso).format('DD/MM/YYYY')}</p>
            <p>{patient.gender}</p>
            <p>{patient.phoneNumber}</p>
            <p>{patient.email}</p>
            <p>Número de estudios: {patient.studies}</p>
          </Space>
        </Card>
      ))}
    </Space>

    if (screens.xs) return  <Space direction="vertical" style={{ width: '100%', padding: 20 }}>
    <FloatButton icon={<UserAddOutlined />} onClick={() => setPatient({})} tooltip="Agregar paciente" type="primary" />
    <PatientModal patient={patient} setPatient={setPatient} refresh={queryPatients} />
    <FiltersModal />
      <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
        <Title level={3}>Pacientes</Title>
        <Filter style={{ fontSize: 40 }} onClick={() => setShowFilters(true)} />
      </Space>
      <PatientsList />
</Space>

    return (
        <Space direction="vertical" style={{ width: '100%', padding: 20 }}>
            <FloatButton icon={<UserAddOutlined />} onClick={() => setPatient({})} tooltip="Agregar paciente" type="primary" />
            <PatientModal patient={patient} setPatient={setPatient} refresh={queryPatients} />
              <Space size="large">
                <Title level={1}>Pacientes</Title>
                {filters()}
              </Space>
            <Table columns={columns} dataSource={patients} loading={loading} locale={{ emptyText: 'No hay Estudios'  }} />
        </Space>
    );
}