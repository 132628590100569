import React, {useState, useEffect } from 'react';
import Parse from 'parse';
import { createContext } from 'react';

export const ClinicConfigContext = createContext();

export const ClinicConfigProvider = ({ children }) => {
    const [clinicConfig, setConfig] = useState(null);

    const loadClinicConfig = async () => {
        console.log('Multiclinic enabled', process.env.REACT_APP_MULTICLINIC);
        if (!process.env.REACTY_APP_MULTICLINIC === 'true') {
            console.log('Multiclinic is disabled');
            return;
        }

        const currentUser = Parse.User.current()
        await currentUser?.fetch();
        console.log('Current user', currentUser?.toJSON());
        if (!currentUser) return;
        const clinicId = currentUser.get('clinic')?.id;
        if (!clinicId) {
            console.log('No clinic id found');
            setConfig({});
            return;
        }
        console.log('Multiclinic id', clinicId);

        const clinicPointer = {
            __type: 'Pointer',
            className: 'Clinics',
            objectId: clinicId
        }
        const ClinicConfig = Parse.Object.extend('Config');
        const query = new Parse.Query(ClinicConfig);
        query.equalTo('clinic', clinicPointer);
        const clinicConfig = await query.first();
        console.log('Multiclinic config', clinicConfig);
        if (!clinicConfig) {
            console.log('No clinic config found');
            setConfig({});
            return;
        }
        setConfig(clinicConfig.toJSON());
    }

    useEffect(() => {
        loadClinicConfig();
    }, []);
    
    return (
        <ClinicConfigContext.Provider value={{clinicConfig, setConfig, loadClinicConfig}}>
          {children}
        </ClinicConfigContext.Provider>
    );
    }