import React from 'react';

const TermsAndConditions = () => {
    return (
        <div style={{ padding: '20px', backgroundColor: '#4287f5', color: 'white', borderRadius: '10px', margin: '10px' }}>
            <h1>Términos y Condiciones de ImageLink</h1>
            <p>
                Bienvenido a ImageLink, una aplicación web de almacenamiento de imágenes médicas, desarrollada y operada por devTek, propiedad de Héctor Emanuel Martín Alcalá. Al utilizar el software, usted acepta los siguientes términos y condiciones:
            </p>

            <h2>1. Aceptación de los Términos</h2>
            <p>
                Al acceder y utilizar ImageLink, usted acepta cumplir con estos términos y condiciones, así como con cualquier ley, normativa o directiva aplicable. Si no está de acuerdo con estos términos, no deberá utilizar la aplicación.
            </p>

            <h2>2. Descripción del Servicio</h2>
            <p>
                ImageLink proporciona una plataforma segura para el almacenamiento, gestión y compartición de imágenes médicas. Los usuarios pueden subir, almacenar y acceder a imágenes médicas a través de la aplicación. El servicio se ofrece "tal cual" y "según disponibilidad".
            </p>

            <h2>3. Uso Aceptable</h2>
            <ul>
                <li>
                    <strong>Registro:</strong> Para utilizar ImageLink, usted debe registrarse proporcionando información veraz y actualizada. Usted es responsable de mantener la confidencialidad de sus credenciales de acceso y de todas las actividades que ocurran bajo su cuenta.
                </li>
                <li>
                    <strong>Uso Permitido:</strong> Solo puede utilizar ImageLink para fines legales y de acuerdo con la legislación aplicable. Está prohibido utilizar la plataforma para almacenar o distribuir contenido ilegal, ofensivo o que infrinja derechos de terceros. No puede utilizar el servicio para:
                    <ul>
                        <li>Distribuir virus, malware u otro software dañino.</li>
                        <li>Violar la privacidad de otros usuarios.</li>
                        <li>Realizar actividades fraudulentas o engañosas.</li>
                    </ul>
                </li>
                <li>
                    <strong>Confidencialidad:</strong> Usted debe mantener la confidencialidad de sus credenciales de acceso y no compartirlas con terceros. Usted es responsable de todas las actividades que se realicen desde su cuenta.
                </li>
            </ul>

            <h2>4. Propiedad Intelectual</h2>
            <p>
                Todos los derechos de propiedad intelectual relacionados con ImageLink, incluyendo pero no limitado a su software, diseño y marcas, son propiedad de devTek y Héctor Emanuel Martín Alcalá. Usted no adquiere ningún derecho de propiedad sobre el contenido accesible a través de ImageLink.
            </p>

            <h2>5. Contenido del Usuario</h2>
            <ul>
                <li>
                    <strong>Propiedad:</strong> Los estudios cargados y sus imágenes son propiedad del hospital que contrata y provee el servicio a través de ImageLink.
                </li>
                <li>
                    <strong>Licencia:</strong> Al subir contenido a ImageLink, usted otorga a devTek una licencia no exclusiva, mundial, libre de regalías y transferible para usar, reproducir, distribuir, preparar trabajos derivados y mostrar el contenido con el fin de operar el servicio.
                </li>
                <li>
                    <strong>Responsabilidad:</strong> Usted es el único responsable del contenido que suba y de las consecuencias de su publicación.
                </li>
            </ul>

            <h2>6. Protección de Datos</h2>
            <p>
                ImageLink cumple con las leyes y regulaciones aplicables en materia de protección de datos personales. Al utilizar la aplicación, usted consiente la recopilación y el uso de sus datos personales de acuerdo con nuestra política de privacidad, la cual se encuentra disponible en <a href="https://imagelink.devtek.mx/privacy" style={{ color: 'white', textDecoration: 'underline' }}>https://imagelink.devtek.mx/privacy</a>.
            </p>

            <h2>7. Seguridad</h2>
            <p>
                ImageLink implementa medidas de seguridad razonables para proteger su información. Sin embargo, no podemos garantizar la seguridad absoluta de los datos transmitidos a través de internet.
            </p>

            <h2>8. Limitación de Responsabilidad</h2>
            <ul>
                <li>
                    <strong>Disponibilidad:</strong> ImageLink se esfuerza por ofrecer un servicio ininterrumpido, pero no garantiza que la aplicación estará disponible en todo momento.
                </li>
                <li>
                    <strong>Exactitud de la Información:</strong> Aunque se procura que la información contenida en ImageLink sea precisa y actualizada, no se garantiza su exactitud o integridad.
                </li>
                <li>
                    <strong>Daños y Perjuicios:</strong> devTek y Héctor Emanuel Martín Alcalá no serán responsables por daños directos, indirectos, incidentales, consecuentes o punitivos derivados del uso o la imposibilidad de uso de ImageLink. Esto incluye, pero no se limita a, pérdida de datos, interrupción del negocio o beneficios perdidos.
                </li>
            </ul>

            <h2>9. Indemnización</h2>
            <p>
                Usted acepta indemnizar y mantener indemne a devTek, sus directores, empleados y agentes de cualquier reclamación, daño, obligación, pérdida, responsabilidad, costo o deuda y gastos (incluyendo pero no limitado a honorarios de abogados) derivados de:
            </p>
            <ul>
                <li>Su uso y acceso a ImageLink.</li>
                <li>Su violación de cualquier término de estos Términos y Condiciones.</li>
                <li>Su violación de cualquier derecho de terceros, incluyendo pero no limitado a cualquier derecho de autor, propiedad o privacidad.</li>
            </ul>

            <h2>10. Modificaciones de los Términos</h2>
            <p>
                devTek se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Las modificaciones serán efectivas a partir de su publicación en la plataforma. El uso continuado de ImageLink tras la publicación de cambios implica la aceptación de dichos cambios.
            </p>

            <h2>11. Terminación</h2>
            <p>
                devTek puede suspender o terminar su acceso a ImageLink en cualquier momento, sin previo aviso, si usted incumple estos términos y condiciones. También puede dejar de utilizar el servicio en cualquier momento.
            </p>

            <h2>12. Ley Aplicable</h2>
            <p>
                Estos términos y condiciones se rigen por las leyes del estado de Jalisco, México. Cualquier disputa relacionada con estos términos se someterá a la jurisdicción exclusiva de los tribunales de la ciudad de Tepatitlán de Morelos, Jalisco, México.
            </p>

            <h2>13. Resolución de Disputas</h2>
            <p>
                Cualquier disputa o reclamación que surja de o en conexión con estos términos y condiciones se resolverá mediante arbitraje vinculante de acuerdo con las reglas del Centro de Arbitraje de México (CAM), y se llevará a cabo en Guadalajara, Jalisco, México.
            </p>

            <h2>14. Miscelánea</h2>
            <ul>
                <li>
                    <strong>Integridad del Acuerdo:</strong> Estos términos y condiciones constituyen el acuerdo completo entre usted y devTek en relación con ImageLink y reemplazan cualquier acuerdo previo o contemporáneo, ya sea oral o escrito.
                </li>
                <li>
                    <strong>Divisibilidad:</strong> Si alguna disposición de estos términos se considera inválida o inaplicable, las demás disposiciones seguirán en plena vigencia.
                </li>
                <li>
                    <strong>No Renuncia:</strong> El hecho de que devTek no ejerza algún derecho o disposición de estos términos no constituirá una renuncia a dicho derecho o disposición.
                </li>
            </ul>

            <h2>15. Contacto</h2>
            <p>
                Para cualquier consulta o reclamación relacionada con ImageLink, puede contactar a devTek a través de <a href="mailto:soporte@devtek.mx" style={{ color: 'white', textDecoration: 'underline' }}>soporte@devtek.mx</a>.
            </p>

            <p><strong>Última actualización:</strong> 18/03/2024</p>
            <footer style={{ marginTop: '20px', textAlign: 'center', fontSize: '14px' }}>
        pixOS® 2024 Todos los derechos reservados
      </footer>
        </div>
    );
}

export default TermsAndConditions;
