import React from 'react';
import { Card, Typography, Space, Grid } from 'antd';
import moment from 'moment';

const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

const OrderInfo = ({ order, loading }) => {
  const screens = useBreakpoint();

  return (
    <Card loading={loading} title={<Title level={screens.xs ? 4 : 3}>Orden</Title>} style={{ width: screens.xs ? '50vw' : '30vw', height: screens.xs ? '20vh' : '25vh' }}>
      <Space direction="vertical">
        <Text>Número: {order?.orderID}</Text>
        <Text>Fecha de creación: {moment(order?.createdAt).format('DD/MM/YYYY')}</Text>
        <Text>Regristrada por: {order?.createdBy?.fullName}</Text>
      </Space>
    </Card>
  );
};

export default OrderInfo;