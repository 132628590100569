import React, { useEffect, useState, useContext } from 'react';
import { Space, Button, Table, Divider, Typography, Grid, Popover, message, FloatButton, Tag, Spin } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { SendModal, OrderInfo, PatientInfo, ReferrerInfo, StudiesList, DataSheets } from './components';
import { fetchOrder, cancelStudy } from './utils';
import moment from 'moment';
import { UserContext } from '../../context';
import { Eye, QrCode, Send, ThreeDots } from 'react-bootstrap-icons';
import OrderNotes from './components/OrderNotes';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const openStudies = (order) => {
  console.log('Order: ', order);
  let studiesIds = order?.studies?.map((study) => study.status === 'Recibiendo imágenes' || study.status === 'Imágenes recibidas'  || study.status === 'Entregado' ? study.instanceUUID : undefined);
  studiesIds = studiesIds.filter((item) => item !== undefined);
  studiesIds = studiesIds.join(',');
  console.log('Studies IDs: ', studiesIds);
  window.open(`${process.env.REACT_APP_VIEWER_URL}/viewer?StudyInstanceUIDs=${studiesIds}`, '_blank');
}

const imagesAvailable = (order) => {
  console.log('Order: ', order);
  const status = order?.studies?.some((study) => study.status === 'Recibiendo imágenes' || study.status === 'Imágenes recibidas' || study.status === 'Entregado');
  console.log('Status: ', status);
  return status;
}

const Order = () => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [dataSheets, setDataSheets] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const { id } = useParams();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const navLocation = useLocation();
  const [user] = useContext(UserContext);

  useEffect(() => {
      if (!navLocation.state == null) navigate('/login', { state: { route: `/order/${id}` } });
      if (user && !navLocation?.state) navigate('/login', { state: { route: `/order/${id}` } });
    fetchOrder(id, setOrder, setLoading);
  }, [id, user, navLocation, refresh]);

  const renderTag = (tag) => {
    switch (tag) {
      case 'Pendiente':
        return <Tag>{tag}</Tag>;
      case 'Enviado a modalidad':
        return <Tag color="orange">{tag}</Tag>;
      case 'Recibiendo imágenes':
        return <Tag color="purple">{tag}{<Spin size="small" style={{ paddingLeft: 5}} />}</Tag>;
      case 'Imágenes recibidas':
        return <Tag color="blue">{tag}</Tag>;
      case 'Entregado':
        return <Tag color="green">{tag}</Tag>;
      default:
        return <Tag color="red">{tag}</Tag>;
    }
  }

  const columns = [
    {
      title: 'ID de estudio',
      dataIndex: 'studyID',
      key: 'studyID',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => renderTag(status),
    },
    {
      title: 'Estudio',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Fecha de estudio',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD/MM/YYYY'),
    },
    {
      title: 'Modalidad',
      dataIndex: 'modality',
      key: 'modality',
      render: (modality) => modality?.commonName,
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Space direction="horizontal">
          {record.status !== 'Pendiente' && record.status !== 'Enviado a modalidad' ? (
            <Button type="link" onClick={() => navigate(`/study/${record.internalUUID}`, { state: { fromMenu: true } })}>Ver estudio</Button>
          ) : (
            <Popover
              key={record.objectId}
              trigger="click"
              content={
                <Space direction="horizontal">
                  <Text onClick={() => cancelStudy(record, setOrder, message)} style={{ color: 'red', cursor: 'pointer' }}>Sí, cancelar</Text>
                  <Text onClick={() => setCanceling(false)} style={{ color: 'blue', cursor: 'pointer' }}>No, regresar</Text>
                </Space>
              }
              title="¿Quieres cancelar este estudio?"
            >
              <Button  style={{ color: 'red', cursor: 'pointer' }}>Cancelar</Button>
            </Popover>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%', padding: 20 }}>
      <SendModal open={sending} setOpen={setSending} study={order?.studies?.[0]} order={order} setOrder={setOrder} setRefresh={setRefresh} />
      <DataSheets studies={order?.studies} open={dataSheets} setOpen={setDataSheets} order={order} />
      <Title level={2}>Orden de estudios</Title>
      <Space direction={screens.xs ? 'vertical' : 'horizontal'} style={{ width: '100%', justifyContent: 'space-between', overflowX: 'hidden' }}>
        <OrderInfo order={order} loading={loading} />
        <PatientInfo order={order} loading={loading} />
        <ReferrerInfo order={order} loading={loading} />
      </Space>
      <Divider />
      <OrderNotes order={order} user={user} />
      {imagesAvailable(order) && <Button type="primary" onClick={() => openStudies(order)}><Eye /> Ver imágenes</Button>}
      <Title level={4}>Estudios</Title>
      {screens.xs ? (
        <StudiesList order={order} screens={screens} canceling={canceling} setCanceling={setCanceling} cancelStudy={cancelStudy} />
      ) : (
        <Table dataSource={order?.studies} columns={columns} loading={loading} />
      )}
      <FloatButton.Group shape="square" style={{ position: 'fixed', bottom: 20, right: 20 }} trigger="hover" icon={<ThreeDots />} type="primary">
        <FloatButton type="primary" onClick={() => setDataSheets(true)} tooltip="Ver hojas de datos" icon={<QrCode />} />
        <FloatButton type="primary" onClick={() => setSending(true)} tooltip="Enviar estudios" icon={<Send />} />
        <FloatButton type="primary" onClick={() => openStudies(order)} tooltip="Ver" icon={<Eye />} />
      </FloatButton.Group>
    </Space>
  );
};

export default Order;