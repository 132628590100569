import React, {useState, useEffect } from 'react';
import { Modal, Form, Button, Space, Input, message, Checkbox, Select } from 'antd';
import Parse from 'parse';

const defaultPrintingMedia = [
    {label: '10x12"', value: '10x12"', checked: false},
    {label: '14x17"', value: '14x17"', checked: false},
]

const PrintModal = ({ open, setOpen, study, setRefresh }) => {
    const [data, setData] = useState([]);
    const [printingMedia, setPrintingMedia] = useState([...defaultPrintingMedia]);
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();

    const setChange = (value, field, key) => {
        let previousData = [...data];
        console.log('Previous data', previousData);
        previousData[key] = {...previousData[key], [field]: value};
        setData(previousData);
    }

    const handlePrint = () => {
        console.log('Data', data);
        const Printings = Parse.Object.extend('Printings');
        const printings = new Printings();
        const studyPointer = {
            __type: 'Pointer',
            className: 'Studies',
            objectId: study.objectId
        }

        printings.set('study', studyPointer);
        printings.set('media', data);
        printings.set('user', Parse.User.current());
        printings.save().then((result) => {
            message.success('Impresión registrada');
            setOpen(false);
            setRefresh((prev) => !prev)
        }
        , (error) => {
            console.error('Error while creating Printing: ', error);
            message.error('Error al registrar la impresión');
        });
    }

    useEffect(() => {
        setData([])
    }, [open])



    return (
        <Modal
          title="Registrar impresión"
          open={open}
          onCancel={() => setOpen(false)}
          footer={[
            <Button key="back" onClick={() => setOpen(false)}>
              Cancelar
            </Button>,
            <Button key="submit" type="primary" loading={submitting} onClick={handlePrint}>
              Guardar
            </Button>,
          ]}
        >
            <Form
                onFinish={handlePrint}
            >
               <Form.List name="printingMedia">
                     { (fields, { add, remove }) => (
                          <>
                          {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item
                                    {...restField}
                                    name={[key, 'media']}
                                    >
                                    <Select options={printingMedia} {...restField} style={{ width: '20vw' }} placeholder="Tamaño de impresión" onChange={(value) => setChange(value, 'media', key)} />
                                </Form.Item>
                                 <Form.Item
                                    {...restField}
                                    name={[key, 'copies']}
                                    >
                                    <Input type="number" placeholder="Copias" style={{ width: '10vw' }} onChange={(event) => setChange(event.target.value, 'copies', key)} />
                                </Form.Item>
                            </Space>
                          ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Agregar tamaño de impresión
                                </Button>
                            </Form.Item>
                          </>
                     )}
                </Form.List>
                
            </Form>
          
        </Modal>
    );
}

export default PrintModal;