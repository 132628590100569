import React, {useState, useEffect } from 'react';
import Parse from 'parse';
import { Modal, Form, Input, Button, message, Space, Flex, Select, Typography } from 'antd';
const { Title, Text } = Typography;

const CardPaymentModal = ({ open, setOpen, setRefresh, clinicId }) => {
    const [cards, setCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);

    useEffect(() => {
        if (open) {
            getCards();
        }
    }, [open]);

    const getCards = async () => {
        const params = { clinicId };
        const cards = await Parse.Cloud.run('listCards', params);
        console.log('Cards', cards);
        setCards(cards);
    }

    const payWithCard = async () => {
        const params = { clinicId, cardId: selectedCard, amount: open?.amount, description: open?.description, deviceSessionId: 'kR1MiQhz2otdIuUlQkbEyitIqVMiI16f' };
        const result = await Parse.Cloud.run('createRedirectPayment', params);
        console.log('Payment result', result);
        message.success('Pago realizado');
        setRefresh((prev) => prev + 1);
        setOpen(false);
    }

    return (
        <Modal title="Pagar con tarjeta" open={open} footer={null} onCancel={() => setOpen(false)}>
            <Title level={4}>{open?.description}</Title>
            <Text>Monto: {String(open?.amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}.00</Text>
            <Form onFinish={payWithCard}>
                <Form.Item name="card" label="Tarjeta" rules={[{ required: true }]}>
                    <Select onChange={(value) => setSelectedCard(value)}>
                        {cards.map((card) => (
                            <Select.Option key={card.id} value={card.id}>
                                {card.cardNumber}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {selectedCard && (
                    <Form.Item name="cvv" label="CVV" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                )}
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">Pagar</Button>
                        <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CardPaymentModal;

