import Parse from 'parse';


const searchFigures = async (value, role) => {
    console.log('Searching figures', value, role);
    return new Promise(async (resolve, reject) => {
    if (role === 'Patients') {
            const query = new Parse.Query('Patients');
            query.matches('fullName', value, 'i');
            query.ascending('fullName');
            const results = (await query.find()).map(item => item.toJSON());
            resolve(results);
    } else {
        const params = { fullName: value, role: [role] };
        try {
            const users = await Parse.Cloud.run('queryUsers', params);
            console.log('Usersss', users);
            resolve(users);
        }
        catch (error) {
            console.error('Error while fetching patients: ', error);
            reject(error);
        }
    }
});

}

const queryModalities = () => {
    return new Promise(async (resolve, reject) => {
        let query = new Parse.Query('Modalities');
        query.ascending('name');
        const results = (await query.find()).map(item => item.toJSON());
        resolve(results);
    });
}

const queryProtocols = (modality) => {
    return new Promise(async (resolve, reject) => {
        let query = new Parse.Query('Protocols');
        const modalityPointer = {
            __type: 'Pointer',
            className: 'Modalities',
            objectId: modality.objectId
        }
        query.equalTo('modality', modalityPointer);
        query.ascending('protocol');
        const results = (await query.find()).map(item => item.toJSON());
        resolve(results);
    });
}

const queryStudies = (value) => {
    console.log('Query description', value)
    return new Promise(async (resolve, reject) => {
        let query = new Parse.Query('StudyDescriptions');
        query.matches('description', value, 'i');
        query.descending('description');
        query.include('modality');
        const results = (await query.find()).map(item => item.toJSON());
        const descriptions = results.map(item => {
            return {
                value: item.description,
                label: item.description,
                key: item.objectId,
                ...item
            }
        });
        console.log('Descriptions', descriptions);
        resolve(descriptions);
    });
}





const updateOrder = async (data) => {
    return new Promise(async (resolve, reject) => {
        const Orders = Parse.Object.extend('Orders');
        const Order = new Orders(data);

        Order.save().then((result) => {
            console.log('Order created', result);
            resolve(result.toJSON());
        }, (error) => {
            console.error('Error while creating Order: ', error);
            reject(error);
        });
    });
}

const queryClients = async (value) => {
    return new Promise(async (resolve, reject) => {
        try {
            const Clients = Parse.Object.extend('Clients');
            const query = new Parse.Query(Clients);
            if (value) {
                query.matches('name', value, 'i');
            }
            const clients = await query.find();
            resolve(clients.map(client => client.toJSON()));
        }
        catch (error) {
            console.error('Error while fetching clients: ', error);
            reject(error);
        }
    });
}
        

  export { updateOrder, searchFigures, queryModalities, queryProtocols, queryStudies, queryClients };
  